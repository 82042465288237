import React from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import Component from '../../components/components';
import './index.css';
import { useLocation } from 'react-router-dom';
const CheckoutComplete: React.FC = () => {
    const location = useLocation();
    const id = location.state;
    return (
        <div>
            <Header />
            <div className='checkout-complete'>
                <div className='container-checkout-complete-grid'>
                    <Component.Shared.TextComponent value={'PEDIDO: ' + id + ' ENVIADO COM SUCESSO!'} size={25} color='black' margin={0} align='center' />
                    <br />
                    <Component.Shared.TextComponent value={'Seu pedido foi processado com êxito e está sujeito à análise de crédito.'} size={18} color='black' margin={0} align='center' />
                    <Component.Shared.TextComponent value={'Confira o status e detalhes no histórico de compras.'} size={18} color='black' margin={0} align='center' />
                    <Component.Shared.TextComponent value={'Obrigado por escolher nossa loja!'} size={18} color='black' margin={0} align='center' />


                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CheckoutComplete;