import { z } from 'zod'
import { isValidCPF, isValidCNPJ } from './validations'
export const registerSchema = z
  .object({
    email: z.string().
      email({
        message: "Por favor, insira um e-mail válido",
      }).min(1, 'Este campo é obrigatório').max(100,'O campo deve ter no máximo 100 caracteres.'),
    senha: z.string().min(6, 'Este campo é obrigatório'),
    nome: z.string().min(3, 'Este campo é obrigatório').max(60,'O campo deve ter no máximo 60 caracteres.'),
    fantasia: z.string().optional(),
    telefone: z.string().min(14, 'Este campo é obrigatório'),
    cnpj_cpf: z.string(),
    endereco: z.string().min(3, 'Este campo é obrigatório').max(60,'O campo deve ter no máximo 60 caracteres.'),
    bairro: z.string().min(3, 'Este campo é obrigatório').max(60,'O campo deve ter no máximo 60 caracteres.'),
    cidade: z.string().min(3, 'Este campo é obrigatório'),
    uf: z.string().min(2, 'Este campo é obrigatório'),
    numero: z.string().min(1, 'Este campo é obrigatório').max(6,'O campo deve ter no máximo 6 caracteres.'),
    cep: z.string().min(8, 'Este campo é obrigatório'),
    inscricao: z.string().max(16,'O campo deve ter no máximo 16 caracteres.'),
    TipoInscricao: z.string().min(1, 'Este campo é obrigatório'),
    tipo: z.string(),
    complemento: z.string(),
    confirmacao_senha: z.string({
      required_error: 'Este campo é obrigatório'
    })
  })
  .refine(data => data.senha === data.confirmacao_senha, {
    message: 'Senhas não conferem',
    path: ['password_confirmation']
  })
  .refine(data => {
    if (data.TipoInscricao === '3') {
      return data.inscricao === ''; // deve estar vazio se for '3'
    } else {
      return data.inscricao.length >= 1; // deve ter pelo menos 1 caractere se for diferente de '3'
    }
  }, {
    message: "Informe inscrição de acordo com seu tipo. Caso seja ISENTO, não informar.",
    path: ['inscricao']
  })
  .refine(data => {
    if (data.tipo === 'pf') {
      return data.cnpj_cpf.length === 14 && isValidCPF(data.cnpj_cpf);
    }
    if (data.tipo === 'pj') {
      return data.cnpj_cpf.length === 18 && isValidCNPJ(data.cnpj_cpf);
    }
    return true;
  }, {
    message: "O valor inserido não é válido para o tipo selecionado (CPF/CNPJ).",
    path: ['cnpj_cpf']
  })
  .refine(data => {
    if (data.tipo === 'pj' && (!data.fantasia || data.fantasia.length === 0)) {
      return false;
    }
    return true;
  }, {
    message: "Este campo é obrigatório",
    path: ['fantasia']
  });
export type RegisterInputProps = z.infer<typeof registerSchema>

export const loginSchema = z.object({
  email: z.string().
    email({
      message: "Por favor, insira um e-mail válido",
    }).min(1, 'Este campo é obrigatório'),
    password: z.string().min(6, 'A senha deve ter pelo menos 6 caracteres')
})

export type LoginInputProps = z.infer<typeof loginSchema>


export const forgotSchema = z.object({
  email: z.string().
    email({
      message: "Por favor, insira um e-mail válido",
    }).min(1, 'Este campo é obrigatório'),
})

export type ForgotInputProps = z.infer<typeof forgotSchema>




export const recoverySchema = z.object({
  senha: z.string().min(6, 'Este campo é obrigatório'),
  confirmacao_senha: z.string({
    required_error: 'Este campo é obrigatório'
  })})
  .refine(data => data.senha === data.confirmacao_senha, {
    message: 'Senhas não conferem',
    path: ['password_confirmation']
  })

export type RecoveryInputProps = z.infer<typeof recoverySchema>


export const checkoutRepresSchema = z
  .object({
    condpagtoid: z.string().min(1, 'Este campo é obrigatório'),
    itempedidos: z.array(z.any()).min(1, 'Obrigatório ter itens no pedido'),
    observacao: z.string().optional(),
    usuarioid: z.string().optional(),
    clienteid: z.string().min(1, 'Este campo é obrigatório'),
    enderecoid: z.string().min(1, 'Este campo é obrigatório'),
    doctoid: z.string().min(1, 'Este campo é obrigatório'),
    tipoped:z.string().min(1, 'Este campo é obrigatório'),

  })

export type CheckoutRepresInputProps = z.infer<typeof checkoutRepresSchema>



export const AlterPedidoSchema = z
  .object({
    condpagtoid: z.string().min(1, 'Este campo é obrigatório'),
    itempedidos: z.array(z.any()).min(1, 'Obrigatório ter itens no pedido'),
    observacao: z.string().optional(),
    clienteid: z.string().min(1, 'Este campo é obrigatório'),
    doctoid: z.string().min(1, 'Este campo é obrigatório'),
    tipoped:z.string().min(1, 'Este campo é obrigatório'),
  })

export type AlterPedidoInputProps = z.infer<typeof AlterPedidoSchema>

export const checkoutSchema = z
  .object({
    condpagtoid: z.string().min(1, 'Este campo é obrigatório'),
    itempedidos: z.array(z.any()).min(1, 'Obrigatório ter itens no carrinho'),
    observacao: z.string().optional(),
    usuarioid: z.string().optional(),
    enderecoid: z.string().min(1, 'Este campo é obrigatório'),
    doctoid: z.string().min(1, 'Este campo é obrigatório'),
  })

export type CheckoutInputProps = z.infer<typeof checkoutSchema>

export const enderecoSchema = z
  .object({
    endereco: z.string().min(3, 'Este campo é obrigatório'),
    bairro: z.string().min(3, 'Este campo é obrigatório'),
    cidade: z.string().min(3, 'Este campo é obrigatório'),
    uf: z.string().min(2, 'Este campo é obrigatório'),
    numero: z.string().min(1, 'Este campo é obrigatório'),
    complemento: z.string().optional(),
    cep: z.string().min(8, 'Este campo é obrigatório'),
  })

export type EnderecoInputProps = z.infer<typeof enderecoSchema>

export const cepcheckSchema = z
  .object({
    endereco: z.string().min(3, 'Este campo é obrigatório'),
    bairro: z.string().min(3, 'Este campo é obrigatório'),
    cidade: z.string().min(3, 'Este campo é obrigatório'),
    uf: z.string().min(2, 'Este campo é obrigatório'),
    numero: z.string().min(1, 'Este campo é obrigatório'),
    cep: z.string().min(8, 'Este campo é obrigatório'),
    complemento: z.string().optional()
  })

export type CepCheckInputProps = z.infer<typeof cepcheckSchema>


export const clientlistSchema = z.object({
  usuario_id : z.string().min(1,'Este campo é obrigatório')
})

export type ClientListInputProps = z.infer<typeof clientlistSchema>



export const rankingProdutosSchema = z.object({
  clienteid : z.string().min(1,'Este campo é obrigatório'),
  perA : z.string().min(1,'Este campo é obrigatório'),
  perB : z.string().min(1,'Este campo é obrigatório'),
})

export type RankingProdutosInputProps = z.infer<typeof rankingProdutosSchema>
