import Component from '../../components';
import './index.css'

import noimage from '../../../images/noimg.jpg';
import { BASE_URL } from "../../../services/api";

type VariationCardProps =
    {
        id: number
        imagem: string
        qtde: string
        cod_variacao: string;
        onQuantityChange?: (id: number, quantity: number, cod_variacao: string, imagem: string) => void;
    }

const VariationCardComponent = (props: VariationCardProps) => {

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (props.onQuantityChange) {

            props.onQuantityChange(props.id, Number(event.target.value), props.cod_variacao, props.imagem);
        }
      
    };

    return (
        <div className='testando'>
            <div className='image-cart'>
                <Component.Shared.ImageProductComponent className="item-image-card" source={props.imagem ? `${BASE_URL}uploads/${props.imagem}` : noimage} />
            </div>

            <div style={{ width: '80%', display: 'flex', flexDirection: 'column', marginLeft: '15px', marginTop: '10px' }}>
                <div className="product-info">
                    <Component.Shared.TextComponent margin={0} value={props.cod_variacao} color="#696969" size={14} align="center" />
                </div>

                <div className="product-actions-variations">
                    <input
                        type="number"
                        value={props.qtde? props.qtde : ''}
                        onChange={handleQuantityChange}
                        className='input-cart'
                    />

                </div>
            </div>
        </div>
    )
}

export default VariationCardComponent;