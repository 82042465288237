import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth.provider";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { documentoSchema } from "../../../schemas/docto.schemas";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import UserProps from "../../../../types/user.type";
import api from "../../../../services/api";
import { AxiosRequestConfig } from "axios";
import Component from "../../../components";
import './cadDocto.css';
import { FaPen, FaPenAlt, FaPlus, FaSave, FaTrash } from "react-icons/fa";

type Documento = {
  id: string;
  descricao: string;
};

const CadastroDocumento: React.FC = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [documento, setDocumento] = useState<Documento>({
    id: '',
    descricao: '',
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Documento>({
    resolver: zodResolver(documentoSchema)
  });
  const [documentos, setDocumentos] = useState<Documento[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isNewCadastro, setIsNewCadastro] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
const [alteracao, setAlteracao] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<Documento[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastDocument = currentPage * itemsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - itemsPerPage;
  const currentDocuments = documentos.slice(indexOfFirstDocument, indexOfLastDocument);
  const currentProductsD = filteredProducts.slice(indexOfFirstDocument, indexOfLastDocument);

  useEffect(() => {
    const token = Cookies.get('AccessToken');
    if (!token) {
      navigate('/login');
      return;
    } else {
      const decoded = jwtDecode<UserProps>(token);
      if (decoded.exp * 1000 < Date.now()) {
        Cookies.remove('AccessToken');
        navigate('/login');
        return;
      }
    }
  }, [navigate]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      console.log(user);
      try {
        await listDocumentos();
      } catch (error) {
        console.error('Ocorreu um erro:', error);
      }
      setIsLoading(false);
    })();
  }, [user]);

  const listDocumentos = async () => {
    try {
      const response = await api.get(`/Doctos/listar`);
      if (response.status === 200) {
        console.log('Documentos cadastrados:', response.data);
        setDocumento(response.data);
        setDocumentos(response.data);
      }
    } catch (error) {
      console.error('Ocorreu um erro ao listar as marcas:', error);
    } finally {
      setIsLoading(false);
    }
  }


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'id' && value.length > 2) {
      const limitedValue = value.slice(0, 2);

      setDocumento((prevDocumento) => ({
        ...prevDocumento,
        [name]: limitedValue,
      }));
    } else {
      setDocumento((prevDocumento) => ({
        ...prevDocumento,
        [name]: value,
      }));
    }
  };
  const [documentoEmEdicao, setDocumentoEmEdicao] = useState<string | null>(null);

  const onSubmit = (data: Documento) => {
    const userId = user ? user.sub : undefined;

    if (user && user.sub) {
      if (userId) {
        const requestData = {
          ...data,
          UsuarioId: userId,
        };
        api
          .post(`/Doctos/inserir`, requestData)
          .then(response => {
            setDocumento({
              id: '',
              descricao: ''
            });
            listDocumentos();
            setSuccessMessage('Cadastro realizado com sucesso.');
            setTimeout(() => {
              setSuccessMessage('');
            }, 2000);
          })
          .catch(error => {
            console.error('Erro ao adicionar o documento:', error);
            throw error;
          });
      }
    }
  };

  const editDocumento = (id: string) => {
    const documentoParaEditar = documentos.find((doc) => doc.id === id);

    if (documentoParaEditar) {
      setDocumento({
        id: documentoParaEditar.id,
        descricao: documentoParaEditar.descricao,
      });

      setDocumentoEmEdicao(id);
      setIsEditing(true);
      setAlteracao(true);
    } else {
      console.error(`Documento com ID não encontrado.`);
    }
  };

  const handleEditDocumento = (data: Documento) => {
    setDocumentoEmEdicao(data.id);
    setIsEditing(true);



    const requestData = {
      ...data,
    };

    api
      .put(`/Doctos/alterar`, requestData)
      .then(response => {
        if (response.status === 200) {
          console.log('Documento editado com sucesso.');
          listDocumentos();
          setDocumento({
            id: '',
            descricao: ''
          });
          setIsEditing(false);
          setDocumentoEmEdicao(null);
        } else {
          console.error('Erro ao editar o documento. Resposta da API:', response.data);
        }
      })
      .catch(error => {
        console.error('Erro ao editar o documento:', error);
      });

  };

  const handleExcluirDocto = (data: Documento) => {

    const request: AxiosRequestConfig = {
      data: {
        ...data
      },
    };

    api
      .delete('/Doctos/excluir', request)
      .then(response => {
        if (response.status === 204) {
          console.log('Documento excluído com sucesso.');
          listDocumentos();
        } else {
          console.error('Erro ao excluir o documento.');
        }
      })
      .catch(error => {
        console.error('Erro ao enviar a solicitação para excluir o documento:', error);
      });
  };


  const handleClick = async () => {
    setIsLoading(true);
    if (user && user.sub) {
      try {
        await listDocumentos();
      } catch (error) {
        console.error('Ocorreu um erro:', error);
      }
    }
    setIsLoading(false);
  };

  const handleNewCadastroClick = () => {
    setIsNewCadastro(true);
    setIsEditing(true);
    setDocumento({ id: '', descricao: '' });
  };


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleCancel = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setDocumento({
      id: '',
      descricao: '',
    });
    setIsEditing(false);
    setDocumentoEmEdicao(null);
    setIsNewCadastro(false);
  };

  const pesqDocto = () => {
    const filtered = documentos.filter((doc) =>
      doc.descricao.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    pesqDocto();
  }, [searchTerm]);

  const cancelSearch = () => {
    if (user && user.sub) {
      setFilteredProducts(documentos);
      setSearchTerm('');
      listDocumentos()
    }
  };

  return (
    <div>
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      <div>
        <div className="box-insert" onClick={handleNewCadastroClick} >
          <span className="text-button-insert">Novo </span>
          <FaPlus title="Adicionar novo registro" color="white" size={25} />
        </div>
        <div className="divisor-container">
          <div className="divisor1">
            <input
              type="text"
              placeholder="Pesquisar Documento"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="divisor2">
            <button
              className="button-list"
              type="button"
              onClick={pesqDocto}>
              Pesquisar
            </button>
            <button
              type="button"
              className="button-list"
              onClick={cancelSearch}>
              Cancelar
            </button>
          </div>
        </div>
        <div className="grid-container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Descrição</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {searchTerm ? (
                currentProductsD.map((doc) => (
                  <tr key={doc.id}>
                    <td>{doc.id}</td>
                    <td>{doc.descricao}</td>
                    <td>
                      <div className="box-update">
                        <FaPen onClick={() => editDocumento(doc.id)} title="Alterar registro" size={20} color="white" />
                      </div>
                      <div className="box-delete">
                        <FaTrash onClick={() => handleExcluirDocto(doc)} title="Excluir registro" color="white" size={20} />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                currentDocuments.map((doc) => (
                  <tr key={doc.id}>
                    <td>{doc.id}</td>
                    <td>{doc.descricao}</td>
                    <td>
                      <div className="box-update">
                        <FaPen onClick={() => editDocumento(doc.id)} title="Alterar registro" size={20} color="white" />
                      </div>
                      <div className="box-delete">
                        <FaTrash onClick={() => handleExcluirDocto(doc)} title="Excluir registro" color="white" size={20} />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>

          </table>
        </div>
        <Component.Custom.Pagination
          currentPage={currentPage}
          totalItems={documentos.length}
          setCurrentPage={handlePageChange}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
        <div className="edit-status">
          {isEditing ? (
            <p>Campos habilitados para edição.</p>
          ) : (
            <p>Campos desabilitados. Clique em "Novo" para editar.</p>
          )}
        </div>
        <div className="order-edit-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="divisor-containerD">
              <div className="divisor1D">
                <label htmlFor="id">ID:</label>
                <input
                  {...register('id')}
                  type="text"
                  id="id"
                  name="id"
                  value={documento.id}
                  onChange={handleInputChange}
                  disabled={ alteracao && true || !isEditing}
                />
                {errors.id ? (
                  <Component.Shared.TextComponent
                    value={errors.id.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
              </div>
              <div className="divisor2D">
                <label htmlFor="descricao">Descrição:</label>
                <input
                  {...register('descricao')}
                  type="text"
                  id="descricao"
                  name="descricao"
                  value={documento.descricao}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
                {errors.descricao ? (
                  <Component.Shared.TextComponent
                    value={errors.descricao.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
              </div>
            </div>
            <div>
              <button
                className="btn btn-secondary button"
                type='button'
                onClick={handleCancel}
                disabled={!isEditing}
              >
                Cancelar
              </button>
              <button
                className="btn btn-primary button"
                type='submit'
                disabled={!isEditing}
              >
                Cadastrar
              </button>
              {documentoEmEdicao ? (
                <button
                  type="button"
                  onClick={() => handleEditDocumento(documento)}
                  className="btn btn-primary button"
                >
                  Salvar Alteração
                </button>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CadastroDocumento;