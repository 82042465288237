import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from "react-router-dom";
import { RecoveryInputProps, forgotSchema, recoverySchema } from "../../components/schemas/user.schemas";
import useAuthStore from "../../context/auth.store";

import ImageComponent from '../../components/shared/image.shared.component';

import Component from '../../components/components';
import { useEffect, useState } from 'react';
import api from '../../services/api';

const Recovery = () => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<RecoveryInputProps>({ resolver: zodResolver(recoverySchema) })

    useEffect(() => {

        const params = new URLSearchParams(window.location.search) // id=123
        let id = params.get('token') // 123 

        const url = `/Usuarios/verificartoken?token=${id}`;
        api
            .get(url)
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status == 404) {
                        alert('Link expirado.Tente novamente redefinir sua senha');
                        navigate("/");
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });

        setToken(id ?? '');
    }, []);



    const onSubmit = async (data: RecoveryInputProps) => {
        setLoading(true);  // Start loading


        api
            .post("/Usuarios/alterarsenha",
                {
                    senha: data.senha,
                    token: token
                })
            .then((res) => {
                alert("Senha alterada com sucesso.");
                setLoading(false);
                navigate("/");

            })
            .catch((error) => {
                alert(`Erro ao autenticar.  ${error.message}`);
                console.log(error);
                setLoading(false);

            });


    }


    return (
        <div className='main'>
            <div className="centered-content">
                {loading && <Component.Shared.LoaderComponent overlay />}
                <form className="form-control contentLogin" onSubmit={handleSubmit(onSubmit)}>
                    <ImageComponent height={80} width={200} className='' source='/teste.png' margin={50} />
                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Senha" type="password" register={register('senha')} />
                    {errors.senha ? (
                        <Component.Shared.TextComponent value={errors.senha.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Confirmação de senha" type="password" register={register('confirmacao_senha')} />
                    {errors.confirmacao_senha ? (
                        <Component.Shared.TextComponent value={errors.confirmacao_senha.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <button className="form-control btn btn-primary button" type='submit'>Recuperar senha</button>

                </form>

            </div>
            <div className="footer-sistema">
                <p>Inovando Sistemas - Todos os direitos reservados.</p>
            </div>
        </div>
    )
}

export default Recovery;


