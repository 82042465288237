import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Component from "../../../../components";
import TitRec from "../../../../../types/titrec.type";


const Inadimplencia: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [list, setList] = useState<TitRec[]>([]);
    const [loading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    useEffect(() => {

        gerarRelatorio();
    }, []);

    const gerarRelatorio = async () => {
         setIsLoading(true);
        await api.get('/relatorios/inadimplencia'
        ).then(response => {
            setList(response.data)
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })

    };


    return (
        <div>
            <div className="divisor-container">
               
                <div className="divisor1">
                    <input
                        type="text"
                        placeholder="Filtrar por nome"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <div className="grid-container">
            {loading && (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent overlay />
                    </div>
                )}
                <table>
                    <thead>
                        <tr>
                            <th>Título</th>
                            <th>Nota fiscal/série</th>
                            <th>Data Venc.</th>
                            <th>Cliente</th>
                            <th>Valor</th>
                            <th>Situação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && (
                            list
                                .filter(cli => {
                                    // Se searchTerm for vazio, inclua todos os itens.
                                    if (!searchTerm) return true;
                                    return cli.nome.toLowerCase().includes(searchTerm.toLowerCase());
                                })
                                .slice(indexOfFirstProduct, indexOfLastProduct).map((cli) => (
                                    <tr key={cli.id}>
                                        <td>{cli.id}</td>
                                        <td>{cli.documento}/{cli.serie}</td>
                                        <td>{cli.data_venc}</td>
                                        <td>{cli.nome}</td>
                                        <td>
                                            R$ {cli.val_saldo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </td>
                                        <td>{cli.situacao}</td>
                                    </tr>
                                )))}
                    </tbody>
                </table>
            </div>
            <Component.Custom.Pagination
                currentPage={currentPage}
                totalItems={list.length}
                setCurrentPage={handlePageChange}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
        </div>
    );
}

export default Inadimplencia;