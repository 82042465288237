import { useContext } from 'react'
import { AuthContext } from './auth.provider'


const useAuthStore = () => {
  const { isAuthenticated, mostrarClientes, representante, login, loginById, logout, user, create, error, clearError } =
    useContext(AuthContext)
  return { isAuthenticated, mostrarClientes, representante, login, loginById, logout, user, create, error, clearError }
}

export default useAuthStore