import React, { ChangeEvent, useEffect, useState } from "react";
import { State } from "../../types/state";
import { TipoInscr } from "../../types/tipoinsc";
import Docto from "../../types/docto.type";
import CondPagto from "../../types/condpagto.type";
import Client from "../../types/client.type";
import Representada from "../../types/representada.type";
import Representante from "../../types/representante.type";

interface SelectProps {
    color: string,
    size: number,
    register?: any;
    margin: number,
    align: 'center' | 'flex-end' | 'flex-start' | 'top',
    data: State[] | TipoInscr[] | CondPagto[] | Docto[] | Client[] | Representada[] | Representante[];
    marginTop?: number;
    placeholder: string;
    onChange?: (value: string) => void;
    filter?: boolean
    findById?: string;
    disable?: boolean;
}

const SelectComponent: React.FC<SelectProps> = ({
    color,
    size,
    data,
    margin,
    align,
    marginTop,
    placeholder,
    register,
    filter,
    findById,
    onChange,
    disable
}) => {

    const [selectedValue, setSelectedValue] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const filteredData = data.filter(item =>

        getDescricao(item).toLowerCase().includes(searchTerm.toLowerCase())
    );


    useEffect(() => {
        if(data.length > 0 && findById) {

        console.log("findById recebido no SelectComponent:", findById);
        // Ajuste o valor inicial com base em findById sempre que findById ou data mudar
        const initialValue = data.find(item => item.id.toString() === findById)?.id.toString() || '';
        setSelectedValue(initialValue);
        }
    }, [findById, data]);


    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setSelectedValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    function getDescricao(item: State | TipoInscr | CondPagto | Docto | Client | Representada | Representante): string {
        if ('nome' in item) {
            if ('cnpj_cpf' in item)
                return item.nome + ' - ' + item.cnpj_cpf;
            return item.nome; // Para 'Representada' ou qualquer outro tipo que use 'nome'
        } else if ('descricao' in item) {
            return item.descricao; // Para os demais tipos
        }
        return ''; // Retorne uma string vazia ou um valor padrão caso nenhum dos casos acima
    }

    return (
        <>
            {filter && (
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Pesquisar por nome ou cnpj do cliente"
                    style={{
                        // estilize seu campo de entrada conforme necessário
                    }}
                />
            )}
            <select
        
                defaultValue=""
                value={selectedValue}
                disabled={disable ? disable : false}
                style={{
                    color: !selectedValue ? 'gray' : color,
                    fontSize: size,
                    margin: margin ? margin : 0,
                    marginTop: marginTop ? marginTop : 0,
                    alignSelf: align ? align : 'center',
                    padding: 8,
                    marginBottom:10
                }}
                className='form-control'
                {...register}
                onChange={handleChange}>
                <option value="" disabled style={{ color: 'white' }}>{placeholder} </option>
                {filteredData.map((object, index) => (
                    <option key={index} value={object.id}>
                        {getDescricao(object)}
                    </option>
                ))}
            </select>
        </>
    );
}

export default SelectComponent;