import React, { useRef, useEffect, useState } from 'react';
import { ptBR } from 'date-fns/locale';
import api from '../../../../../services/api';
import { format, eachDayOfInterval, startOfMonth, endOfMonth } from 'date-fns';
import Component from '../../../../components';

type Type =
    {
        represId:number;
        representanteNome: string;
        cnpJ_CPF: string;
        totalVendas: number;
    }

interface Props {
  selectedDate: Date | null;
  selectedRepresentada: number;

}
const GridDetalhesRepres: React.FC<Props> = ({ selectedDate,  selectedRepresentada }) => {
    const [list, setList] = useState<Type[]>([]);
 
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedDate) return;
      setLoading(true); // Ativa o estado de carregamento
      const mesAno = format(selectedDate, 'MM/yyyy');
      try {
        const response = await api.post(`/relatorios/detalhar_vendas_mensal?mesAno=${mesAno}&representadaid=${selectedRepresentada}`);
        setList(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
      finally {
        setLoading(false); // Desativa o estado de carregamento
      }
    };

    fetchData();
  }, [selectedDate,  selectedRepresentada]);

  useEffect(() => {
    if (!selectedDate) return;

    const ano = selectedDate.getFullYear();
    const mes = selectedDate.getMonth() + 1;
    const days = eachDayOfInterval({
      start: startOfMonth(new Date(ano, mes - 1)),
      end: endOfMonth(new Date(ano, mes - 1))
    });
  
  }, [list, selectedDate]);



  return <><div>
    {loading && (
      <div className="product-container">
        <Component.Shared.LoaderComponent overlay />
      </div>
    )}
    <div className="grid-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Ranking</th>
                                            <th>Nome</th>
                                            <th>Valor vendido</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && (
                                            list.map((product, index) => (
                                                <tr key={product.represId}>
                                                    <td style={{ color: '#ee4d2d', fontWeight: 'bold' }}>{indexOfFirstProduct + index + 1}</td>
                                                    <td>{product.representanteNome}</td>
                                                    <td>R$ {product.totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                            )))}
                                    </tbody>
                                </table>
                            </div>

  </div></>;
};

export default GridDetalhesRepres;