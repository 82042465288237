import React, { useContext, useState } from 'react';
import './index.css';
import { FaBars, FaBookOpen, FaDatabase, FaFile, FaHome, FaMinus, FaPlus, FaRegFile, FaUser } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import Component from '../../../components';
import { AuthContext } from '../../../../context/auth.provider';

const SideBarMobile: React.FC<{ onMenuItemClick: (title: string) => void }> = ({ onMenuItemClick }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  return (
    <div className="sidebar-mobile">
      <div className='logo-mobile'>
        <Component.Shared.ImageComponent height={100} width={100} className='' source={process.env.PUBLIC_URL + '/logoadmin.png'} margin={25} />
      </div>
      <div className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)}>
        <FaBars size={25} />
      </div>
      {menuOpen && (
        <div className="mobile-menu">
          <ul className="menu-list">
            <li className="adminmenu-item" onClick={() => { navigate('/admin-home'); onMenuItemClick('Página Inicial'); setMenuOpen(!menuOpen); }}>
              <FaHome size={15} /> Página Inicial
            </li>

            {user?.HabilitarCadastros && (
              <li className="adminmenu-item has-submenu">
                <FaPlus size={15} /> Cadastros
                <ul className="submenu-list">
                  <li className="submenu-item" onClick={() => { navigate('categorias'); onMenuItemClick('Categorias'); setMenuOpen(!menuOpen); }}> <FaMinus size={15} /> Categorias</li>
                  <li className="submenu-item" onClick={() => { navigate('clientes'); onMenuItemClick('Clientes'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Clientes</li>
                  <li className="submenu-item" onClick={() => { navigate('condicoes'); onMenuItemClick('Condições de pagamento'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Condições de pagamento</li>
                  <li className="submenu-item" onClick={() => { navigate('documentos'); onMenuItemClick('Documentos'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Documentos</li>
                  <li className="submenu-item" onClick={() => { navigate('fornecedores'); onMenuItemClick('Fornecedores'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Fornecedores</li>
                  <li className="submenu-item" onClick={() => { navigate('marcas'); onMenuItemClick('Marcas'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Marcas</li>
                  <li className="submenu-item" onClick={() => { navigate('produtos'); onMenuItemClick('Produtos'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Produtos</li>
                  <li className="submenu-item" onClick={() => { navigate('subcategorias'); onMenuItemClick('Subcategorias'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Subcategorias</li>
                </ul>
              </li>
            )}


            <li className="adminmenu-item" onClick={() => { navigate('historicopedidos'); onMenuItemClick('Pedidos'); setMenuOpen(!menuOpen); }}>
              <FaRegFile size={15} /> Pedidos
            </li>

            <li className="adminmenu-item" onClick={() => { navigate('clientes'); onMenuItemClick('Lista de clientes'); setMenuOpen(!menuOpen); }}>
              <FaUser size={15} /> Lista de Clientes
            </li>

            <li className="adminmenu-item" onClick={() => { navigate('saldoestoque'); onMenuItemClick('Produtos'); setMenuOpen(!menuOpen); }}>
              <FaBookOpen size={15} /> Produtos
            </li>

            <li className="adminmenu-item has-submenu">
              <FaPlus size={15} /> Relatórios
              <ul className="submenu-list">
                <li className="submenu-item" onClick={() => { navigate('clientessemcompra'); onMenuItemClick('Clientes sem compra'); setMenuOpen(!menuOpen); }}><FaMinus size={15} />  Clientes sem compra</li>
                <li className="submenu-item" onClick={() => { navigate('rankingclientes'); onMenuItemClick('Ranking de clientes'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Ranking de clientes</li>
                <li className="submenu-item" onClick={() => { navigate('rankingprodutos'); onMenuItemClick('Ranking de produtos por cliente'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Ranking de produtos</li>
                <li className="submenu-item" onClick={() => { navigate('rankingvendas'); onMenuItemClick('Ranking de vendas'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Ranking de vendas</li>
                <li className="submenu-item" onClick={() => { navigate('geralvendas'); onMenuItemClick('Geral de vendas'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Geral de vendas</li>
                <li className="submenu-item" onClick={() => { navigate('inadimplencia'); onMenuItemClick('Inadimplência'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Inadimplência</li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default SideBarMobile;