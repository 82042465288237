import React from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';

import './home.css'; // Importando o arquivo CSS
import Component from "../../components/components"
import {  useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import UserProps from '../../types/user.type';


const Products: React.FC = () => {


  const token = Cookies.get('AccessToken')
  const navigate = useNavigate();
 
  if(!token)
  {
    navigate("/login");
  }
  else
  {
      const decoded = jwtDecode<UserProps>(token);
      if (decoded.exp * 1000 < Date.now()) {
        Cookies.remove('AccessToken');
        navigate("/login");
      }
  }

  return (
    <div>
      <Header />
      <Component.Custom.ProductList  />
      <Footer />
    </div>
  );
};

export default Products;