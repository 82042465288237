import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth.provider";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addressSchema } from "../../../schemas/endereco.schema";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import axios, { AxiosRequestConfig } from "axios";
import api from "../../../../services/api";
import Component from "../../../components";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import './cadCliente.css'
import UserProps from "../../../../types/user.type";


type Address = {
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  uf: string;
};

type Usuarios ={
  id: string
  nome: string
  email: string
  cnpj_cpf: string
}
const CadastroEnderecoCliente: React.FC = () => {
  const [addressesAtual, setAddressesAtual] = useState<Address>({
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
  });
  const [userAtual, setUserAtual] = useState<Usuarios>({
    id: '',
    nome: '',
    email: '',
    cnpj_cpf: '',
  });

  const [users, setUsers] = useState<Usuarios[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [addresses, setAddresses] = useState<Address[]>([]);

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewCadastro, setIsNewCadastro] = useState(false);

  const [AddressesToEdit, setAddressesToEdit] = useState<string| null>(null);
  const [userToEdit, setUserToEdit]= useState<string | null>(null);

  const [childKey, setChildKey] = useState(0);
  const [showDiv, setShowDiv] = useState(false);


  const { 
    reset, 
    handleSubmit, 
    register,
    formState: { errors}, 
    setValue
  } = useForm<Address>({ resolver: zodResolver(addressSchema) });
  const { 
    reset: resetUser, 
    handleSubmit:handleSubmitUser, 
    register: registerUser,
    setValue: setValueUser
  } = useForm<Usuarios>();

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const [successMessage, setSuccessMessage] = useState<string>('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<Address[]>([]);

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentAddresses = addresses.slice(indexOfFirstProduct, indexOfLastProduct);
  const currentProductsA = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  useEffect(() => {
    const token = Cookies.get('AccessToken');
    if (!token) {
      navigate('/login');
      return;
    } else {
      const decoded = jwtDecode<UserProps>(token);
      if (decoded.exp * 1000 < Date.now()) {
        Cookies.remove('AccessToken');
        navigate('/login');
        return;
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (user && user.sub) {
        try {
          await listUsers(user.sub);
          console.log(user)
        } catch (error) {
          console.error('Ocorreu um erro:', error);
        }
      }
      setIsLoading(false);
    };

    fetchData();
  }, [user?.sub]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddressesAtual({
      ...addressesAtual,
      [name]: value,
    });
  }

  const handleCepChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddressesAtual({
      ...addressesAtual,
      [name]: value,
    });
    const cep = event.target.value.replace(/\D/g, '');
    if (cep.length === 8) {
        axios
            .get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    const data = response.data;
                    setValue('endereco', data.street);
                    setValue('bairro', data.neighborhood);
                    setValue('cidade', data.city);
                    setValue('uf', data.state);
                } else {
                    console.error('CEP inválido ou não encontrado.');
                }
            })
            .catch((error) => {
                console.error('Erro ao buscar o CEP:', error);
            });
    }
  };

  const listAddresses = async (sub: string) => {
    try {
      const response = await api.get('/EnderecoEntrega/listar?userId='+ sub);
      if (response.status === 200) {
        console.log(addresses)
        const addressesData = response.data as Address[];
        setAddresses(addressesData);
      } else {
        console.error('Erro ao carregar os endereços.');
      }
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
    }
  }; 


  const listUsers= async (sub: string) => {
    try {
      const response = await api.get('/Usuarios/listar?userId='+ sub);
      if (response.status === 200) {
        const usersData = response.data as Usuarios[];
        setUsers(usersData);
        console.log(usersData)
      } else {
        console.error('Erro ao carregar os endereços.');
      }
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
    }
  }; 

  const onSubmit = (data: Address) => {
    const userId = user ? user.sub : undefined;
    if (user && user.sub) {
      if (userId) {
        const requestData = {
            ...data,
            usuarioid: userId,
        };

        api
        .post('/EnderecoEntrega/inserir', requestData)
        .then((response) => {
          setAddressesAtual({
            cep: '',
            endereco: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            uf: '',
          });
          reset(); 
          listAddresses(user.sub);
          setSuccessMessage('Cadastro realizado com sucesso.');
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        })
          .catch((error) => {
            console.error('Erro ao adicionar o endereço:', error);
          });
      }
    }
  };

  const handleEditUser = (id: string) => {
    const userToEdit = users.find((user) => user.id === id);
    if (userToEdit) {
      setUserAtual({
        id: userToEdit.id,
        email: userToEdit.email,
        cnpj_cpf: userToEdit.cnpj_cpf,
        nome: userToEdit.nome
      });
      setUserToEdit(id);
      setIsEditing(true);
  };
}
const EditUser = (data: Usuarios) => {
  const userId = user ? user.sub : undefined;
  if (user && user.sub) {
    setUserAtual(data)
    setIsEditing(true);

    if (userId) {
      const requestData = {
        id: data.id,
        email:data.email,
        Cnpj_cpf: data.cnpj_cpf,
        Nome:data.nome,
        UsuarioId: userId,
      };

      api
        .put('/Usuarios/alterar', requestData)
        .then((response) => {
          if (response.status === 204) {
            console.log('Usuário atualizado com sucesso.');
            setUserAtual({
              id: '',
              email: '',
              cnpj_cpf: '',
              nome: '',
            });
            listUsers(user.sub);
            setIsEditing(false);
            setIsNewCadastro(false)
            setUserToEdit(null); 
          } else {
            console.error('Erro ao atualizar o usuário.');
          }
        })
        .catch((error) => {
          console.error('Erro ao enviar a solicitação para editar o usuário:', error);
        });
    } else {
      console.error('ID de usuário não disponível.');
    }
  }
}; 

const handleDeleteUser = (data: Usuarios) => {
  const userId = user ? user.sub : undefined;
  if (!userId) {
    console.error('ID de usuário não disponível.');
    return;
  }
  if (user && user.sub) {
    const request: AxiosRequestConfig = {
      data: {
        ...data,
        UsuarioId: userId,
      },
    };

    api
      .delete('/Usuarios/excluir', request)
      .then((response) => {
        if (response.status === 204) {
          console.log('Usuário excluído com sucesso.');
          listUsers(user.sub);
        } else {
          console.error('Erro ao excluir o usuário.');
        }
      })
      .catch((error) => {
        console.error('Erro ao enviar a solicitação para excluir o endereço:', error);
      });
  }
};
  const handleEdit = (cep: string) => {
    const addressToEdit = addresses.find((address) => address.cep === cep);

    if (addressToEdit) {
      setAddressesAtual({
        cep: addressToEdit.cep,
        endereco: addressToEdit.endereco,
        numero: addressToEdit.numero,
        complemento: addressToEdit.complemento,
        bairro: addressToEdit.bairro,
        cidade: addressToEdit.cidade,
        uf: addressToEdit.uf,
      });
      setValue('cep', addressToEdit.cep);
      setValue('endereco', addressToEdit.endereco);
      setValue('numero', addressToEdit.numero);
      setValue('complemento', addressToEdit.complemento);
      setValue('bairro', addressToEdit.bairro);
      setValue('cidade', addressToEdit.cidade);
      setValue('uf', addressToEdit.uf);

      setAddressesToEdit(cep);
      setIsEditing(true);
    } else {
      console.error(`Endereço com ID não encontrado.`);
    }
  };

  const handleEditAddress = (data: Address) => {
    const userId = user ? user.sub : undefined;
    if (user && user.sub) {
      setAddressesToEdit(data.cep);
      setIsEditing(true);

      if (userId) {
        const requestData = {
        ...data,
          usuarioid: userId,
        };

        api
          .put('/EnderecoEntrega/alterar', requestData)
          .then((response) => {
            if (response.status === 200) {
              console.log('Endereço atualizado com sucesso.');
              setAddressesAtual({
                cep: '',
                endereco: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                uf: '',
              });
              listAddresses(user.sub);
              reset();
              setIsEditing(false);
              setIsNewCadastro(false)
              setAddressesToEdit(null)
            } else {
              console.error('Erro ao atualizar o endereço.');
            }
          })
          .catch((error) => {
            console.error('Erro ao enviar a solicitação para editar o endereço:', error);
          });
      } else {
        console.error('ID de usuário não disponível.');
      }
    }
  }; 
  
  const handleDeleteAddress = (data: Address) => {
    const userId = user ? user.sub : undefined;
    if (!userId) {
      console.error('ID de usuário não disponível.');
      return;
    }
    if (user && user.sub) {
      const request: AxiosRequestConfig = {
        data: {
          ...data,
          UsuarioId: userId,
        },
      };

      api
        .delete('/EnderecoEntrega/excluir', request)
        .then((response) => {
          if (response.status === 204) {
            console.log('Endereço excluído com sucesso.');
            listAddresses(user.sub);
          } else {
            console.error('Erro ao excluir o endereço.');
          }
        })
        .catch((error) => {
          console.error('Erro ao enviar a solicitação para excluir o endereço:', error);
        });
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  const pesqAddress = () => {
    console.log("Pesquisar clicado");
    const cepRegex = /^[0-9]{5}-?[0-9]{3}$/;
    const normalizedSearchTerm = searchTerm.replace(/-/g, '');

    if (cepRegex.test(searchTerm)) {
      console.log("CEP válido:", searchTerm);
      const filtered = addresses.filter((address) => {
        const normalizedAddressCep = address.cep.replace(/-/g, '');
        return normalizedAddressCep === normalizedSearchTerm;
      });
      if (user && user.sub) {
        if (filtered.length > 0) {
          console.log("Endereços filtrados:", filtered);
          setFilteredProducts(filtered);
          setErrorMessage(""); 
          listAddresses(user.sub);
        } else {
          setErrorMessage("CEP não encontrado na lista de cadastrados. Por favor, digite um CEP válido.");
          setFilteredProducts([]); 
        }
      }
    } 
  };

  const cancelSearch = () => {
    if (user && user.sub) {
      setFilteredProducts(addresses); 
      setSearchTerm('');     
      listAddresses(user.sub)   
      listUsers(user.sub)
    }    
  };

  useEffect(() => {
    if (user && user.sub) {
      pesqAddress();
      listAddresses(user.sub)
    }
  }, [searchTerm]);

  const handleCancel = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (user && user.sub) {
      setUserAtual({
        id: '',
        email: '',
        cnpj_cpf: '',
        nome: '',
      });
      listUsers(user.sub);
      setIsEditing(false);
      setIsNewCadastro(false)
      setUserToEdit(null); 
    }
  };

  const handleNewCadastro = () => {
    setIsEditing(true);
  };

  const updateEndereco = () => {
    if (user && user.sub) {
      listAddresses(user.sub);
      setShowDiv(!showDiv);
      setChildKey(prevKey => prevKey + 1);
    }
  };

return (
  <div>
    <div className="box-insert" onClick={handleNewCadastro}>
      <span className="text-button-insert">Novo</span>
      <FaPlus title="Adicionar novo registro" color="white" size={25} />
    </div>
    <div className="divisor-container">
      <div className="divisor1">
        <input
          type="text"
          placeholder="Pesquisar Endereço"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="divisor2">
        <button
          className="button-list"
          type="button"
          onClick={pesqAddress}
        >
          Pesquisar
        </button>
        <button
          type="button"
          className="button-list"
          onClick={cancelSearch}
        >
          Cancelar
        </button>
      </div>
    </div>
    <div className="order-edit-form">
      <label htmlFor="usuario">Usuários:</label>
      <select
        id="usuario"
        name="usuario"
        value={selectedUser}
        onChange={async (e) => {
          const selectedUser = e.target.value;
          setSelectedUser(selectedUser);
          setUsers([...users]);

          if (selectedUser) {
            await listAddresses(selectedUser);
          }
        }}
        disabled={!isEditing}
      >
        <option value="">Selecione um Usuário</option>
        {users.map((usuario) => (
          <option key={usuario.id} value={usuario.id}>
            {usuario.email}
          </option>
        ))}
      </select>
    </div>
    {selectedUser ? (
      <div className="order-edit-form">
        <h4>Usuário</h4>
        <div className="grid-container">
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>CNPJ/CPF</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {users
                .filter((user) => user.id === selectedUser)
                .map((user) => (
                  <tr key={user.id}>
                    <td>{user.nome}</td>
                    <td>{user.email}</td>
                    <td>{user.cnpj_cpf}</td>
                    <td>
                      <div className="box-update">
                        <FaPen
                          onClick={() => handleEditUser(user.id)}
                          title="Alterar registro"
                          size={20}
                          color="white"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    ) : null}
    {userToEdit ? (
      <div className="order-edit-form">
        <div className="form-group">
          <input
            {...registerUser('id')}
            type="hidden"
            name="id"
            value={userAtual.id}
          />
          <label htmlFor="nome">Nome:</label>
          <input
            {...registerUser('nome')}
            type="text"
            id="Nome"
            name="Nome"
            value={userAtual.nome}
            onChange={(e) => {
              const { value } = e.target;
              setValueUser('nome', value);
              setUserAtual({ ...userAtual, nome: value });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            {...registerUser('email')}
            type="email"
            id="email"
            name="email"
            value={userAtual.email}
            onChange={(e) => {
              const { value } = e.target;
              setValueUser('email', value);
              setUserAtual({ ...userAtual, email: value });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="Cnpj_cpf">CNPJ/CPF:</label>
          <input
            {...registerUser('cnpj_cpf')}
            type="text"
            id="cnpj_cpf"
            name="cnpj_cpf"
            value={userAtual.cnpj_cpf}
            onChange={(e) => {
              const { value } = e.target;
              setValueUser('cnpj_cpf', value);
              setUserAtual({ ...userAtual, cnpj_cpf: value });
            }}
          />
        </div>
        <button
          type="button"
          onClick={() => EditUser(userAtual)}
          className="btn btn-primary button"
        >
          Salvar Alteração
        </button>
        <button
          className="btn btn-secondary button"
          type="button"
          onClick={handleCancel}
        >
          Cancelar
        </button>
      </div>
    ) : null}
    <div className="order-edit-form">
      <h4>Endereços</h4>
      {selectedUser ? (
        <>
          <div className="grid-container">
            <table>
              <thead>
                <tr>
                  <th>CEP</th>
                  <th>Endereço</th>
                  <th>Número</th>
                  <th>Complemento</th>
                  <th>Bairro</th>
                  <th>Cidade</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {searchTerm ? (
                  currentProductsA.map((address) => (
                    <tr key={address.cep}>
                      <td>{address.cep}</td>
                      <td>{address.endereco}</td>
                      <td>{address.numero}</td>
                      <td>{address.complemento}</td>
                      <td>{address.bairro}</td>
                      <td>{address.cidade}</td>
                      <td>
                        <div className="box-delete">
                          <FaTrash
                            onClick={() => handleDeleteAddress(address)}
                            title="Excluir registro"
                            color="white"
                            size={20}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  currentAddresses.map((address) => (
                    <tr key={address.cep}>
                      <td>{address.cep}</td>
                      <td>{address.endereco}</td>
                      <td>{address.numero}</td>
                      <td>{address.complemento}</td>
                      <td>{address.bairro}</td>
                      <td>{address.cidade}</td>
                      <td>
                        <div>
                          <div className="box-delete">
                            <FaTrash
                              onClick={() => handleDeleteAddress(address)}
                              title="Excluir registro"
                              color="white"
                              size={20}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div>
            <Component.Custom.Pagination
              currentPage={currentPage}
              totalItems={addresses.length}
              setCurrentPage={handlePageChange}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </>
      ) : null}
      
      <div className="checkout-row">
      <Component.Shared.TextInputComponent
            size={16}
            color="black"
            placeholder="* Endereço"
            type="hidden"
            maxLength={9}
            margin={0} marginTop={10}
            register={register('cep')}
            value={addressesAtual.cep}
            onChange={(e) => {
              handleInputChange(e);
            }} 
      />
        {errors.cep ? (
          <Component.Shared.TextComponent
            value={errors.cep.message}
            size={12}
            color='red'
            margin={0}
            align='flex-start'
          />
        ) : null}

      </div>
      <span className='btn btn-primary button' onClick={() => setShowDiv(!showDiv)} >
        {showDiv ? 'Cancelar' : 'Cadastrar um novo'}
      </span>
      <div className={`animatedDiv ${showDiv ? 'open' : 'closed'}`}>
        <Component.Custom.NewEnderecoEntregaComponent key={childKey} onSucess={updateEndereco} />
      </div>
    </div>
  </div>
  );
}
export default CadastroEnderecoCliente;