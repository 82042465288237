import React, { useContext } from 'react';
import './index.css';

import { useNavigate } from 'react-router-dom';
import { FaBars, FaBookOpen, FaDatabase, FaFile, FaHome, FaMinus, FaPlus, FaRegFile, FaUser } from 'react-icons/fa';
import Component from '../../../components';
import { AuthContext } from '../../../../context/auth.provider';
const SiderBarComponent: React.FC<{ onMenuItemClick: (title: string) => void }> = ({ onMenuItemClick }) => {

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  return (
    <div className="sidebar">
      <div className="logo">
        <Component.Shared.ImageComponent height={100} width={100} className='' source={process.env.PUBLIC_URL + '/logoadmin.png'} margin={50} />
        <p>Menu Principal</p>
      </div>

      <ul className="menu-list">
        <li className="adminmenu-item" onClick={() => { navigate('/admin-home'); onMenuItemClick('Página Inicial'); }}>
          <FaHome size={15} /> Página Inicial
        </li>
        {user?.HabilitarCadastros && (
          <li className="adminmenu-item has-submenu">
            <FaPlus size={15} /> Cadastros
            <ul className="submenu-list">
              <li className="submenu-item" onClick={() => { navigate('categorias'); onMenuItemClick('Categorias'); }}> <FaMinus size={15} /> Categorias</li>
              <li className="submenu-item" onClick={() => { navigate('clientes'); onMenuItemClick('Clientes'); }}><FaMinus size={15} /> Clientes</li>
              <li className="submenu-item" onClick={() => { navigate('condicoes'); onMenuItemClick('Condições de pagamento'); }}><FaMinus size={15} /> Condições de pagamento</li>
              <li className="submenu-item" onClick={() => { navigate('documentos'); onMenuItemClick('Documentos'); }}><FaMinus size={15} /> Documentos</li>
              <li className="submenu-item" onClick={() => { navigate('fornecedores'); onMenuItemClick('Fornecedores'); }}> <FaMinus size={15} /> Fornecedores</li>
              <li className="submenu-item" onClick={() => { navigate('marcas'); onMenuItemClick('Marcas'); }}> <FaMinus size={15} /> Marcas</li>
              <li className="submenu-item" onClick={() => { navigate('produtos'); onMenuItemClick('Produtos'); }}><FaMinus size={15} /> Produtos</li>
              <li className="submenu-item" onClick={() => { navigate('subcategorias'); onMenuItemClick('Subcategorias'); }}><FaMinus size={15} /> Subcategorias</li>
            </ul>
          </li>
        )}

<li className="adminmenu-item" onClick={() => { navigate('historicopedidos'); onMenuItemClick('Pedidos'); }}>
          <FaRegFile size={15} /> Pedidos
        </li>

        <li className="adminmenu-item" onClick={() => { navigate('clientes'); onMenuItemClick('Lista de clientes'); }}>
          <FaUser size={15} /> Lista de clientes
        </li>

        <li className="adminmenu-item" onClick={() => { navigate('saldoestoque'); onMenuItemClick('Produtos'); }}>
          <FaBookOpen size={15} /> Produtos
        </li>

        <li className="adminmenu-item has-submenu">
            <FaPlus size={15} /> Relatórios
            <ul className="submenu-list">
              <li className="submenu-item" onClick={() => { navigate('clientessemcompra'); onMenuItemClick('Clientes sem compra'); }}><FaMinus size={15} /> Clientes sem compra</li>
              <li className="submenu-item" onClick={() => { navigate('rankingclientes'); onMenuItemClick('Ranking de clientes'); }}><FaMinus size={15} /> Ranking de clientes</li>
              <li className="submenu-item" onClick={() => { navigate('rankingprodutos'); onMenuItemClick('Ranking de produtos por cliente'); }}><FaMinus size={15} /> Ranking de produtos</li>
              <li className="submenu-item" onClick={() => { navigate('rankingvendas'); onMenuItemClick('Ranking de vendas'); }}><FaMinus size={15} /> Ranking de vendas</li>
              <li className="submenu-item" onClick={() => { navigate('geralvendas'); onMenuItemClick('Geral de vendas'); }}><FaMinus size={15} /> Geral de vendas</li>
              <li className="submenu-item" onClick={() => { navigate('inadimplencia'); onMenuItemClick('Inadimplência'); }}> <FaMinus size={15} /> Inadimplência</li>
            </ul>
          </li>
      </ul>

    </div>
  );
}

export default SiderBarComponent;