import { ReactNode, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth.provider";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { pagtoInsertSchema } from "../../../schemas/cadPagto.schemas";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import UserProps from "../../../../types/user.type";
import api from "../../../../services/api";
import { AxiosRequestConfig } from "axios";
import Component from "../../../components";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import './cadForn.css';
import PaginationComponent from "../../pagination/pagination.components";
import FornSchema from "../../../schemas/forn.schema";

type Forn = {
    id: number;
    nome: string;
  };
  
const CadastroForn: React.FC = () => {
  const navigate = useNavigate();
  const [marca, setMarca] = useState<Forn>({
    id: 0,
    nome: '',
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Forn>({
    resolver: zodResolver(FornSchema)
  });

  const [marcas, setMarcas] = useState<Forn[]>([]);
  const [marcaEmEdicao, setMarcaEmEdicao] = useState<string | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isNewCadastro, setIsNewCadastro] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<Forn[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentMarcas = marcas.slice(indexOfFirstProduct, indexOfLastProduct);
  const currentProductsM = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  useEffect(() => {
    const token = Cookies.get('AccessToken');
    if (!token) {
      navigate('/login');
      return;
    } else {
      const decoded = jwtDecode<UserProps>(token);
      if (decoded.exp * 1000 < Date.now()) {
        Cookies.remove('AccessToken');
        navigate('/login');
        return;
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
        try {
          await listMarcas();
        } catch (error) {
          console.error('Ocorreu um erro:', error);
        }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const  listMarcas = async () => {
    try {
      const response = await api.get('/Marcas/listar');
      if (response.status === 200) {
        console.log('Marcas cadastradas:', response.data);
        setMarcas(response.data);
      }
      } catch (error) {
        console.error('Ocorreu um erro ao listar as marcas:', error);
      } finally {
        setIsLoading(false);
      }
    }
  
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setMarca((prevMarca) => ({
      ...prevMarca,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const onSubmit = (data: Forn) => {
   
      const requestData = {
        id:data.id,
        descricao: data.nome,
      };

      api
        .post(`/Marcas/inserir`, requestData)
        .then((response) => {
          setMarca({
            id: 0,
            nome: '',
          });
          listMarcas();
          setIsEditing(false)
          setSuccessMessage('Cadastro realizado com sucesso.');
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        })
        .catch((error) => {
          console.error('Erro ao adicionar a marca:', error);
          throw error;
        });
   
  };

  const editMarca = (id: number) => {
    const marcaParaEditar = marcas.find((marca) => marca.id === id);
    setIsEditing(true)
    if (marcaParaEditar) {
      setMarca({
        id: marcaParaEditar.id,
        nome: marcaParaEditar.nome,
      });

      setMarcaEmEdicao(id.toString());
    } else {
      console.error(`Marca com ID não encontrado.`);
    }
  };

  const handleEditMarca = (data: Forn) => {
    setMarcaEmEdicao(data.id.toString());
    setIsEditing(true);
   
    
      const requestData = {
        ...data,
      };

      api
        .put(`/Marcas/alterar`, requestData)
        .then((response) => {
          if (response.status === 200) {
            console.log('Marca editada com sucesso.');
            listMarcas();
            setMarca({
              id: 0,
              nome: '',
            });
            setIsEditing(false);
            setMarcaEmEdicao(null);
          } else {
            console.error('Erro ao editar a marca. Resposta da API:', response.data);
          }
        })
        .catch((error) => {
          console.error('Erro ao editar a marca:', error);
        });
 

};

  const handleExcluirMarca = (data: Forn) => {
    
  
    const request: AxiosRequestConfig = {
      data: {
        ...data,
      },
    };


    api
      .delete('/Marcas/excluir', request)
      .then((response) => {
        if (response.status === 204) {
          console.log('Marca excluída com sucesso.');
          listMarcas();
        } else {
          console.error('Erro ao excluir a marca.');
        }
      })
      .catch((error) => {
        console.error('Erro ao enviar a solicitação para excluir a marca:', error);
      });
    
}

  const handleClick = async () => {
    setIsLoading(true);
    
      try {
        await listMarcas();
      } catch (error) {
        console.error('Ocorreu um erro:', error);
      }
    setIsLoading(false);
  };


  const handleNewCadastroClick = () => {
    setIsNewCadastro(true);
    setIsEditing(true);
    setMarca({ id: 0, nome: '' });
  };

  const handleCancel = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (isEditing) {
      // Se estiver editando, limpe o estado atual da marca
      setMarca({
        id: 0,
        nome: '',
      });
      setIsEditing(false);
      setIsNewCadastro(false)
    }
  };

const pesqMarca = () => {
  const filtered = marcas.filter((marca) =>
    marca.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );
  setFilteredProducts(filtered);
};

useEffect(() => {
  pesqMarca();
}, [searchTerm]);

const cancelSearch = () => {
 
  setFilteredProducts(marcas); 
  setSearchTerm(''); 
  listMarcas()  
};

  return (
    <div>
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      <div>
        <div className="box-insert" onClick={() => setIsEditing(true)} >
                <span className="text-button-insert">Novo </span>
                <FaPlus title="Adicionar novo registro" color="white" size={25} />
            </div>
            <div className="divisor-container">
                <div className="divisor1">
                <input
                    type="text"
                    placeholder="Pesquisar Marcas"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                </div>
                <div className="divisor2">
                <button 
                    className="button-list"
                    type="button" 
                    onClick={pesqMarca}>
                    Pesquisar
                </button>
                <button 
                    type="button" 
                    className="button-list"
                    onClick={cancelSearch}>
                    Cancelar
                </button>
                </div>
            </div>
            <div className="grid-container">
            <table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Ações</th>
                </tr>
                </thead>
                <tbody>
                {searchTerm ? (
                  currentProductsM.map((marca) => (
                    <tr key={marca.id}>
                        <td>{marca.id}</td>
                        <td>{marca.nome}</td>
                        <td>
                          <div className="btnM">
                            <div className="box-update">
                                <FaPen onClick={() => editMarca(marca.id)} title="Alterar registro" size={20} color="white" />
                            </div>
                            <div className="box-delete">
                                <FaTrash onClick={() => handleExcluirMarca(marca)} title="Excluir registro" color="white" size={20} />
                            </div>
                            </div>
                        </td>
                    </tr>
                 ))
                 ) : (
                  currentMarcas.map((marca) => (
                    <tr key={marca.id}>
                        <td>{marca.id}</td>
                        <td>{marca.nome}</td>
                        <td>
                          <div className="btnM">
                            <div className="box-update">
                                <FaPen onClick={() => editMarca(marca.id)} title="Alterar registro" size={20} color="white" />
                            </div>
                            <div className="box-delete">
                                <FaTrash onClick={() => handleExcluirMarca(marca)} title="Excluir registro" color="white" size={20} />
                            </div>
                            </div>
                        </td>
                    </tr>
                  ))
                 )}
                </tbody>
            </table>
            </div>
        <PaginationComponent
          currentPage={currentPage}
          totalItems={marcas.length}
          setCurrentPage={handlePageChange}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
        <div className="edit-status">
          {isEditing ? (
            <p>Campos habilitados para edição.</p>
          ) : (
            <p>Campos desabilitados. Clique em "Novo" para editar.</p>
          )}
        </div>
        <div className="order-edit-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="divisor-container">
              <div>
                <input
                {...register('id')}
                type="hidden"
                name="id"
                value={marca.id}
              />
                {errors.id ? (
                  <Component.Shared.TextComponent
                    value={errors.id.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
              </div>
              </div>
              <div>
                <label htmlFor="nome">Nome:</label>
                <input
                  {...register('nome')}
                  type="text"
                  id="nome" 
                  name="nome" 
                  value={marca.nome}
                  onChange={handleInputChange}
                  disabled= {!isEditing}
                />

                {errors.nome ? (
                  <Component.Shared.TextComponent
                    value={errors.nome.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
             
              </div>
              <div className="buttonF">
                <button 
                className="btn btn-secondary button" 
                type='button'
                onClick={handleCancel}
                disabled={!isEditing}
                >
                    Cancelar
                </button>
                <button 
                className="btn btn-primary button" 
                type='submit'
                disabled={!isEditing}
                >
                    Cadastrar
                </button>
                    {marcaEmEdicao ? (
                    <button 
                    type="button" 
                    onClick={() => handleEditMarca(marca)}
                    className="btn btn-primary button" 
                    >
                        Salvar Alteração
                    </button>
                    ) : null}
              </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CadastroForn;