
export const isValidCPF = (value: string) => {
    const cleaned = value.replace(/\D/g, ''); // Remove caracteres não numéricos
  
    if (cleaned.length !== 11) {
      return false;
    }
  
    const num1 = parseInt(cleaned.charAt(0));
    const num2 = parseInt(cleaned.charAt(1));
    const num3 = parseInt(cleaned.charAt(2));
    const num4 = parseInt(cleaned.charAt(3));
    const num5 = parseInt(cleaned.charAt(4));
    const num6 = parseInt(cleaned.charAt(5));
    const num7 = parseInt(cleaned.charAt(6));
    const num8 = parseInt(cleaned.charAt(7));
    const num9 = parseInt(cleaned.charAt(8));
    const num10 = parseInt(cleaned.charAt(9));
    const num11 = parseInt(cleaned.charAt(10));
  
    if (
      num1 === num2 &&
      num2 === num3 &&
      num3 === num4 &&
      num4 === num5 &&
      num5 === num6 &&
      num6 === num7 &&
      num7 === num8 &&
      num8 === num9 &&
      num9 === num10 &&
      num10 === num11
    ) {
      return false;
    } else {
      const soma1 =
        num1 * 10 +
        num2 * 9 +
        num3 * 8 +
        num4 * 7 +
        num5 * 6 +
        num6 * 5 +
        num7 * 4 +
        num8 * 3 +
        num9 * 2;
  
      let resto1 = (soma1 * 10) % 11;
      if (resto1 === 10) {
        resto1 = 0;
      }
  
      const soma2 =
        num1 * 11 +
        num2 * 10 +
        num3 * 9 +
        num4 * 8 +
        num5 * 7 +
        num6 * 6 +
        num7 * 5 +
        num8 * 4 +
        num9 * 3 +
        num10 * 2;
  
      let resto2 = (soma2 * 10) % 11;
      if (resto2 === 10) {
        resto2 = 0;
      }
  
      return resto1 === num10 && resto2 === num11;
    }
  };

  // Regex para validação de string no formato CNPJ
export const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/

// Método de validação
export function isValidCNPJ(value: string | number | number[] = '') {
  if (!value) return false

  const isString = typeof value === 'string'
  const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

  if (!validTypes) return false

  if (isString) {
    const digitsOnly = /^\d{14}$/.test(value)
    const validFormat = regexCNPJ.test(value)
    const isValid = digitsOnly || validFormat

    if (!isValid) return false
  }

  const numbers = matchNumbers(value)

  if (numbers.length !== 14) return false

  const items = Array.from(new Set(numbers));
  if (items.length === 1) return false

  const digits = numbers.slice(12)

  const digit0 = validCalc(12, numbers)
  if (digit0 !== digits[0]) return false

  const digit1 = validCalc(13, numbers)
  return digit1 === digits[1]
}

// Cálculo validador
function validCalc(x: number, numbers: number[]) {
  const slice = numbers.slice(0, x)
  let factor = x - 7
  let sum = 0

  for (let i = x; i >= 1; i--) {
    const n = slice[x - i]
    sum += n * factor--
    if (factor < 2) factor = 9
  }

  const result = 11 - (sum % 11)

  return result > 9 ? 0 : result
}

// Elimina tudo que não é dígito
function matchNumbers(value: string | number | number[] = '') {
  const match = value.toString().match(/\d/g)
  return Array.isArray(match) ? match.map(Number) : []
}