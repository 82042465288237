import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './custom-owl-carousel.css'; // Importe o arquivo CSS personalizado

import { BASE_URL } from "../../../services/api";
import Banner from '../../../types/banner.type';
import Component from '../../components';

type BannerCarrouselProps = {
  images: Banner[],
}

export const BannerHomeComponent: React.FC<BannerCarrouselProps> = ({ images }) => {
  const [loading, setLoading] = useState(true);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);
    if (images.length > 0) {
      const urls = images.map(image => `${BASE_URL}uploads/${image.imagem}`);
      setImageUrls(urls);
      Promise.all(
        urls.map(url => {
          const img = new Image();
          img.src = url;
          return new Promise<void>((resolve, reject) => {
            img.onload = () => {
              const imgElement = document.querySelector(`img[src="${url}"]`);
              if (imgElement) {
                imgElement.classList.add('loaded');
              }
              resolve();
            };
            img.onerror = reject;
          });
        })
      )
        .then(() => setLoading(false))
        .catch(error => {
          console.log("Erro ao carregar imagens", error);
          setLoading(false);
        });
    } else {
      setImageUrls([]);
      setLoading(false);
    }
  }, [images]);

  if (loading) {
    return <div className="product-container">
      <Component.Shared.LoaderComponent overlay />
    </div>; // Seu spinner ou componente de carregamento aqui
  }

  return (
    <div>
      <OwlCarousel items={1} loop={imageUrls.length > 1} nav={imageUrls.length > 1}>
        {imageUrls.map((url, index) => (
          <div key={index} className="item">
            <img src={url} alt={`Banner ${index}`} className="fade-in" onLoad={(e) => e.currentTarget.classList.add('loaded')} />
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default BannerHomeComponent;
