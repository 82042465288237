
export interface TipoInscr {
    descricao: string;
    id: number;
   
  }
  
  const tipos: TipoInscr[] = [
   
     {
      id: 1, descricao: 'INSC. ESTADUAL'
     },
     {
        id: 2, descricao: 'REGISTRO GERAL'
       },
       {
        id: 3, descricao: 'ISENTO'
       },
       {
        id: 4, descricao: 'CPF'
       },
       {
        id: 5, descricao: 'INSC. MUNICIPAL'
       },
       {
        id: 6, descricao: 'PRODUTOR RURAL'
       },
       {
        id: 7, descricao: 'CRC'
       },
       {
        id: 8, descricao: 'NIRE'
       },
  ];
  
  export default tipos;