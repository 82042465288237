import React, { useContext, useEffect, useState } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import './index.css';
import ProductCardComponent from '../../components/custom/product-card/product.card.components';
import { CartContext } from '../../context/cart.provider';
import api from '../../services/api';
import { AuthContext } from '../../context/auth.provider';
import { useNavigate } from 'react-router-dom';
import CondPagto from '../../types/condpagto.type';
import { CheckoutInputProps, checkoutSchema } from '../../components/schemas/user.schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import Component from '../../components/components';
import Endereco from '../../types/endereco.type';
import Docto from '../../types/docto.type';
import { RepresentadaContext } from '../../context/representada.provider';



const Checkout: React.FC = () => {
    const { cart, removeProduct, clearCart } = useContext(CartContext);
    const { selectedRepresentada } = useContext(RepresentadaContext);
    const { user } = useContext(AuthContext);
    const totalValue = cart.reduce((acc, product) => acc + product.qtde * product.preco, 0);
    const navigate = useNavigate()
    const [condpagtos, setCondPagtos] = useState<CondPagto[]>([]);
    const [doctos, setDoctos] = useState<Docto[]>([]);
    const [enderecos, setEnderecos] = useState<Endereco[]>([]);
    const [childKey, setChildKey] = useState(0);
    const [showDiv, setShowDiv] = useState(false);
    const [error, setError] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<CheckoutInputProps>({ resolver: zodResolver(checkoutSchema) })


    const handleSendOrder = async (data: CheckoutInputProps) => {

        console.log("passou");
            await api.post('/pedidos/inserirpedido', {
                ...data,
                UsuarioId: user?.sub,
                ...(selectedRepresentada?.id !== null && { representadaid: selectedRepresentada?.id })
            }
            ).then(response => {
                // Pedido enviado com sucesso, agora limpe o carrinho
                clearCart();
                navigate("/checkout-complete", { state: response.data.id });
            }).catch(error => {
                setError(error.response.data);
                console.log(error.response.data);
            })
       


    };

    const listCondPagto = async (sub: string) => {

        await api.get('/condpagtos?id=' + sub
        ).then(response => {
            setCondPagtos(response.data)

        })

    };

    const listDocto = async (sub: string, condpagtoid: string) => {

        await api.get('/doctos?id=' + sub + '&&condpagtoid=' + condpagtoid
        ).then(response => {
            setDoctos(response.data)

        })

    };

    const listEndereco = async (sub: string) => {

        await api.post('/enderecoentrega/listarenderecos?usuario_id=' + sub
        ).then(response => {
            setEnderecos(response.data)
        })

    };

    useEffect(() => {
        if (user && user.sub) {
            listCondPagto(user.sub);
            listEndereco(user.sub);
        }

        setValue('itempedidos', cart);
    }, [cart, setValue, user]);


    const updateEndereco = () => {

        if (user && user.sub) {
            listEndereco(user.sub);
            setShowDiv(!showDiv);
            setChildKey(prevKey => prevKey + 1);
        }
    };

    const handleSelectChange = (condpagtoid: string) => {
        setValue('doctoid', '');
        if (user && user.sub) {
            listDocto(user.sub, condpagtoid);
        }
    };
    return (
        <div>
            <Header />
            <form className="" onSubmit={handleSubmit(handleSendOrder)}>
                <div className='container'>
                    <div className='line-checkout container'>
                    </div>
                    <div className='container-checkout-grid'>

                        <div className='produtoscheckout'>
                            <h4 className='resume-title'>PRODUTOS</h4>
                            {cart.length > 0 ?
                                cart.map((product) => (
                                    <ProductCardComponent
                                        id={product.id}
                                        key={product.id}
                                        imagem={product.imagens.length > 0 ? product.imagens[0].imagem : ''}
                                        nome={product.nome}
                                        qtde={product.qtde}
                                        preco={product.preco}
                                        desabilitarbotoes={false}
                                        variacoes={product.variacoes}
                                        onRemove={removeProduct}
                                    />
                                )) :
                                <div className='text-checkout'>
                                    <span>Seu carrinho está vazio!</span>
                                    <br />
                                    <p>Para adicionar produtos ao seu carrinho, navegue pelas categorias ou utilize a busca do site.</p>
                                    {errors.itempedidos ? (
                                        <Component.Shared.TextComponent value={errors.itempedidos.message} size={12} color='red' margin={0} align='center' />
                                    ) : null}
                                </div>

                            }

                        </div>
                        <div className='resume-checkout'>

                            <h4 className='resume-title'>Resumo</h4>

                            <div className='resume-checkout-price'>

                                {/*user?.Cliente && (
                                    <>
                                        <span className='span-text'>Cliente: </span> <span className='span-subtext'>{user.Cliente}</span> <br />
                                    </>
                                )*/}

                                <div className='checkout-row2'>
                                    <span className='span-text'>Total </span>
                                    <strong className='span-price'>R$ {totalValue.toFixed(2)}</strong> <br />
                                </div>

                            </div>

                            <h4 className='resume-title'>Entrega</h4>
                            <div className='resume-checkout-price'>
                                <div className='checkout-row'>
                                    <Component.Shared.SelectComponent register={register('enderecoid')} margin={0} marginTop={10} align='flex-start' data={enderecos} size={16} color="black" placeholder="* Endereço de entrega" />

                                    {errors.enderecoid ? (
                                        <Component.Shared.TextComponent value={errors.enderecoid.message} size={12} color='red' margin={0} align='flex-start' />
                                    ) : null}
                                </div>
                                <span className='span-checkout' onClick={() => setShowDiv(!showDiv)}>
                                    {showDiv ? 'Cancelar' : 'Cadastrar um novo'}
                                </span>
                                <div className={`animatedDiv ${showDiv ? 'open' : 'closed'}`}>
                                    <Component.Custom.NewEnderecoEntregaComponent key={childKey} onSucess={updateEndereco} />
                                </div>
                            </div>

                            <h4 className='resume-title'>Pagamento</h4>
                            <div className='resume-checkout-price'>
                                <div className='checkout-row'>
                                    <Component.Shared.SelectComponent onChange={handleSelectChange} register={register('condpagtoid')} margin={0} marginTop={10} align='flex-start' data={condpagtos} size={16} color="black" placeholder="* Condição de pagamento" />

                                    {errors.condpagtoid ? (
                                        <Component.Shared.TextComponent value={errors.condpagtoid.message} size={12} color='red' margin={0} align='flex-start' />
                                    ) : null}

                                    <Component.Shared.SelectComponent register={register('doctoid')} margin={0} marginTop={10} align='flex-start' data={doctos} size={16} color="black" placeholder="* Tipo de pagamento" />

                                    {errors.doctoid ? (
                                        <Component.Shared.TextComponent value={errors.doctoid.message} size={12} color='red' margin={0} align='flex-start' />
                                    ) : null}
                                </div>
                                <span className='span-text'>Financeiro entrará em contato</span>


                            </div>
                            <h4 className='resume-title'>Observação</h4>

                            <div className='resume-checkout-price'>

                                <Component.Shared.TextInputComponent maxLength={200} register={register('observacao')} type='textarea' align='flex-start' placeholder='* Observação' size={15} color='black' margin={0} />

                                {errors.observacao ? (
                                    <Component.Shared.TextComponent value={errors.observacao.message} size={12} color='red' margin={0} align='flex-start' />
                                ) : null}

                            </div>
                            <div>
                                {error ? (
                                    <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                                ) : null}
                                <button
                                    type="submit"
                                    className="btn btn-success resume-checkout-button"
                                >
                                    <i className="fa fa-cart-arrow-down"></i> FINALIZAR PEDIDO
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <Footer />
        </div>
    );
};

export default Checkout;











