import React from 'react';
import './index.css';
import Component from '../../../components';


const FooterAdminComponent = () => {
  return (
      <div className="footer-sistema-main">
          <p>Inovando Sistemas - Todos os direitos reservados.</p>
      </div>
  );
}

export default FooterAdminComponent;