import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth.provider";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { subCategoriaInsertSchema } from "../../../schemas/subcategoy.schemas";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import UserProps from "../../../../types/user.type";
import api from "../../../../services/api";
import { AxiosRequestConfig } from "axios";
import Component from "../../../components";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import '../cadCategoriaSubcategoria/cadCategoriaSubcategoria.css'


type SubCategory = {
  id: number;
  descricao: string;
  categoriaid: number; 
};

type Category = {
  id: number;
  descricao: string;
};

const CadastroSubcategoria: React.FC = () => {
  const navigate = useNavigate();
  const [subCategoriaAtual, setSubCategoriaAtual] = useState<SubCategory>({
    id: 0,
    descricao: '',
    categoriaid: 0, 
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SubCategory>({
    resolver: zodResolver(subCategoriaInsertSchema),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [subcategorias, setSubcategorias] = useState<SubCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [categorias, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [editingSubId, setEditingSubId] = useState<number | null>(null);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isNewCadastro, setIsNewCadastro] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); 
  const [itemsPerPage, setItemsPerPage] = useState(10); 

  // Calcula a lista de produtos a ser exibida na página atual
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts =  subcategorias.slice(indexOfFirstProduct, indexOfLastProduct);
 
  useEffect(() => {
    const token = Cookies.get('AccessToken');
    if (!token) {
      navigate('/login');
      return;
    } else {
      const decoded = jwtDecode<UserProps>(token);
      if (decoded.exp * 1000 < Date.now()) {
        Cookies.remove('AccessToken');
        navigate('/login');
        return;
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
     
        try {
          await fetchCategory();
          await fetchSubCategories(); 
        } catch (error) {
          console.error('Ocorreu um erro:', error);
        }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const  fetchSubCategories = async () => {
    try {
      const response = await api.get(`/SubCategorias/listar`);
      if (response.status === 200) {
        const subcategoriasdata = response.data as SubCategory[];
        setSubcategorias(subcategoriasdata);
        console.log('Subcategorias',subcategoriasdata)
      } else {
        console.error('Erro ao listar as categorias.');
      }
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await api.get('/Categorias/listar');
      if (response.status === 200) {
        const categoriadata = response.data as Category[];
        setCategories(categoriadata);
        console.log('Categorias',categoriadata) 
      } else {
        console.error('Erro ao listar as categorias.');
      }
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
    }
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
      setSubCategoriaAtual({
        ...subCategoriaAtual,
        [name]: value,
      });
    };

  const onSubmit = (data: SubCategory) => {
   
      const requestData = {
        ...data,
        categoriaid: selectedCategory,
      };

      api
        .post('/SubCategorias/inserir', requestData)
        .then((response) => {
          setSubCategoriaAtual({
            id: 0,
            descricao: '',
            categoriaid: 0, 
          });
          fetchSubCategories();
          setSelectedCategory(''); 
          setIsEditing(false)

          setSuccessMessage('Cadastro realizado com sucesso.');
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        })
          .catch((error) => {
            console.error('Erro ao adicionar um subcategoria:', error);
          });
    };

  const editSubCategoria = (id: number) => {
    const subCategoriaParaEditar = subcategorias.find((sub) => sub.id === id);

    if (subCategoriaParaEditar) {
      setSubCategoriaAtual({
        id: subCategoriaParaEditar.id,
        descricao: subCategoriaParaEditar.descricao,
        categoriaid: subCategoriaParaEditar.categoriaid,
        
      });

      setIsEditing(true);
      setEditingSubId(id); 

     setSelectedCategory(subCategoriaParaEditar.categoriaid.toString());
    } else {
      console.error(`Produto com ID não encontrado.`);
    }
  };

  const handleEditSubCategoria = (data: SubCategory) => {

    setSubCategoriaAtual(data);
    setIsEditing(true);
   
      const requestData = {
        ...data,
        categoriaid: selectedCategory
      };

      api
        .put('/SubCategorias/alterar', requestData)
        .then((response) => {
          if (response.status === 204) {
            fetchSubCategories();
            setSubCategoriaAtual({
              id: 0,
              descricao: '',
              categoriaid: 0, 
            });
            setIsEditing(false);
            setSelectedCategory('');
            setEditingSubId(null) 
          } else {
            console.error('Erro ao editar a subcategoria.');
          }
        })
        .catch((error) => {
          console.error('Erro ao editar a subcategoria:', error);
        });
  };

  const handleExcluirSubCategoria = (data: SubCategory) => {

    
    const request: AxiosRequestConfig = {
      data: {
        ...data,
      },
    };

    api
      .delete('/SubCategorias/excluir', request)
      .then((response) => {
        if (response.status === 204) {
          fetchSubCategories();
        } else {
          console.error('Erro ao excluir a subcategoria.');
        }
      })
      .catch((error) => {
        console.error('Erro ao enviar a solicitação para excluir a subcategoria:', error);
      });
  };

  const handleCancel = () => {
    if (isEditing) {
      // Se estiver editando, limpe o estado atual do produto
        setSubCategoriaAtual({
          id: 0,
          descricao: '',
          categoriaid: 0,
         
        });
        setSelectedCategory(''); 
      setIsEditing(false);
      setEditingSubId(null);
      setIsNewCadastro(false); 
    }
  };

  const getCategoriaDescricao = (categoriaid: number) => {
    const categoria = categorias.find((categoria) => categoria.id === categoriaid);
    return categoria ? categoria.descricao : 'Categoria não encontrada';
  };

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<SubCategory[]>([]);
  const currentProductsS = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const pesqSub = () => {
    const filtered = subcategorias.filter((sub) =>
      sub.descricao.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    pesqSub();
  }, [searchTerm]);

  const cancelSearch = () => {
    setFilteredProducts(subcategorias); 
    setSearchTerm('');    
    fetchSubCategories() 
  };

  return (
    <div>
    
      <div>
          <div>
          {successMessage && (
          <div className="success-message">{successMessage}</div>
          )}
          <div>
          <div className="box-insert" onClick={() => setIsEditing(true)} >
            <span className="text-button-insert">Novo </span>
            <FaPlus title="Adicionar novo registro" color="white" size={25} />
          </div>
          <div className="divisor-container">
        <div className="divisor1">
          <input
            type="text"
            placeholder="Pesquisar Subcategoria"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="divisor2">
          <button 
            className="button-list"
            type="button" 
            onClick={pesqSub}>
            Pesquisar
          </button>
          <button 
            type="button" 
            className="button-list"
            onClick={cancelSearch}>
            Cancelar
          </button>
        </div>
        </div>
        <div className="grid-container">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Descrição</th>
                  <th>Categoria</th> 
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
              {searchTerm ? (
                currentProductsS.map((subCategoria) => (
                  <tr key={subCategoria.id}>
                    <td>{subCategoria.id}</td>
                    <td>{subCategoria.descricao}</td>
                    <td>{getCategoriaDescricao(subCategoria.categoriaid)}</td>
                    <td>
                      <div className="btnS">
                      <div className="box-update">
                        <FaPen onClick={() => editSubCategoria(subCategoria.id)} title="Alterar registro" size={20} color="white" />
                      </div>
                      <div className="box-delete">
                        <FaTrash onClick={() => handleExcluirSubCategoria(subCategoria)} title="Excluir registro" color="white" size={20} />
                      </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                currentProducts.map((subCategoria) => (
                  <tr key={subCategoria.id}>
                    <td>{subCategoria.id}</td>
                    <td>{subCategoria.descricao}</td>
                    <td>{getCategoriaDescricao(subCategoria.categoriaid)}</td>
                    <td>
                      <div className="btnS">
                      <div className="box-update">
                        <FaPen onClick={() => editSubCategoria(subCategoria.id)} title="Alterar registro" size={20} color="white" />
                      </div>
                      <div className="box-delete">
                        <FaTrash onClick={() => handleExcluirSubCategoria(subCategoria)} title="Excluir registro" color="white" size={20} />
                      </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            </table>
           </div>
            <Component.Custom.Pagination
            currentPage={currentPage}
            totalItems={subcategorias.length}
            setCurrentPage={handlePageChange}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
            <div className="edit-status">
              {isEditing ? (
                <p>Campos habilitados para edição.</p>
              ) : (
                <p>Campos desabilitados. Clique em "Novo" para editar.</p>
              )}
            </div>
            <div className="order-edit-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="campos-container">
                <label htmlFor="descricao">Descrição:</label>
                <input
                  {...register('descricao')}
                  type="text"
                  id="descricao"
                  name="descricao"
                  value={subCategoriaAtual.descricao}
                  onChange={(e) => {
                    setValue('descricao', e.target.value);
                    handleInputChange(e);
                  }}
                  disabled={!isEditing}
                />
                {errors.descricao ? (
                  <Component.Shared.TextComponent
                    value={errors.descricao.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
              </div>
              <div className="category-select custom-select">
              <label htmlFor="subcategoriaId">Categoria:</label>
              <select
                  {...register('categoriaid')} 
                  id="categoriaid"
                  name="categoriaid" 
                  value={selectedCategory.toString()} 
                  onChange={(e) => {
                    const selectedCategoryId = parseInt(e.target.value, 10); 
                    setSelectedCategory(selectedCategoryId.toString()); 
                    setSubCategoriaAtual({
                      ...subCategoriaAtual,
                      categoriaid: selectedCategoryId,
                    });
                  }}
                  disabled={!isEditing}
                >
                  <option value="">Selecione uma Categoria</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id.toString()}> 
                      {categoria.descricao}
                    </option>
                  ))}
                </select>
              </div>
                  <input
                    {...register('id')}
                    type="hidden"
                    name="id"
                    value={subCategoriaAtual.id}
                  />
                  <div>
                  <button 
                  className="btn btn-secondary button" 
                  type='button'
                  onClick={handleCancel}
                  disabled={!isEditing}
                  >
                    Cancelar
                  </button>
                  <button 
                  className="btn btn-primary button" 
                  type='submit'
                  disabled={!isEditing}
                  >
                    Cadastrar
                  </button>
                    {editingSubId  ? (
                      <button 
                      type="button" 
                      onClick={() => handleEditSubCategoria(subCategoriaAtual)}
                      className="btn btn-primary button" 
                      >
                        Salvar Alteração
                      </button>
                    ) : null}
              </div>
            </form>
            </div>
          </div>
        </div>
        </div>
     
    </div>
  );
};

export default CadastroSubcategoria;
