import React from 'react';
import styled from 'styled-components';

type ModalProps = {
  show: boolean;
  onClose: () => void;
  imageSrc: string;
};

const ModalBackdrop = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  z-index: 1051;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ModalComponent: React.FC<ModalProps> = ({ show, onClose, imageSrc }) => {
  return (
    <ModalBackdrop show={show} onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <img src={imageSrc} alt="Modal" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
      </ModalContent>
    </ModalBackdrop>
  );
};

export default ModalComponent;
