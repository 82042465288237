import React, { useRef, useEffect, useState } from 'react';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import { format, eachDayOfInterval, startOfMonth, endOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import api from '../../services/api';
import Component from '../components';
import './canvasbarras.css';

Chart.register(...registerables);

type Type = {
  dia: number;
  total: number;
  totalAcumulado: number;
};

interface Props {
  selectedDate: Date | null;
  selectedRepresentada: number;
  selectedRepres: number;
}
const GraficoBarraComponent: React.FC<Props> = ({ selectedDate, selectedRepres, selectedRepresentada }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chartData, setChartData] = useState<{ labels: string[], data: number[], dataAcumulada: number[] }>({
    labels: [],
    data: [],
    dataAcumulada: [] // dataAcumulada em camelCase
  });
  const [list, setList] = useState<Type[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalVendido, setTotalVendido] = useState<number>(0);
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedDate) return;
      setLoading(true); // Ativa o estado de carregamento
      const mesAno = format(selectedDate, 'MM/yyyy');
      try {
        const response = await api.post(`/relatorios/grafico_vendas_mensal?mesAno=${mesAno}&representadaid=${selectedRepresentada}&representanteid=${selectedRepres}`);
        setList(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
      finally {
        setLoading(false); // Desativa o estado de carregamento
      }
    };

    fetchData();
  }, [selectedDate, selectedRepres, selectedRepresentada]);

  useEffect(() => {
    if (!selectedDate) return;

    const ano = selectedDate.getFullYear();
    const mes = selectedDate.getMonth() + 1;
    const days = eachDayOfInterval({
      start: startOfMonth(new Date(ano, mes - 1)),
      end: endOfMonth(new Date(ano, mes - 1))
    });

    const labels = days.map(day => `${format(day, 'd')} ${format(day, 'eeee', { locale: ptBR }).slice(0, 3).toUpperCase()}`);

    const dailySalesMap = new Map(list.map((item) => [item.dia, { total: item.total, totalAcumulado: item.totalAcumulado }]));
    const data = days.map(day => dailySalesMap.get(day.getDate())?.total || 0);
    const dataAcumulada = days.map(day => dailySalesMap.get(day.getDate())?.totalAcumulado || 0);
    // Calcula o total vendido
    const total = list.reduce((acc, item) => acc + item.total, 0);
    setTotalVendido(total);

    setChartData({ labels, data, dataAcumulada });
  }, [list, selectedDate]);

  useEffect(() => {
    if (canvasRef.current && chartData.labels.length > 0) {
      const ctxCarteira = canvasRef.current.getContext('2d');

      if (ctxCarteira) {
        const config: ChartConfiguration = {
          type: 'line',  // ou 'bar' se preferir gráfico de barras
          data: {
            labels: chartData.labels,
            datasets: [{
              label: 'Vendas no mês',
              data: chartData.dataAcumulada,
              borderColor: 'rgb(75, 192, 192)',
              backgroundColor: 'rgba(173, 216, 230, 0.5)',
            }]
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  afterBody: (tooltipItems) => {
                    let total = chartData.data[tooltipItems[0].dataIndex];
                    return `Venda diária: R$ ${total.toFixed(2)}`;
                  }
                }
              },

            }
          }
        };

        const chartInstanceCarteira = new Chart(ctxCarteira, config);

        return () => chartInstanceCarteira.destroy();
      }
    }
  }, [chartData, selectedDate]);

  return <><div>
    <div style={{paddingLeft:10}} >
      <Component.Shared.TextComponent marginTop={0} value="Evolução de Vendas" size={18} color='black' margin={0} align='center' />

    </div>
    {loading && (
      <div className="product-container">
        <Component.Shared.LoaderComponent overlay />
      </div>
    )}
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: 350 , borderTop: '1px solid gray'}}>
      <div className="column" style={{ flex: '2 1 60%', minWidth: '300px' }}>
        <canvas ref={canvasRef} className='canvas-desktop' />
      </div>
      <div className="column" style={{ paddingTop:25,  backgroundColor: '#f8f8f8' , flex: '1 1 40%', minWidth: '200px', textAlign: 'center' }}>
        <Component.Shared.TextComponent marginTop={0} value="Vendido no mês" size={18} color='black' margin={0} align='center' />
        <Component.Shared.TextComponent marginTop={0} value={`R$${totalVendido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} size={18} color='black' margin={0} align='center' />
      </div>
    </div>

  </div></>;
};

export default GraficoBarraComponent;