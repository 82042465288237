// Header.tsx
import React from 'react';
import './index.css'; // Importando o arquivo CSS
import { useTheme } from '../../context/theme.context';

// Defina os tipos das props, caso existam props
interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {

  const {currentTheme}  = useTheme();

  return (
    <footer>
      <div className="footer-columns">
        <div className="footer-column">
          <h3 style={{color: currentTheme.color.primary}}>Sobre a empresa</h3>
          <p>Somos uma loja de acessórios para celular que tem como objetivo oferecer aos nossos clientes produtos de alta qualidade e design exclusivo. Acreditamos que os acessórios certos podem tornar o uso do seu celular ainda mais prático e estiloso.</p>
        </div>
        <div className="footer-column">
          <h3 style={{color: currentTheme.color.primary}}>Navegue por</h3>
          <ul>
            <li><a href="/home" style={{color: currentTheme.color.primary}}>Página Inicial</a></li>
            <li><a href="/panel" style={{color: currentTheme.color.primary}}>Meus Pedidos</a></li>
            <li><a href="/checkout" style={{color: currentTheme.color.primary}}>Meu Carrinho</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3 style={{color: currentTheme.color.primary}}>Fale conosco</h3>
          <p>Telefone: (17) 99669-3555</p>
        </div>
        <div className="footer-column">
          <h3 style={{color: currentTheme.color.primary}}>Redes sociais</h3>
          <ul className="social-media">
            <li><a href="https://www.facebook.com/deviabrasil" style={{color: currentTheme.color.primary}}><i className="fab fa-facebook"></i> Facebook</a></li>
            <li><a href="https://www.instagram.com/deviabrasil" style={{color: currentTheme.color.primary}}><i className="fab fa-instagram"></i> Instagram</a></li>
          </ul>

        </div>
      </div>
      <div className="footer-legal" style={{backgroundColor: currentTheme.color.primary}}>
        <p>26.489.986/0001-07 D. C. AYRES - EQUIPAMENTOS DE TELEFONIA LTDA Mercadoria sai do estado de São Paulo podendo haver imposto de ICMS.</p>
      </div>
    </footer>
  );
};

export default Footer;