import React, { useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './custom-owl-carousel.css'; // Importe o arquivo CSS personalizado
import noimage from '../../../images/noimg.jpg';
import { BASE_URL } from "../../../services/api";
import { Imagem } from '../../../types/product.type';
type BannerCarrouselProps = {
  images: Imagem[],
  type: 'public' | 'base'

}

export const BannerCarrouselComponent = (props: BannerCarrouselProps) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);


  const handleThumbnailClick = (index: number) => {
    setSelectedImageIndex(index);
  }

  const imagesArray = Array.isArray(props.images) ? props.images : [props.images];

  const getImagePath = (image: string) => {
    if (props.type === 'public') {
      return process.env.PUBLIC_URL + image;
    }
    return image ? `${BASE_URL}uploads/${image}` : noimage;
  }

  return (
    <div>

      {imagesArray.length ? (

        <> <OwlCarousel items={1} startPosition={selectedImageIndex} loop nav>
          {imagesArray.map((image, index) => (
            <div key={index} className="item">
              <img src={getImagePath(image.imagem)} alt={`Product ${index}`} />
            </div>
          ))}
        </OwlCarousel>
          <OwlCarousel items={5} nav className="thumbnail-carousel">
            {imagesArray.map((image, index) => (
              <div key={index} className="item-thumbnail" onClick={() => handleThumbnailClick(index)}>
                <img src={getImagePath(image.imagem)} alt={`Thumbnail ${index}`} />
              </div>
            ))}
          </OwlCarousel></>
      )
        : (
          <><OwlCarousel items={1} loop nav>
            <div className="item">
              <img src={noimage} alt={`Product`} />
            </div>

          </OwlCarousel></>
        )
      }


    </div>
  );
};

export default BannerCarrouselComponent;