import React, { useContext, useEffect, useState } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import './home.css'; // Importando o arquivo CSS
import Component from "../../components/components"
import { AuthContext } from '../../context/auth.provider';
import api from '../../services/api';
import Banner from '../../types/banner.type';
import { useNavigate } from 'react-router-dom';
import { RepresentadaContext } from '../../context/representada.provider';
const Home: React.FC = () => {
  const { representante } = useContext(AuthContext);
  const navigate = useNavigate();
  const [images, setImages] = useState<Banner[]>([]);
  const { selectedRepresentada } = useContext(RepresentadaContext);

  useEffect(() => {

    if (representante) {
      navigate('/admin-home')
    }

    if (selectedRepresentada) {
      api.get('/banners/listar?representadaid=' + selectedRepresentada.id
      ).then(response => {
        setImages(response.data)
      }).catch(error => {
      })
    }
    else {
      api.get('/banners/listar'
      ).then(response => {
        setImages(response.data)
      }).catch(error => {
      })
    }
  }, [selectedRepresentada]);

  return (
    <div>
      <Header />
      <Component.Custom.BannerHomeComponent images={images} />
      {selectedRepresentada ? (
        <Component.Custom.Scroll title="Lançamentos" />
      ) : <div style={{ marginBottom: '10vh' }}>
      </div>}
      <Footer />
    </div>
  );
};

export default Home;