
import { FaDownload, FaFileDownload } from "react-icons/fa";
import Order from "../../../types/order.type";

import ProductCardComponent from "../product-card/product.card.components";
import './index.css';
import api from "../../../services/api";
import { useState } from "react";
import Component from "../../components";
type OrderDetailProps =
  {
    order: Order;
  }

export const OrderDetailComponent = (props: OrderDetailProps) => {
  const steps = ["Análise", "Processamento", "Faturamento"];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let currentStepIndex = 0;

  if (props.order.situacao.trim() === "Aberto") {
    currentStepIndex = 0;
  } else if (props.order.situacao.trim() === "Producao") {
    currentStepIndex = 1;
  }
  else if (props.order.situacao.trim() === "Faturado") {
    currentStepIndex = 2;
  }


  const handleDownload = async (pedidoid: number) => {
    setLoading(true);
    try {
      setLoading(true);
      await api.get('/pedidos/download?pedidoid=' + pedidoid, { responseType: 'blob' }).then(response => {
        // Cria um URL para o Blob e define como href para um link, depois dispara um clique
        const urlBlob = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));

        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', 'DanfePdf.zip'); // ou qualquer outro nome de arquivo
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      });

    } catch (error: any) {
      setLoading(false);
      setError("Não foi possível realizar o download dos documentos.");
    }
  };



  return (
    <div className="container container-product-grid-full">
      {loading && <Component.Shared.LoaderComponent overlay />}
      <div className="page-header">
        <h1 >DETALHES DO PEDIDO</h1>

        <div className="btn btn-primary button" onClick={() => {
          if (props.order.situacao.trim() === 'Faturado') {
            handleDownload(props.order.id);
          } else {
            setError('Download disponível apenas para documentos faturados.')
          }
        }}>
          <FaFileDownload size={18} />
          <span>Baixar documentos</span>
        </div>
        {error ? (<Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='center' />
        ) : null}
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1 }}>
          <h4 style={{ marginRight: '10px' }}>Pedido:</h4>
          <h4>{props.order.id}</h4>
        </div>

      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '300px' }}>
        <h4 style={{ marginRight: '10px' }}>Data:</h4>
        <h4>{props.order.data}</h4>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '300px' }}>
        <h4 style={{ marginRight: '10px' }}>Situação:</h4>
        <h4>{props.order.situacao}</h4>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '300px' }}>
        <h4 style={{ marginRight: '10px' }}>Código Interno:</h4>
        <h4>{props.order.cod_interno}</h4>
      </div>
      {currentStepIndex >= 2 && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '300px' }}>
          <h4 style={{ marginRight: '10px' }}>Código de Rastreio:</h4>
          <h4>{props.order.codigo_rastreio}</h4>
        </div>
      )}
      <div className="progress-container">
        {steps.map((step, index) => (
          <div key={index} className={`progress-step ${index <= currentStepIndex ? "active" : ""}`}>
            <div className="progress-circle"></div>
            <p>{step}</p>
          </div>
        ))}

      </div>

      <div className="produtoscheckout">
        <h4>Itens do Pedido:</h4>
        {props.order.items.map(item => (
          <ProductCardComponent
            key={item.id}
            id={item.id}
            imagem={item.imagem}
            nome={item.nome}
            qtde={item.quantidade}
            preco={item.preco}
            desabilitarbotoes
            variacoes={item.variacoes}
            onRemove={() => { }} // Como você provavelmente não quer remover itens aqui, mantemos esta função vazia
          />
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '300px' }}>
        <h4 style={{ marginRight: '10px' }}>Condição: </h4>
        <h4>{props.order.condpagto}</h4>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '300px' }}>
        <h4 style={{ marginRight: '10px' }}>Valor Total: </h4>
        <h4>R$ {props.order.totalPedido.toFixed(2)}</h4>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '300px' }}>
        <h4 style={{ marginRight: '10px' }}>Observação: </h4>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '90%', marginBottom: '40px' }}>
        <span style={{ marginRight: '10px' }}>{props.order.observacao}</span>
      </div>
    </div>
  )
}

export default OrderDetailComponent;