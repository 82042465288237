import React from "react";

interface ButtonProps {
  border?: string;
  color: string;
  title?: string
  height: number;
  onClick?: () => void;
  radius?: string
  width: number;
}

const ButtonComponent: React.FC<ButtonProps> = ({ 
    border,
    color,
    title,
    height,
    onClick, 
    radius,
    width
  }) => { 
  return (
    <button className="btn btn-primary"  type='submit'>Acessar</button>
  );
}

export default ButtonComponent;