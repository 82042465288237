import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from "react-router-dom";
import { ForgotInputProps, forgotSchema } from "../../components/schemas/user.schemas";
import useAuthStore from "../../context/auth.store";
import './forgot.css';
import ImageComponent from '../../components/shared/image.shared.component';

import Component from '../../components/components';
import { useState } from 'react';
import api from '../../services/api';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { login, error } = useAuthStore()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotInputProps>({ resolver: zodResolver(forgotSchema) })


  const onSubmit = async (data: ForgotInputProps) => {
    setLoading(true);  // Start loading


    api
      .post("/Usuarios/esqueceusenha?emails=" + data.email)
      .then((res) => {
        alert("Consulte sua caixa de entrada para receber usuário e senha");
        setLoading(false);

      })
      .catch((error) => {
        alert(`Erro ao autenticar.  ${error.message}`);
        console.log(error);
        setLoading(false);

      });


  }


  return (
    <div className='main'>
      <div className="centered-content">
        {loading && <Component.Shared.LoaderComponent overlay />}
        <form className="form-control contentLogin" onSubmit={handleSubmit(onSubmit)}>
          <ImageComponent height={80} width={200} className='' source='/logogrupoayres.jpg' margin={50} />
          <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="E-mail" type="text" register={register('email')} />

          {errors.email ? (
            <Component.Shared.TextComponent value={errors.email.message} size={12} color='red' margin={0} align='flex-start' />
          ) : null}


          {error ? (
            <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
          ) : null}


          <button className="form-control btn btn-primary button" type='submit'>Recuperar senha</button>




        </form>

      </div>
      <div className="footer-sistema">
        <p>Inovando Sistemas - Todos os direitos reservados.</p>
      </div>
    </div>
  )
}

export default ForgotPassword;


