import { useContext, useEffect, useState } from "react";
import './index.css'; // Importando o arquivo CSS
import { CartContext } from "../../../context/cart.provider";
import { Product } from "../../../types/product.type";
import { Link, useNavigate } from "react-router-dom";
import Component from "../../components";

import { ProductContext } from "../../../context/product.provider";
import noimage from '../../../images/noimg.jpg';
import { BASE_URL } from "../../../services/api";
import SubCategory from "../../../types/subcategory.type";
import { AuthContext } from "../../../context/auth.provider";
import { CategoryContext } from "../../../context/category.provider";
import { useTheme } from "../../../context/theme.context";
import { RepresentadaContext } from "../../../context/representada.provider";

export const ProductListComponent = () => {
  const { selectedCategory, selectedSubCategory, selectSubCategory } = useContext(CategoryContext);
   const {selectedRepresentada} = useContext(RepresentadaContext);
  const { getProductsbyCategoryId, getProductsbySubCategoryId } = useContext(ProductContext);
  const { cart, addProduct } = useContext(CartContext);
  const [message, setMessage] = useState<string | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(16);
  const navigate = useNavigate()
  const { currentTheme } = useTheme();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    setIsLoading(true);
    (async () => {

      if (!selectedCategory && !selectedSubCategory) {
        navigate('/home')
        return;
      }

      if (user && user.sub) {
        if (selectedSubCategory ) {
          const fetchedProducts = await getProductsbySubCategoryId(selectedSubCategory.id, user.sub,selectedRepresentada?.id);
          setProducts(fetchedProducts);
        } else if (selectedCategory ) {
          const fetchedProducts = await getProductsbyCategoryId(selectedCategory.id, user.sub, selectedRepresentada?.id);
          setProducts(fetchedProducts);
        }
      }
      setIsLoading(false);
      setCurrentPage(1);
    })();
  }, [user, selectedSubCategory, selectedCategory,selectedRepresentada]);

  useEffect(() => {
    window.scrollTo(0, 0);  // Isso irá rolar a página para o topo quando a página atual mudar
  }, [currentPage]);

  const handleBuyClick = (product: Product) => {

    const productQuantity = quantities[product.id] || 1;

    // Criar uma cópia do produto e adicionar a propriedade quantity
    const productWithQuantity = {
      ...product,
      qtde: productQuantity
    };

    if (cart.some(item => item.id === product.id)) {
      setMessage('Produtos já estão no carrinho.');

    } else {

      if (product.saldo < productQuantity) {
        handleQuantityChange(product.id, Number(product.saldo))
        setMessage('Saldo insuficiente para esse produto.');
      }
      else {
        addProduct(productWithQuantity);
        setMessage('Produtos adicionados com sucesso!');
      }

    }
    setQuantities(prevQuantities => {
      if (!prevQuantities[product.id]) {
        return {
          ...prevQuantities,
          [product.id]: 1,
        };
      }
      return prevQuantities;
    });

    setTimeout(() => setMessage(null), 1500);

  };

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [quantities, setQuantities] = useState<{ [key: number]: number }>({});
  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);
  };


  const handleQuantityChange = (productId: number, quantity: number) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const handleBlur = (productId: number) => {
    setQuantities(prevQuantities => {
      if (!prevQuantities[productId]) {
        return {
          ...prevQuantities,
          [productId]: 1,
        };
      }
      return prevQuantities;
    });
  };
  

  const handleSubCategoryChange = (subcategory: SubCategory) => {
    // Adicione aqui qualquer lógica adicional específica para quando uma subcategoria é selecionada
    selectSubCategory(subcategory); // Isso atualizará a subcategoria selecionada no contexto
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (


    <div>
      {message && <div className="success-message">{message}</div>}

      <div className="container container-product-list">
        <div className='filters'>
          <h4 className='divs-list-top-bordered'>Filtros</h4>
          <small>
            Subcategorias de
          </small>
          <h4 className="divs-list-top-bordered">
            {selectedCategory?.descricao}
          </h4>
          <Component.Custom.SubCategoriesList 
      subcategories={selectedCategory?.subCategorias || []}
      selectedSubcategoryId={selectedSubCategory?.id}
      onSubCategoryChange={handleSubCategoryChange}
    />
        </div>
        <div className='list-products columns-4'>
          <div className="page-header">
            <h1 >{selectedCategory?.descricao} </h1>
          </div>
          {loading ? (
            <div className="product-container">
              <Component.Shared.LoaderComponent />
            </div>
          ) : products.length === 0 ? ( // Adicione esta condição
            <div className="no-products-message">
              Não houve resultados para esta categoria.
            </div>
          ) : (
            products.slice(startIndex, endIndex).map((product, index) => (
              <article className="product" key={index}>
                <Link to={`/produtos/detalhes/${product.id}`}
                  state={{ value: product }}
                  style={{ color: currentTheme.color.fontColorsTitle }}>
                  <div className="teste2-click" onClick={() => handleProductClick(product)}>

                    <Component.Shared.ImageProductComponent className="item-image" source={product.imagens.length > 0 ? `${BASE_URL}uploads/${product.imagens[0].imagem}` : noimage} />
                    <div className="item-name-product">
                      <Component.Shared.TextComponent fontWeight={400}  margin={0} value={product.nome} color={product.saldo > 0 ? "#444444" : "#C9C9C9"} size={16} align="center" />
                      <Component.Shared.TextComponent margin={5} value={'R$ ' + product.preco.toFixed(2)} color={product.saldo > 0 ? "#646464" : "#696969"} size={18} align="center" />
                    </div>
                  </div>
                </Link>
                {product.saldo > 0 ? (
                  <div className="input-button-container">
                    <input
                      type="number"
                      value={quantities[product.id] || ''}
                      className='input-cart teste2'
                      onBlur={() => handleBlur(product.id)}
                      onChange={e => handleQuantityChange(product.id, Number(e.target.value))}
                    />
                    {
                      product.variacao === true ? (

                        <Link to={`/produtos/detalhes/${product.id}`}
                          state={{ value: product }}
                          style={{ color: currentTheme.color.fontColorsTitle }}>
                          <button
                            id={`buy-button-${index}`}
                            type="submit"
                            className="btn btn-success teste2"
                          >
                            <i className="fa fa-cart-arrow-down"></i> ESCOLHER
                          </button>
                        </Link>

                      ) :

                        (
                          <button
                            id={`buy-button-${index}`}
                            type="submit"
                            className="btn btn-success teste2"
                            onClick={() =>  handleBuyClick(product)}
                          >

                            <i className="fa fa-cart-arrow-down"></i> COMPRAR
                          </button>
                        )
                    }

                  </div>
                ) :
                  <h4>INDISPONÍVEL</h4>}

              </article>
            )))}

          <Component.Custom.Pagination totalItems={products.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage} />

        </div>
      </div>
    </div>
  );
};


export default ProductListComponent;