import Component from '../../components';
import './index.css'
import { FaMicrosoft, FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import noimage from '../../../images/noimg.jpg';
import { BASE_URL } from "../../../services/api";
import { Variacao } from '../../../types/product.type';
import Swal from 'sweetalert2';
import { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../../context/cart.provider';
type ProductCardProps =
    {
        id: number
        imagem: string
        nome: string
        qtde: number
        preco: number
        onRemove: (id: number) => void;
        desabilitarbotoes: boolean,
        variacoes: Variacao[];
    }

const ProductCardComponent = (props: ProductCardProps) => {
    const [quantidade, setQuantidade] = useState(props.qtde); // Passo 2

    useEffect(() => {
        setQuantidade(props.qtde);
    }, [props.qtde]);

    const { updateProductQtde } = useContext(CartContext);

    const handleQuantidadeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const novaQuantidade = parseInt(event.target.value, 10);
        if (!isNaN(novaQuantidade) && novaQuantidade >= 0) {
            setQuantidade(novaQuantidade);
            updateProductQtde(props.id, novaQuantidade); // Atualiza a quantidade no contexto
        }
    };
    const confirmarExclusao = (id: number) => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: "Você deseja mesmo remover este item do seu carrinho?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
          
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
        }).then((result) => {
            if (result.isConfirmed) {
                props.onRemove(id);
            }
        });
    };
    const incrementQuantidade = () => {
        const novaQuantidade = quantidade + 1;

      //  if (props.saldo < novaQuantidade) {
          
          //  return;
         // }

        setQuantidade(novaQuantidade);
        updateProductQtde(props.id, novaQuantidade); // Atualize a quantidade no contexto, se estiver usando
    };

    const decrementQuantidade = () => {
        const novaQuantidade = quantidade > 1 ? quantidade - 1 : 1; // Evita que a quantidade fique abaixo de 1
        setQuantidade(novaQuantidade);
        updateProductQtde(props.id, novaQuantidade); // Atualize a quantidade no contexto, se estiver usando
    };
    return (
        <div>
            <div className='testando'>
                <div className='image-cart'>
                    <Component.Shared.ImageProductComponent className="item-image-card" source={props.imagem ? `${BASE_URL}uploads/${props.imagem}` : noimage} />
                </div>
                <div style={{ width: '80%', display: 'flex', flexDirection: 'column', marginLeft: '5px', marginTop: '10px' }}>
                    <div className="product-info">
                        <Component.Shared.TextComponent margin={0} value={props.nome} color="#696969" size={14} align="center" />
                    </div>
                    <div className="product-actions">
                        <div className="input-group">
                            
                            {!props.desabilitarbotoes  && (
                                <button className="btn btn-outline-secondary bootstrap-touchspin-down" type="button" disabled={props.desabilitarbotoes} onClick={decrementQuantidade}>
                                <FaMinus />
                            </button>

                            
                            )}
                            
                            <input
                                type="number"
                                value={quantidade}
                                disabled
                                className="form-control"
                               
                            />
                            {!props.desabilitarbotoes  && (
                                <button className="btn btn-outline-secondary bootstrap-touchspin-up" type="button" disabled={props.desabilitarbotoes} onClick={incrementQuantidade}>
                                <FaPlus />
                                </button>  
                            )}
                          
                        </div>
                        <Component.Shared.TextComponent margin={0} value={'R$ ' + props.preco.toFixed(2)} color="black" size={15} align="center" />
                        {!props.desabilitarbotoes && (
                            <FaTrash title='Excluir do carrinho' onClick={() => confirmarExclusao(props.id)}
                                style={{ marginLeft: '1px', cursor: 'pointer' }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div >
                {props.variacoes && props.variacoes.map((variation) => (
                    <Component.Custom.VariationCardThumb
                        object={variation}
                    />
                ))}
            </div>
        </div>
    )
}

export default ProductCardComponent;