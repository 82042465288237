import { z } from 'zod'

 const addressSchema = z
  .object({
    endereco: z.string().min(3, 'Obrigatório'),
    bairro: z.string().min(3, 'Obrigatório'),
    cidade: z.string().min(3, 'Obrigatório'),
    uf: z.string().min(2, 'Obrigatório'),
    numero: z.string().min(1, 'Obrigatório'),
    cep: z.string().min(8, 'Obrigatório'),
    complemento: z.string().min(1, 'Obrigatório')
  })
export {addressSchema};