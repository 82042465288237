import { useEffect, useState } from "react";
import PaginationComponent from "../../../pagination/pagination.components";
import api from "../../../../../services/api";
import Component from "../../../../components";
import { format } from 'date-fns';

type Type =
    {
        totalA: number;
        totalB: number;
        variacao: number;
        porcentagem: number;
    }
const GeralVendas: React.FC = () => {
    const [list, setList] = useState<Type>()

    const [loading, setIsLoading] = useState(false);


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    const [per1A, setPer1A] = useState<Date | null>(null);
    const [per2A, setPer2A] = useState<Date | null>(null);
    const [per1B, setPer1B] = useState<Date | null>(null);
    const [per2B, setPer2B] = useState<Date | null>(null);

    const handleDateChange1A = (date: Date | null) => {
        setPer1A(date);
    };
    const handleDateChange2A = (date: Date | null) => {
        setPer2A(date);
    };
    const handleDateChange1B = (date: Date | null) => {
        setPer1B(date);
    };


    const handleDateChange2B = async (date: Date | null) => {
        setPer2B(date);

        setIsLoading(true);

        const formattedPerA1 = per1A ? format(per1A, 'dd/MM/yyyy') : '';
        const formattedPerA2 = per2A ? format(per2A, 'dd/MM/yyyy') : '';
        const formattedPerB1 = per1B ? format(per1B, 'dd/MM/yyyy') : '';
        const formattedPerB2 = date ? format(date, 'dd/MM/yyyy') : '';


        await api.get(`relatorios/geral_vendas?per1A=${formattedPerA1}&per2A=${formattedPerA2}&per1B=${formattedPerB1}&per2B=${formattedPerB2}`
        ).then(response => {
            setList(response.data)
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })


    };

    return (
        <div>
            <div className="divisor-container">
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Intervalo A" onDateChange={handleDateChange1A} />
                </div>
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Intervalo A" onDateChange={handleDateChange2A} />
                </div>
            </div>
            <div className="divisor-container">
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Intervalo B" onDateChange={handleDateChange1B} />
                </div>
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Intervalo B" onDateChange={handleDateChange2B} />
                </div>
            </div>
            <div className="grid-container">
                {loading && (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent overlay />
                    </div>
                )}
                <table>
                    <thead>
                        <tr>
                            <th>Total A</th>
                            <th>Total B</th>
                            <th>Variação</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && (
                            <tr key={list.totalA.toFixed(2)}>
                                <td>R$ {list.totalA.toFixed(2)}</td>
                                <td>R$ {list.totalB.toFixed(2)}</td>
                                <td>R$ {list.variacao.toFixed(2)}</td>
                                <td>%{list.porcentagem.toFixed(2)}</td>

                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default GeralVendas;