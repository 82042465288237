import styled from 'styled-components';
import React, { useState } from 'react';
import { FaWarehouse, FaTag, FaBarcode } from 'react-icons/fa';
import Product from '../../types/product.type';
import Component from '../components';
import { BASE_URL } from '../../services/api';
import noimage from '../../images/noimg.jpg';

type StyledCardProps = {
  inativar: boolean;
};
type ProductCardProps = {
  produto: Product;
};

const StyledCard = styled.div<StyledCardProps>`
  padding: 10px;
  margin: 0;
  cursor: ${({ inativar }) => (inativar ? 'default' : 'pointer')};
  transition: background-color 0.3s;
  border: 1px solid gray;
  display: flex; // Ajuste para usar Flexbox
  align-items: center; // Alinha itens verticalmente ao centro

  &:hover {
    background-color: #f0f0f0;
  }

  .product-image2 {
    flex-shrink: 0; // Impede que a imagem encolha
    width: 80px; // Largura máxima da imagem
    height: 50px; // Altura máxima da imagem
    object-fit: cover; // Garante que a imagem cubra o espaço disponível
    margin-right: 10px; // Espaço entre a imagem e o texto
    cursor: zoom-in; // Cursor de ampliar
  }

  .headerv {
    display: flex;
    align-items: baseline;
    font-size: 1em;
    max-width: 100%;
    margin-bottom: 0 !important; // Espaço entre o cabeçalho e o conteúdo abaixo
  }

  .nome {
    margin-right: 0.5em; // Espaço entre o nome e o CNPJ
  }

  .cnpj {
    color: #999;
    font-weight: normal;
    line-height: 1;
  }

  .dadosclicard {
    margin-bottom: 0 !important;
  }

  .telefone,
  {
    margin-right: 1em !important;
    margin-bottom: 0 !important;
  }
  .cidade {
    margin-bottom: 0 !important;
  }

  .icons {
    margin-right: 0.2em;
  }

  .contact-info {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-width: 85px;
  }

  .email-info {
    display: flex;
    align-items: center;
    overflow-wrap: break-word; // Isso permite que o texto quebre para a nova linha
    word-break: break-word; // Quebra a palavra ao alcançar o fim do container
    hyphens: auto; // Adiciona hífens se necessário
    max-width: calc(100% - 120px); // Limita a largura máxima, 20px é o dobro do padding para ajuste
    margin-bottom: 0 !important;
  }

  .email {
    overflow: hidden; // Esconde qualquer texto que ultrapasse o container
    text-overflow: ellipsis; // Adiciona "..." se o texto for muito longo para o container
    white-space: nowrap; // Mantém o texto do e-mail em uma única linha
    margin-right: 1em !important;
    margin-bottom: 0 !important;
  }

  .barcode {
    overflow: hidden; // Esconde qualquer texto que ultrapasse o container
    text-overflow: ellipsis; // Adiciona "..." se o texto for muito longo para o container
    white-space: nowrap; // Mantém o texto do e-mail em uma única linha
    margin-right: 1em !important;
    margin-bottom: 0 !important;
    font-size: 12px;
  }

  @media (max-width: 445px) {
    .telefone,
    {
      margin-right: -15px !important;
      margin-left: -15px !important;
      margin-bottom: 0 !important;
      font-size: 14px;
    }

    .email {
      font-size: 14px;
    }

    .icons {
      height: 10px;
      width: 10px;
    }

    .contact-info .icons {
      margin-right: 14px;
    }
  }
`;

const ProductCard: React.FC<ProductCardProps> = ({ produto }) => {
  const [showModal, setShowModal] = useState(false);

  const handleImageClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Previne a propagação do evento para o contêiner pai
    setShowModal(true);
  };

  const imageUrl = produto.imagens.length > 0 ? `${BASE_URL}uploads/${produto.imagens[0].imagem}` : noimage;

  return (
    <StyledCard inativar={produto.saldo === 0}>
      {/* Imagem do Produto */}
      {produto.imagens.length > 0 ? (
        <Component.Shared.ImageProductComponent
          className="product-image2"
          source={imageUrl}
          onClick={handleImageClick}
        />
      ) : (
        <Component.Shared.ImageProductComponent className="product-image2" source={noimage} onClick={handleImageClick} />
      )}

      <div>
        <h5 className="nome">{produto.nome} - {produto.id}</h5>
        <div className="headerv">
          <div className="contact-info">
            <FaTag size={14} className='icons' />
            <p className='telefone'>R${produto.preco.toFixed(2)}</p>
          </div>
          <div className="email-info">
            <FaWarehouse size={14} className='icons' /><p className='email'>{produto.saldo}</p>
          </div>
          <div className="email-info">
            <FaBarcode size={14} className='icons' /><p className='barcode'>{produto.barras}</p>
          </div>
        </div>
      </div>
      {showModal && (
        <Component.Shared.Modal show={showModal} onClose={() => setShowModal(false)} imageSrc={imageUrl} />
      )}
    </StyledCard>
  );
};

export default ProductCard;
