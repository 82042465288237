import { useEffect, useState, useContext } from "react";
import Component from "../../components";

import Order from "../../../types/order.type";
import './index.css';
import api from "../../../services/api";
import { AuthContext } from '../../../context/auth.provider';
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../context/auth.store";
export const PanelComponent = () => {
  const [loading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(16);
  const { user } = useContext(AuthContext);
  const { logout } = useAuthStore()
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.sub) { // Adicione esta verificação para garantir que user e user.sub existam
      setIsLoading(true);
      (async () => {
        const response = await api.get(`/pedidos`);
        setOrders(response.data);
        setIsLoading(false);
      })();
    }
  }, [user]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handleRowClick = (order: Order) => {
    navigate(`/panel/detalhes/${order.id}`, { state: order });
  }

  const handleExit = () => {
    logout();
    navigate(`/`);
  }

  return (

    <div className="container container-product-list">
      <div className='filters'>
        <h4 className='divs-list-top-bordered'>Meus Pedidos</h4>
        <p className="clickable-row">HISTÓRICO DE COMPRAS</p>
        <p onClick={() => { handleExit(); }} className="clickable-row">SAIR</p>
      </div>
      <div className='list-products row'>
        <div className="page-header">
          <h1 >HISTÓRICO DE COMPRAS</h1>
        </div>
        {loading ? (
          <div className="product-container">
            <Component.Shared.LoaderComponent />
          </div>
        ) : orders.length === 0 ? (
          <div className="no-products-message">
            Nenhuma compra no período.
          </div>
        ) : (
          <table className="orders-table">
            <thead >
              <tr>
                <th>Pedido</th>
                <th>Data</th>
                <th>Total</th>
                <th>Situação</th>

              </tr>
            </thead>
            <tbody>
              {orders.slice(startIndex, endIndex).map((order, index) => (
                <tr key={index} onClick={() => handleRowClick(order)} className="clickable-row">
                  <td><p>{order.id}</p></td> {/* Supondo que haja um ID de pedido */}
                  <td><p>{order.data}</p></td> {/* Supondo que haja um campo de data */}
                  <td><p>{order.totalPedido.toFixed(2)}</p></td>
                  <td><p>{order.situacao}</p></td> {/* Supondo que haja um campo de situação */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Component.Custom.Pagination totalItems={orders.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage} />
      </div>
    </div>
  );
}

export default PanelComponent;