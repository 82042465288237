import styled from 'styled-components';
import React from 'react';
import { FaCircle, FaEnvelope, FaExclamation, FaExclamationCircle, FaMapMarker, FaPhoneAlt, FaRegIdCard } from 'react-icons/fa';
import Client from '../../types/client.type';

type ClienteCardProps = {
  cliente: Client;
};

type StyledCardProps = {
  inativar: boolean;
};
const StyledCard = styled.div<StyledCardProps>`
  padding: 10px;
  margin: 0;
  cursor: ${({ inativar }) => (inativar ? 'default' : 'pointer')};
  transition: background-color 0.3s;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 10px;
  border-radius: 5px;
  color: ${({ inativar }) => (inativar ? 'red' : 'black')};
  pointer-events: ${({ inativar }) => (inativar ? 'none' : 'auto')};

  &:first-child {
    border-top: 1px solid #e7e7e7;
  }

  &:hover {
    background-color: ${({ inativar }) => (inativar ? 'inherit' : '#f0f0f0')};
  }

.headerv {
  display: flex;
  align-items: baseline;
  font-size: 1em;
  max-width: 100%;
  margin-bottom: 0 !important; // Espaço entre o cabeçalho e o conteúdo abaixo
}

.nome {
  margin-right: 0.5em; // Espaço entre o nome e o CNPJ

}

.cnpj {
  color: #999;
  font-weight:normal;
  line-height: 1;
}

.dadosclicard
{
  margin-bottom: 0 !important;
}

.telefone,
{
  margin-right: 1em !important;
  margin-bottom: 0 !important;
}
.cidade
{
  margin-bottom: 0 !important;
}

.icons
{
  margin-right: 0.2em;
}

.contact-info {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}


.pendente
{
  margin-bottom: 0 !important;
  font-weight:bold !important;
  line-height: 1 !important;
}

  .email-info {
    display: flex;
    align-items: center;
    overflow-wrap: break-word; // Isso permite que o texto quebre para a nova linha
    word-break: break-word; // Quebra a palavra ao alcançar o fim do container
    hyphens: auto; // Adiciona hífens se necessário
    max-width: calc(100% - 120px); // Limita a largura máxima, 20px é o dobro do padding para ajuste
    margin-bottom: 0 !important;

  }

  .email {
    overflow: hidden; // Esconde qualquer texto que ultrapasse o container
    text-overflow: ellipsis; // Adiciona "..." se o texto for muito longo para o container
    white-space: nowrap; // Mantém o texto do e-mail em uma única linha
    margin-right: 1em !important;
    margin-bottom: 0 !important;
  }
  .nome-info {
    min-height:50px !important;
  }
`;

function aplicarMascaraCpfCnpj(valor: string) {
  // Remove caracteres não numéricos
  const apenasNumeros = valor.replace(/\D/g, '');

  // Verifica se é CPF ou CNPJ e aplica a máscara correspondente
  if (apenasNumeros.length <= 11) { // CPF
    return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else { // CNPJ
    return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}

const ClienteCard: React.FC<ClienteCardProps> = ({ cliente }) => {
  return (
    <StyledCard inativar={cliente.inativar}>

      <div className="nome-info" >
      <h5 className="nome">{cliente.nome} </h5>
      </div>

      {cliente.tipo === "N" && (
        <div className="headerv">
          <FaExclamationCircle size={14} className='icons' />
          <p className='pendente'>Em análise</p>
        </div>
      )}
      <div className="headerv">
        <FaRegIdCard size={14} className='icons' />
        <p className='telefone'> {aplicarMascaraCpfCnpj(cliente.cnpj_cpf)}</p>
      </div>
      <div className="headerv">
        <div className="contact-info">
          <FaPhoneAlt size={14} className='icons' />
          <p className='telefone'> {cliente.telefone}</p>
        </div>
        <div className="email-info" title={cliente.email}>
          <FaEnvelope size={14} className='icons' /><p className='email'>  {cliente.email}</p>
        </div>
      </div>
      <div className="headerv">
        <FaMapMarker size={14} className='icons' /> <p className='cidade'>{cliente.cidade}, {cliente.uf}</p>
      </div>
    </StyledCard>
  );
};

export default ClienteCard;
