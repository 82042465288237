import React, { useState, useContext } from 'react';
import './index.css';
import ProductCardComponent from '../product-card/product.card.components';
import { CartContext } from '../../../context/cart.provider';
import { Link } from 'react-router-dom';



const CartComponent = () => {
  // Adicione o conteúdo do carrinho aqui
  const { cart, removeProduct, get } = useContext(CartContext);

  // Define a altura máxima em pixels para o conteúdo do carrinho
  const maxHeight = 300;

  // Verifica se a altura do conteúdo do carrinho ultrapassa a altura máxima
  const showScrollbar = cart.length * 100 > maxHeight;

  return (
    <div className='cart-box'>
      <h4>Carrinho</h4>
      <div style={{ height: maxHeight, overflowY: showScrollbar ? 'scroll' : 'auto' }}>

        {cart.length > 0 ?
          cart.map((product) => (
            <ProductCardComponent
              id={product.id}
              key={product.id}
              imagem={product.imagens.length > 0 ? product.imagens[0].imagem : ''}
              nome={product.nome}
              qtde={product.qtde}
              preco={product.preco}
              variacoes={product.variacoes}
              desabilitarbotoes={false}
              onRemove={removeProduct}
            />
          )) :
          <div>
            <span>Seu carrinho está vazio!</span>
            <br />
            <p>Para adicionar produtos ao seu carrinho, navegue pelas categorias ou utilize a busca do site.</p>
          </div>

        }


      </div>

      <Link to={`/checkout`}>
        <button className="form-control btn btn-success button" type='submit'>COMPRAR</button>
      </Link>
    </div>
  );
};

export default CartComponent;