import { ReactNode, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth.provider";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { pagtoInsertSchema } from "../../../schemas/cadPagto.schemas";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import UserProps from "../../../../types/user.type";
import api from "../../../../services/api";
import { AxiosRequestConfig } from "axios";
import Component from "../../../components";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import './cadPagto.css';

type CondPagto = {
  id: string;
  descricao: string;
  padrao: boolean;
};

const CadastroCondPagto: React.FC = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [condPagtoAtual, setCondPagtoAtual] = useState<CondPagto>({
    id: '0',
    descricao: '',
    padrao: false,
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CondPagto>({ resolver: zodResolver(pagtoInsertSchema) });

  const [isEditing, setIsEditing] = useState(false);
  const [condPagtoToEdit, setCondPagtoToEdit] = useState<CondPagto | null>(null);
  const [condPagtos, setCondPagtos] = useState<CondPagto[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isNewCadastro, setIsNewCadastro] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<CondPagto[]>([]);
  const indexOfLastProduct = currentPage * itemsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentPagtos = condPagtos.slice(indexOfFirstProduct, indexOfLastProduct);
  const currentProductsP = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  useEffect(() => {
    const token = Cookies.get('AccessToken');
    if (!token) {
      navigate('/login');
      return;
    } else {
      const decoded = jwtDecode<UserProps>(token);
      if (decoded.exp * 1000 < Date.now()) {
        Cookies.remove('AccessToken');
        navigate('/login');
        return;
      }
    }
  }, [navigate]);

  const onFocus = (fieldName: keyof typeof condPagtoAtual) => {
    setValue(fieldName, '');
    setCondPagtoAtual({
      ...condPagtoAtual,
      [fieldName]: '',
    });
  };

  useEffect(() => {
    console.log(user)
    const fetchData = async () => {
      setIsLoading(true);
      
        try {
          await listCondPagto();
        } catch (error) {
          console.error('Ocorreu um erro:', error);
        }
     
      setIsLoading(false);
    };

    fetchData();
  }, [user?.sub]);

  const  listCondPagto = async () => {
    try {
      const response = await api.get('/CondPagtos/listar');
      if (response.status === 200) {
        setCondPagtos(response.data);
      console.log('Condições de pagamento cadastradas:', response.data);
      }
    } catch (error) {
      console.error('Ocorreu um erro ao listar as condições de pagamento:', error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setCondPagtoAtual({
        ...condPagtoAtual,
        [name]: checked,
      });
    } else {
      setCondPagtoAtual({
        ...condPagtoAtual,
        [name]: value,
      });
    }
  };

  const onSubmit = (data: CondPagto) => {
    const userId = user ? user.sub : undefined;
    if (user && user.sub) {
    if (userId) {
      const requestData = {
        ...data,
        UsuarioId: userId,
      };

        api
          .post(`/CondPagtos/inserir`, requestData)
          .then(response => {
            setCondPagtoAtual({
              id: '0',
              descricao: '',
              padrao: false,
            });
            listCondPagto();
            setIsEditing(false)
            console.log('Condição de pagamento adicionada com sucesso.');
            
            setSuccessMessage('Cadastro realizado com sucesso.');
            setTimeout(() => {
              setSuccessMessage('');
            }, 2000);
          })
          .catch(error => {
            console.error('Erro ao adicionar a condição de pagamento:', error);
          });
        }
      }
  };
  const [PagtoEmEdicao, setPagtoEmEdicao] = useState<string | null>(null);
  const editCondPagto = (id: string) => {
    const condPagtoParaEditar = condPagtos.find((condPagto) => condPagto.id === id);
  
    if (condPagtoParaEditar) {
      setCondPagtoAtual({
        id: condPagtoParaEditar.id,
        descricao: condPagtoParaEditar.descricao,
        padrao: condPagtoParaEditar.padrao,
      });
      setPagtoEmEdicao(id)
      setIsEditing(true);
    } else {
      console.error(`Condição de pagamento com ID não encontrada.`);
    }
  };
  
  const handleEditCondPagto = (data: CondPagto) => {
    const userId = user ? user.sub : undefined;
    console.log(user)
    setCondPagtoAtual(data);
    setIsEditing(true);
    if (user && user.sub) {
    if (userId) {
      const requestData = {
        ...data,
        UsuarioId: userId,
      };
  
      api
        .put(`/CondPagtos/alterar`, requestData)
        .then(response => {
          if (response.status === 204) {
            listCondPagto();
            setCondPagtoAtual({
              id: '0',
              descricao: '',
              padrao: false,
            });
            setIsEditing(false);
            setPagtoEmEdicao(null);
            console.log('Condição de pagamento editada com sucesso.');
          } else {
            console.error('Erro ao editar a condição de pagamento.');
          }
        })
        .catch(error => {
          console.error('Erro ao editar a condição de pagamento:', error);
        });
      }
    };
  }
  
  const handleExcluirCondPagto = (data: CondPagto) => {
    const userId = user ? user.sub : undefined;
    if (!userId) {
      console.error('ID de usuário não disponível.');
      return;
    }
    if (user && user.sub) {
    const request: AxiosRequestConfig = {
      data: {
        ...data,
        UsuarioId: userId,
      },
    };

    api
      .delete('/CondPagtos/excluir', request)
      .then(response => {
        if (response.status === 204) {
          console.log('Condição de pagamento excluída com sucesso.');
          listCondPagto();
        } else {
          console.error('Erro ao excluir a condição de pagamento.');
        }
      })
      .catch(error => {
        console.error('Erro ao enviar a solicitação para excluir a condição de pagamento:', error);
      });
    }
  };

  const handleCancel = (event: { preventDefault: () => void; }) => {
      event.preventDefault();
    if (isEditing) {
      // Se estiver editando, limpe o estado atual do produto
        setCondPagtoAtual({
          id: '0',
          descricao: '',
          padrao: false,
        });
      setIsEditing(false);
      setPagtoEmEdicao(null);
      setIsNewCadastro(false); 
    }
  };

    const pesqPagto = () => {
      const filtered = condPagtos.filter((pagto) =>
        pagto.descricao.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    };

    useEffect(() => {
      pesqPagto();
    }, [searchTerm]);

    const cancelSearch = () => {
      if (user && user.sub) {
      setFilteredProducts(condPagtos); 
      setSearchTerm('');  
      listCondPagto()    
      }     
    };

  return (
    <div>
      
      {successMessage && (
          <div className="success-message">{successMessage}</div>
          )}
      <div>
          <div className="box-insert" onClick={() => setIsEditing(true)} >
            <span className="text-button-insert">Novo </span>
            <FaPlus title="Adicionar novo registro" color="white" size={25} />
          </div>
          <div className="divisor-container">
            <div className="divisor1">
              <input
                type="text"
                placeholder="Pesquisar Condição de Pagamento"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="divisor2">
              <button 
                className="button-list"
                type="button" 
                onClick={pesqPagto}>
                Pesquisar
              </button>
              <button 
                type="button" 
                className="button-list"
                onClick={cancelSearch}>
                Cancelar
              </button>
            </div>
          </div>
          <div className="grid-container">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Descrição</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
              {searchTerm ? (
                  currentProductsP.map((condPagtos) => (
                      <tr key={condPagtos.id}>
                        <td>{condPagtos.id}</td>
                        <td>{condPagtos.descricao}</td>
                        <td>
                          <div className="box-update">
                            <FaPen onClick={() => editCondPagto(condPagtos.id)} title="Alterar registro" size={20} color="white" />
                          </div>
                          <div className="box-delete">
                            <FaTrash onClick={() => handleExcluirCondPagto(condPagtos)} title="Excluir registro" color="white" size={20} />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : ( currentPagtos.map((condPagtos) => (
                      <tr key={condPagtos.id}>
                        <td>{condPagtos.id}</td>
                        <td>{condPagtos.descricao}</td>
                        <td>
                          <div className="box-update">
                            <FaPen onClick={() => editCondPagto(condPagtos.id)} title="Alterar registro" size={20} color="white" />
                          </div>
                          <div className="box-delete">
                            <FaTrash onClick={() => handleExcluirCondPagto(condPagtos)} title="Excluir registro" color="white" size={20} />
                          </div>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
            </div>
              <Component.Custom.Pagination
              currentPage={currentPage}
              totalItems={condPagtos.length}
              setCurrentPage={handlePageChange}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />

            <div className="edit-status">
              {isEditing ? (
                <p>Campos habilitados para edição.</p>
              ) : (
                <p>
                  Campos desabilitados. Clique em "Novo" para editar.
                </p>
              )}
            </div>
            <div className="order-edit-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="CadastroCondPagto">
              <label htmlFor="padrao"> Padrão:</label>
              <div className="check">
                <input
                  {...register('padrao')}
                  type="checkbox"
                  id="padrao"
                  name="padrao"
                  checked={condPagtoAtual.padrao}
                  onChange={() => setCondPagtoAtual({ ...condPagtoAtual, padrao: !condPagtoAtual.padrao })}
                  disabled={!isEditing}
                />
                </div>
                {errors.padrao ? (
                  <Component.Shared.TextComponent
                    value={errors.padrao.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
              </div>
              <input
                {...register('id')}
                type="hidden"
                name="id"
                value={condPagtoAtual.id}
              />
                <div className='nome-container'>
                <label htmlFor="descricao">Nome:</label>
                <input
                  {...register('descricao')}
                  type="text"
                  id="descricao"
                  name="descricao"
                  value={condPagtoAtual.descricao}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
               
                {errors.descricao ? (
                  <Component.Shared.TextComponent
                    value={errors.descricao.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
              </div>
            
            <div className="buttonF">
              <button 
              className="btn btn-secondary button" 
              type='button'
              onClick={handleCancel}
              disabled={!isEditing}
              >
                Cancelar
              </button>
              <button 
              className="btn btn-primary button" 
              type='submit'
              disabled={!isEditing}
              >
                Cadastrar
              </button>
                {PagtoEmEdicao ? (
                  <button 
                  type="button" 
                  onClick={() => handleEditCondPagto(condPagtoAtual)}
                  className="btn btn-primary button" 
                  >
                    Salvar Alteração
                  </button>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
  );
};

export default CadastroCondPagto;
