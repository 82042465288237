import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth.provider";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { categoriaInsertSchema } from "../../../schemas/cat.schemas";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import UserProps from "../../../../types/user.type";
import api from "../../../../services/api";
import { AxiosRequestConfig } from "axios";
import Component from "../../../components";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import '../cadCategoriaSubcategoria/cadCategoriaSubcategoria.css'

type Category ={
  id: number;
  descricao: string; 
}

const CadastroCategoria: React.FC = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [categoriaAtual, setCategoriaAtual] = useState<Category>({
    id: 0,
    descricao: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Category>({
    resolver: zodResolver(categoriaInsertSchema),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [categorias, setCategorias] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingCatId, setEditingCatId] = useState<number | null>(null);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isNewCadastro, setIsNewCadastro] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<Category[]>([]);

 
  const [currentPage, setCurrentPage] = useState(1); 
  const [itemsPerPage, setItemsPerPage] = useState(10); 

  // Calcula a lista de produtos a ser exibida na página atual
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts =  categorias.slice(indexOfFirstProduct, indexOfLastProduct);
  const currentProductsC = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  useEffect(() => {
    const token = Cookies.get('AccessToken');
    if (!token) {
      navigate('/login');
      return;
    } else {
      const decoded = jwtDecode<UserProps>(token);
      if (decoded.exp * 1000 < Date.now()) {
        Cookies.remove('AccessToken');
        navigate('/login');
        return;
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);
      
          try {
            await fetchCategory();
          } catch (error) {
            console.error('Ocorreu um erro:', error);
          }
       
        setIsLoading(false);
    };
    fetchData();
  }, [user?.sub]);

  const fetchCategory = async () => {
    try {
      const response = await api.get('/Categorias/listar');
      if (response.status === 200) {
        const categoriadata = response.data as Category[];
        setCategorias(response.data);
        console.log('Categorias',categoriadata) 
      } else {
        console.error('Erro ao listar as categorias.');
      }
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name } = event.target;
  
    if (event.target instanceof HTMLSelectElement) {
      const selectedOptions = Array.from(event.target.selectedOptions).map((option) => option.value);
  
      setCategoriaAtual({
        ...categoriaAtual,
        [name]: selectedOptions,
      });
    } else {
      setCategoriaAtual({
        ...categoriaAtual,
        [name]: event.target.value,
      });
    }
  };
  
  const onSubmit = (data: Category) => {
    const userId = user ? user.sub : undefined;
    if (user && user.sub) {
    if (userId) {
      const userId = user ? user.sub : undefined;

      const requestData = {
        ...data,
        UsuarioId: userId,
      };

      api
        .post('/Categorias/inserir', requestData)
        .then((response) => {
          setCategoriaAtual({
            id: 0,
            descricao: '',
          });
          fetchCategory();

          setSuccessMessage('Cadastro realizado com sucesso.');
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        })
        .catch((error) => {
          console.error('Erro ao adicionar a categoria:', error);
        });
      }
    }
  };
  
  const editCategoria = (id: number) => {
    const categoriaParaEditar = categorias.find((categoria) => categoria.id === id);

    if (categoriaParaEditar) {
      setCategoriaAtual(categoriaParaEditar);
      setEditingCatId(id)
      setIsEditing(true);
    } else {
      console.error('Categoria com ID não encontrada.');
    }
  };

  const handleEditCategoria = (data: Category) => {
   
    setCategoriaAtual(data);
    setIsEditing(true);

   
      const requestData = {
        ...data,
      };

      api
        .put('/Categorias/alterar', requestData)
        .then((response) => {
          if (response.status === 204) {
            fetchCategory();
            setCategoriaAtual({
              id: 0,
              descricao: '',
            });
            setIsEditing(false);
            setEditingCatId(null)
            console.log('Categoria editada com sucesso.');
          } else {
            console.error('Erro ao editar a categoria.');
          }
        })
        .catch((error) => {
          console.error('Erro ao editar a categoria:', error);
        });
  };

  const handleExcluirCategoria = (data: Category) => {

  
   
    const request: AxiosRequestConfig = {
      data: {
        ...data,
      },
    };

    api
      .delete('/Categorias/excluir', request)
      .then((response) => {
        if (response.status === 204) {
          console.log('Categoria excluída com sucesso.');
          fetchCategory();
        } else {
          console.error('Erro ao excluir a categoria.');
        }
      })
      .catch((error) => {
        console.error('Erro ao enviar a solicitação para excluir a categoria:', error);
      });
  
  };
  const handleCancel = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (user && user.sub) {
    if (isEditing) {
      // Se estiver editando, limpe o estado atual do produto
        setCategoriaAtual({
          id: 0,
          descricao: '',       
        });
        fetchCategory()
      setIsEditing(false);
      setEditingCatId(null);
      setIsNewCadastro(false)
    }
    }
  };

  useEffect(() => {
    pesqCat();
  }, [searchTerm]);

  const pesqCat = () => {
    const filtered = categorias.filter((cat) =>
      cat.descricao.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const cancelSearch = () => {
    if (user && user.sub) {
    fetchCategory()
    setFilteredProducts(categorias); 
    setSearchTerm('');  
    }        
  };

  return (
    <div>
      <div>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
          )}
            <div className="box-insert" onClick={() => setIsEditing(true)} >
            <span className="text-button-insert">Novo </span>
            <FaPlus title="Adicionar novo registro" color="white" size={25} />
          </div>
          <div className="divisor-container">
            <div className="divisor1">
              <input
                type="text"
                placeholder="Pesquisar Categoria"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="divisor2">
              <button 
                className="button-list"
                type="button" 
                onClick={pesqCat}>
                Pesquisar
              </button>
              <button 
                type="button" 
                className="button-list"
                onClick={cancelSearch}>
                Cancelar
              </button>
            </div>
          </div>
          <div className="grid-container">
        <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {searchTerm ? (
            currentProductsC.map((categoria) => (
              <tr key={categoria.id}>
                <td>{categoria.id}</td>
                <td>{categoria.descricao}</td>
                <td>
                  <div className="box-update">
                    <FaPen onClick={() => editCategoria(categoria.id)} title="Alterar registro" size={20} color="white" />
                  </div>
                  <div className="box-delete">
                    <FaTrash onClick={() => handleExcluirCategoria(categoria)} title="Excluir registro" color="white" size={20} />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            currentProducts.map((categoria) => (
              <tr key={categoria.id}>
                <td>{categoria.id}</td>
                <td>{categoria.descricao}</td>
                <td>
                  <div className="box-update">
                    <FaPen onClick={() => editCategoria(categoria.id)} title="Alterar registro" size={20} color="white" />
                  </div>
                  <div className="box-delete">
                    <FaTrash onClick={() => handleExcluirCategoria(categoria)} title="Excluir registro" color="white" size={20} />
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
          </table>
          </div>
            <Component.Custom.Pagination
            currentPage={currentPage}
            totalItems={categorias.length}
            setCurrentPage={handlePageChange}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
            <div className="edit-status">
              {isEditing ? (
                <p>Campos habilitados para edição.</p>
              ) : (
                <p>Campos desabilitados. Clique em "Novo" para editar.</p>
              )}
            </div>
            <div className="order-edit-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="campos-container">
                <label htmlFor="descricao">Descrição:</label>
                <input
                  {...register('descricao')}
                  type="text"
                  id="descricao"
                  name="descricao"
                  value={categoriaAtual.descricao}
                  onChange={(e) => handleInputChange(e)}
                  disabled={!isEditing}
                />
                {errors.descricao ? (
                  <Component.Shared.TextComponent
                    value={errors.descricao.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
              </div>
              <input
                {...register('id')}
                type="hidden"
                name="id"
                value={categoriaAtual.id}
              />
              {errors.id ? (
                  <Component.Shared.TextComponent
                    value={errors.id.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
            <div className="buttonF">
              <button 
              className="btn btn-secondary button" 
              type='button'
              onClick={handleCancel}
              disabled={!isEditing}
              >
                Cancelar
              </button>
              <button 
              className="btn btn-primary button" 
              type='submit'
              disabled={!isEditing}
              >
                Cadastrar
              </button>
                {editingCatId ? (
                  <button 
                  type="button" 
                  onClick={() => handleEditCategoria(categoriaAtual)}
                  className="btn btn-primary button" 
                  >
                    Salvar Alteração
                  </button>
                ) : null}
              </div>
            </form>
            </div>
          </div>
    
    </div>
  );
};

export default CadastroCategoria;