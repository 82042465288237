import Component from '../../components';
import './index.css'

import noimage from '../../../images/noimg.jpg';
import { BASE_URL } from "../../../services/api";
import { Variacao } from '../../../types/product.type';

type VariationCardThumbProps =
    {
        object : Variacao
    }

const VariationCardThumbComponent = (props: VariationCardThumbProps) => {


    return (
        <div className='variations-card' style={{ padding: '5px 0', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <div style={{ width: '60px', height:'50px', marginRight: '5px', marginLeft:'30px', alignItems:'center' }}>
            <Component.Shared.ImageProductComponent 
                className="img-thumb" 
                source={props.object.imagem ? `${BASE_URL}uploads/${props.object.imagem}` : noimage} 
            />
        </div>
        <div style={{ width: '80%', marginRight: '15px' }}> {/* Ajuste a largura conforme necessário */}
            <Component.Shared.TextComponent 
                margin={0} 
                value={props.object.cod_variacao.toString()} 
                color="#696969" 
                size={14} 
                align="center" 
            />
        </div>
        <div style={{ width: '80px' }}> {/* Ajuste a largura conforme necessário */}
            <Component.Shared.TextComponent 
                margin={0} 
                value={props.object.qtde.toString()} 
                color="#696969" 
                size={14} 
                align="center" 
            />
        </div>
    </div>
    )
}

export default VariationCardThumbComponent;