import { z } from 'zod';

const categoriaInsertSchema = z.object({
  descricao: z.string().min(1, 'Obrigatório'),
  subCategorias: z.string().optional(), 
  id: z.string(),
});

export {categoriaInsertSchema};

