import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Component from "../../../../components";
import { RankingProdutosInputProps, clientlistSchema } from "../../../../schemas/user.schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from 'date-fns';
import { FaSearch } from "react-icons/fa";
import Swal from "sweetalert2";
import Representante from "../../../../../types/representante.type";

const RankingVendas: React.FC = () => {
    const [loading, setIsLoading] = useState(false);
    const [representantes, setRepresentantes] = useState<Representante[]>([]);
    const [representanteid, setRepresentanteId] = useState<number | null>();

    const {
        formState: { errors },
    } = useForm<RankingProdutosInputProps>({ resolver: zodResolver(clientlistSchema) })

    useEffect(() => {

        listarrepresentantes();
    }, []);


    const listarrepresentantes = async () => {

        await api.post('/Representantes/listar'
        ).then(response => {

            const todosRepres = [{ id: 0, nome: 'TODOS' }, ...response.data];
            setRepresentantes(todosRepres);

        }).catch(error => {
        })

    };

    const [perA, setPerA] = useState<Date | null>(null);

    const handleDateChangeA = (date: Date | null) => {
        setPerA(date);
    };

    const [perB, setPerB] = useState<Date | null>(null);

    const handleDateChangeB = async (date: Date | null) => {
        setPerB(date);
    };

    const handleRepresChange = (value: string) => {
        setRepresentanteId(Number(value));
    };


    const handleGerarRelatorio = async () => {

        if (!perA || !perB) {
            Swal.fire({
                title: 'Mensagem',
                text: "Informe o período para gerar o relatório.",
                icon: 'info',
            });
        }

        setIsLoading(true);
        const formattedPerA = perA ? format(perA, 'dd/MM/yyyy') : '';
        const formattedPerB = perB ? format(perB, 'dd/MM/yyyy') : '';

        let url = `/relatorios/ranking_vendas?perA=${formattedPerA}&perB=${formattedPerB}`;
        if (representanteid) {
            url += `&representanteid=${representanteid}`;
        }

        await api.get(url
        ).then(response => {

            if (response.data.length === 0) {
                Swal.fire({
                    title: 'Mensagem',
                    text: "Não há dados disponíveis para exibir no relatório.",
                    icon: 'info',
                });
            }
            else {
                // Cria um URL para o Blob e define como href para um link
                const urlBlob = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

                const link = document.createElement('a');
                link.href = urlBlob;
                link.target = '_blank'; // Abre o link em uma nova aba
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Limpa o link do DOM após o clique
                setIsLoading(false);
            }
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })

    };

    return (
        <div>
            <Component.Shared.TextComponent marginTop={0} value="Período" size={18} color='black' margin={0} align='center' />

            <div className="divisor-container" >
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Data Inicial" onDateChange={handleDateChangeA} />
                </div>
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Data Final" onDateChange={handleDateChangeB} />
                </div>
            </div>
            <div>
                {representantes && representantes.length > 0 &&
                    (
                        <>
                            <Component.Shared.TextComponent marginTop={0} value="Representante" size={18} color='black' margin={0} align='flex-start' />
                            <Component.Shared.SelectComponent
                                margin={0}
                                marginTop={0}
                                align='flex-start'
                                data={representantes}
                                onChange={handleRepresChange}
                                size={16}
                                color="black"
                                placeholder="* Representante"
                            // Desabilita o SelectComponent se houver itens no carrinho
                            />
                        </>
                    )}

                <div className="btn btn-primary button" onClick={() => handleGerarRelatorio()}>
                    <FaSearch size={18} />
                    <span>Gerar relatório</span>
                </div>

            </div>
            {loading && (
                <div className="product-container">
                    <Component.Shared.LoaderComponent overlay />
                </div>
            )}
        </div>
    );
}

export default RankingVendas;