import { useContext, useEffect, useState } from "react";
import Representada from "../../../types/representada.type";
import api from "../../../services/api";
import Component from "../../components";
import { useTheme } from "../../../context/theme.context";
import { CartContext } from '../../../context/cart.provider';
import { RepresentadaContext } from "../../../context/representada.provider";
import { BASE_URL } from "../../../services/api";
import "./index.css";
import { useNavigate } from "react-router-dom";
interface RepresentadaComponentProps {
    onSelecao: (item: Representada) => void;
}

export const RepresentadaComponent = (props: RepresentadaComponentProps) => {

    const [representadas, setRepresentadas] = useState<Representada[]>([]);
    const { clearCart } = useContext(CartContext);
    const { selectRepresentada } = useContext(RepresentadaContext);
    const [loading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true); // começa visível
    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true);
        listar();


    }, []);


    const listar = async () => {

        await api.post('/Fornecedores/buscarrepresentadas'
        ).then(response => {
            setRepresentadas(response.data)
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })

    };

    const { setCurrentTheme } = useTheme();
    const selecionarItem = (item: Representada) => {
        //limpar o carrinho 
        clearCart();
        // Dentro do seu componente que usa o contexto
        setCurrentTheme(prevTheme => ({
            ...prevTheme,
            color: {
                ...prevTheme.color,
                primary: item.cor ? item.cor : '#00b7bd'// nova cor primária
            }
        }));

        props.onSelecao(item);
        selectRepresentada(item);
        navigate('/home');
    };

    return (
        <div>
            {isVisible && (
                loading ? (
                    <Component.Shared.LoaderComponent />
                ) :
                    <div className="overlay">
                        <div className="centered-component">

                            {representadas.map((object, index) => (
                                <>
                                    <div className="painel-representada" key={index}  onClick={() => selecionarItem(object)}>
                                        <Component.Shared.ImageComponent source={object.logo ? `${BASE_URL}uploads/${object.logo}` : ''} width={120} height={41} margin={0} className="logo-representada" />
                                        <span className="texto-representada" >{object.nome}</span>
                                    </div>
                                    <hr />
                                </>
                            ))}

                        </div>
                    </div>
            )}
        </div>)
};

export default RepresentadaComponent;