import { useState, useContext, ChangeEvent } from "react";
import Component from "../../components";


import api from "../../../services/api";
import { AuthContext } from '../../../context/auth.provider';

import axios from "axios";
import states from '../../../types/state'; // Importe a lista de estados
import { CepCheckInputProps, cepcheckSchema } from "../../schemas/user.schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

type NewEnderecoEntregaProps = {
    onSucess: () => void;
}

export const NewEnderecoEntregaComponent = (props: NewEnderecoEntregaProps) => {
    const [loading, setLoading] = useState(false);

    const { user } = useContext(AuthContext);
    const [error, setError] = useState('');
    const [sucess, setSucess] = useState(false);
    const [idUf, setUf] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<CepCheckInputProps>({ resolver: zodResolver(cepcheckSchema) })


    const handleCepChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        const cep = event.target.value.replace(/\D/g, '');
        if (cep.length === 8) {
            setLoading(true);

            axios
                .get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        const data = response.data;
                        // Utilize o setValue para atualizar os campos do formulário com os dados retornados pela API
                        setValue('endereco', data.street);
                        setValue('bairro', data.neighborhood);
                        setValue('cidade', data.city);
                        setValue('uf', data.state);
                        setUf(data.state);
                        setLoading(false);

                    } else {
                        console.error('CEP inválido ou não encontrado.');
                        setLoading(false);

                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar o CEP:', error);
                    setLoading(false);

                });
        }
    };



    const handleChangeUf = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setUf(value);
    };

    const handleSendOrder = async (data: CepCheckInputProps) => {
        await api.post('/enderecoentrega/inserir', data
        ).then(response => {

            setSucess(true);
            props.onSucess();

        }).catch(error => {
            setError('Não foi possível salvar o endereço de entrega. Verifique os campos e tente novamente.');
        })


    };

    return (
        <>
            {loading && (
                <div className="product-container">
                    <Component.Shared.LoaderComponent overlay />
                </div>
            )}
            <div className="form-group">
                <Component.Shared.TextInputComponent
                    size={16}
                    color="black"
                    placeholder="* CEP"
                    mask="cep"
                    type="text"
                    maxLength={9}
                    register={register('cep')}
                    margin={0}
                    marginTop={10}
                    onChange={handleCepChange} // Chama a função handleCepChange quando o valor do CEP mudar
                />
                {errors.cep ? (
                    <Component.Shared.TextComponent value={errors.cep.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}
                <Component.Shared.TextInputComponent register={register('endereco')} marginTop={10} margin={0} size={16} color="black" placeholder="* Endereço" type="text" />
                {errors.endereco ? (
                    <Component.Shared.TextComponent value={errors.endereco.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}
                <div className='inputs-create'>
                    <Component.Shared.TextInputComponent marginTop={10} margin={0} maxLength={6} size={16} color="black" placeholder="* Número" type="text" register={register('numero')} />
                    <Component.Shared.TextInputComponent maxLength={60} register={register('complemento')} marginTop={10} margin={0} size={16} color="black" placeholder="Complemento" type="text" />
                </div>
                {errors.numero ? (
                    <Component.Shared.TextComponent value={errors.numero.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}
                <Component.Shared.TextInputComponent register={register('bairro')} maxLength={60} marginTop={10} margin={0} size={16} color="black" placeholder="* Bairro" type="text" />
                {errors.bairro ? (
                    <Component.Shared.TextComponent value={errors.bairro.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}
                <Component.Shared.TextInputComponent register={register('cidade')} marginTop={10} margin={0} size={16} color="black" placeholder="* Cidade" type="text" />
                {errors.cidade ? (
                    <Component.Shared.TextComponent value={errors.cidade.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                <select
                    defaultValue=""
                    value={idUf}
                    style={{
                        marginTop: 10,
                        alignSelf: 'flex-start',
                        fontSize: 16,
                        color: !idUf ? 'gray' : 'black',

                    }}
                    className='form-control'
                    {...register('uf')}
                    onChange={handleChangeUf}>
                    <option value="" disabled style={{ color: 'white' }}>* UF </option>
                    {states.map((object, index) => (
                        <option key={index} value={object.id}>
                            {object.descricao}
                        </option>
                    ))}
                </select>
                {errors.uf ? (
                    <Component.Shared.TextComponent value={errors.uf.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                {error ? (
                    <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                {sucess ? (
                    <Component.Shared.TextComponent value={'Registro concluído com sucesso.'} size={12} color='green' margin={0} align='flex-start' />
                ) : null}
                <button
                    type="button"
                    onClick={handleSubmit(handleSendOrder)}
                    className="btn btn-success mt-3"
                >
                    <i className="fa fa-cart-arrow-down"></i> ADICIONAR
                </button>
            </div>
        </>
    );
}

export default NewEnderecoEntregaComponent;