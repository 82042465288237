
import axios from "axios";

import Cookies from "js-cookie";
export const BASE_URL = "https://vps.inovaprovas.com.br/";


const api = axios.create({
  baseURL: "https://vps.inovaprovas.com.br/api"
});

api.interceptors.request.use(async (config : any)=> {
  const token = Cookies.get('AccessToken')
 
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export default api;