import { ReactNode, createContext, useState } from "react";
import Representada from "../types/representada.type";


interface RepresentadaContextProps {
  selectedRepresentada: Representada | null;
  selectRepresentada: (representada: Representada | null) => void;
}


export const RepresentadaContext = createContext<RepresentadaContextProps>({
  // ...valores iniciais para outros estados e funções
  selectedRepresentada: null,
  selectRepresentada: () => {},
});

export const RepresentadaProvider = ({ children }: { children: ReactNode }) => {
  // ...outros estados e funções
  const [selectedRepresentada, setSelectedRepresentada] = useState<Representada | null>(null);

  const selectRepresentada = (fornecedor: Representada | null) => {
    setSelectedRepresentada(fornecedor);
  };

  return (
    <RepresentadaContext.Provider value={{ 
      // ...outros estados e funções passadas aqui
      selectedRepresentada, 
      selectRepresentada 
    }}>
      {children}
    </RepresentadaContext.Provider>
  );
};