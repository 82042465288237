import React, { useRef, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import "./index.css";
import Component from '../../../components';
import api from '../../../../services/api';
import Representada from '../../../../types/representada.type';
import { FaBars, FaChartBar } from 'react-icons/fa';
import Representante from '../../../../types/representante.type';
import GraficoPizzaComponent from '../../../shared/grafico-pizza.shared.component';
Chart.register(...registerables);



const HomeContent = () => {
    const [representadas, setRepresentadas] = useState<Representada[]>([]);
    const [representantes, setRepresentantes] = useState<Representante[]>([]);
    const [showDetails, setShowDetails] = useState(false);
    const [showDetailsCarteira, setShowDetailsCarteira] = useState(false);
    const [showDetailsPositivacao, setShowDetailsPositivacao] = useState(false);
    const [showDetailsCurvaABC, setShowDetailsCurvaABC] = useState(false);
    const [statusCarteira, setStatusCarteira] = useState(['']);
    const [statusPositivacao, setStatusPositivacao] = useState(['']);
    const [statusCurvaABC, setstatusCurvaABC] = useState(['']);
    const [representadaid, setRepresentadaId] = useState('0');
    const [represId, setRepresId] = useState('0');
    useEffect(() => {
        setSelectedDate(new Date());
        listarrepresentadas();
        listarrepresentantes();
    }, []); // O array vazio garante que o efeito seja executado apenas uma vez
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const toggleDetails = () => {
        setShowDetails(prev => !prev);
    };

    const toggleDetailsCarteira = () => {
        setShowDetailsCarteira(prev => !prev);
        setShowDetailsCurvaABC(false);
        setShowDetailsPositivacao(false);
    };

    const toggleDetailsPositivacao = () => {
        setShowDetailsPositivacao(prev => !prev);
        setShowDetailsCarteira(false);
        setShowDetailsCurvaABC(false);
    };


    const toggleDetailsCurvaABC = () => {
        setShowDetailsCurvaABC(prev => !prev);
        setShowDetailsPositivacao(false);
        setShowDetailsCarteira(false);


    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const listarrepresentadas = async () => {

        await api.post('/Fornecedores/buscarrepresentadas'
        ).then(response => {

            const todasRepresentadas = [{ id: 0, nome: 'TODOS' }, ...response.data];
            setRepresentadas(todasRepresentadas);

        }).catch(error => {
        })

    };



    const listarrepresentantes = async () => {

        await api.post('/Representantes/listar'
        ).then(response => {

            const todosRepres = [{ id: 0, nome: 'TODOS' }, ...response.data];
            setRepresentantes(todosRepres);

        }).catch(error => {
        })

    };

    const handleRepresentadaChange = (value: string) => {
        setRepresentadaId(value);
    };

    const handleRepresChange = (value: string) => {
        setRepresId(value);
    };


    const handleStatusChange = (status: string[]) => {
        setStatusCarteira(status);
    };

    

    const handleStatusPositivacaoChange = (status: string[]) => {
        setStatusPositivacao(status);
    };

    

    const handleStatusCurvaABCChange = (status: string[]) => {
        setstatusCurvaABC(status);
    };


    return (
        <>
            <div className="header-content">
                <div className="header-itemc periodo">
                    <Component.Shared.TextComponent marginTop={0} value="Período" size={18} color='black' margin={0} align='flex-start' />
                    <Component.Shared.MonthPicker texto="" onDateChange={handleDateChange} value={selectedDate} />
                </div>
                <div className="header-itemc representada">
                    <Component.Shared.TextComponent marginTop={0} value="Representada" size={18} color='black' margin={0} align='flex-start' />
                    <Component.Shared.SelectComponent
                        margin={0}
                        marginTop={0}
                        align='flex-start'
                        data={representadas}
                        onChange={handleRepresentadaChange}
                        size={16}
                        color="black"
                        placeholder="* Representada"
                    // Desabilita o SelectComponent se houver itens no carrinho
                    />
                </div>

                <div className="header-itemc vendedor">
                    {representantes && representantes.length > 0 &&
                        (
                            <>
                                <Component.Shared.TextComponent marginTop={0} value="Representante" size={18} color='black' margin={0} align='flex-start' />
                                <Component.Shared.SelectComponent
                                    margin={0}
                                    marginTop={0}
                                    align='flex-start'
                                    data={representantes}
                                    onChange={handleRepresChange}
                                    size={16}
                                    color="black"
                                    placeholder="* Representante"
                                // Desabilita o SelectComponent se houver itens no carrinho
                                />
                            </>
                        )}
                </div>


            </div>

            <div className='widget-full'>
                <Component.Shared.GraficoBarra selectedDate={selectedDate} selectedRepresentada={Number(representadaid)} selectedRepres={Number(represId)} />
            </div>
            <div className='footer-widget-full' onClick={toggleDetails}>
                {representantes && representantes.length > 0 &&
                    (
                        <>
                            <FaChartBar /> Detalhar por representante
                        </>
                    )}
            </div>
            {showDetails && (
                <Component.Custom.GridDetalhesRepres
                    selectedDate={selectedDate}
                    selectedRepresentada={Number(representadaid)}
                />
            )}

           
                  
                        <div className='grid-container-widget ' >
                            <div className='widget'>
                                <GraficoPizzaComponent onStatusChange={handleStatusChange} selectedRepres={Number(represId)} />
                                <div className='footer-widget-full' onClick={toggleDetailsCarteira}>
                                    <FaChartBar /> Detalhar Carteira
                                </div>
                            </div>

                            <div className='widget'>
                            <Component.Shared.GraficoPositivacao selectedDate={selectedDate} onStatusChange={handleStatusPositivacaoChange} selectedRepres={Number(represId)}/>
                                <div className='footer-widget-full' onClick={toggleDetailsPositivacao}>
                                    <FaChartBar /> Detalhar Positivação
                                </div>
                            </div>

                            <div className='widget'>
                            <Component.Shared.GraficoCurvaABC selectedDate={selectedDate} onStatusChange={handleStatusCurvaABCChange} selectedRepres={Number(represId)}/>
                                <div className='footer-widget-full' onClick={toggleDetailsCurvaABC}>
                                    <FaChartBar /> Detalhar Curva ABC
                                </div>
                            </div>
                        </div>
                        {showDetailsCarteira && (
                            <Component.Custom.GridDetalhesCarteira visibleStatus={statusCarteira} selectedRepres={Number(represId)}
                            />
                        )}
                        {showDetailsPositivacao && (
                            <Component.Custom.GridDetalhesPositivacao visibleStatus={statusPositivacao} selectedDate={selectedDate} selectedRepres={Number(represId)}
                            />
                        )}
                        {showDetailsCurvaABC && (
                            <Component.Custom.GridDetalhesCurvaABC visibleStatus={statusCurvaABC} selectedDate={selectedDate} selectedRepres={Number(represId)}
                            />
                        )}
        </>
    );
}

export default HomeContent;