import React from 'react';
import './index.css';
import Component from '../../../components';



type HeaderAdminComponent = {

  title: string

}

const HeaderAdminComponent = (props: HeaderAdminComponent) => {
  return (
    <div className="header">
      <Component.Shared.TextComponent value={props.title} size={22} color='black' margin={0} align='flex-start' />
    </div>
  );
}

export default HeaderAdminComponent;