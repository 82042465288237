import React from "react";

interface TextProps {
  color: string,
  size: number,
  value: string | undefined;
  margin: number,
  marginTop?: number;
  className?: string;
  align: 'center' | 'flex-end' | 'flex-start' | 'top',
  fontWeight?: number;
}

const TextComponent: React.FC<TextProps> = ({
  color,
  size,
  value,
  margin,
  marginTop,
  align,
  className,
  fontWeight
}) => {
  return (
    <p
      style={{
        color: color,
        fontSize: size,
        margin: margin ? margin : 0,
        marginTop: marginTop ? marginTop : 0,
        alignSelf:  align ? align : 'center',
        fontWeight:fontWeight ?fontWeight : "600",

      }}
      className={className? className :''}
    >
      {value}
    </p>
  );
}

export default TextComponent;