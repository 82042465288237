import { useEffect, useState } from "react";
import PaginationComponent from "../../../pagination/pagination.components";
import api from "../../../../../services/api";
import Component from "../../../../components";
import { format } from 'date-fns';
type Type =
    {
        clienteId: number;
        nome: string;
        cnpJ_CPF: string;
        total: number;
    }

const RankingClientes: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [list, setList] = useState<Type[]>([])

    const [loading, setIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    const pesqMarca = () => {

    };

    const cancelSearch = () => {

    };


    useEffect(() => {

        gerarRelatorio();
    }, []);

    const gerarRelatorio = async () => {

        await api.get('/relatorios/ranking_clientes'
        ).then(response => {
            setList(response.data)
        }).catch(error => {
        })

    };

    const [perA, setPerA] = useState<Date | null>(null);

    const handleDateChangeA = (date: Date | null) => {
        setPerA(date);
        // Aqui você pode fazer o que quiser com a data selecionada
    };

    const [perB, setPerB] = useState<Date | null>(null);



    function aplicarMascaraCpfCnpj(valor: string) {
        // Remove caracteres não numéricos
        const apenasNumeros = valor.replace(/\D/g, '');

        // Verifica se é CPF ou CNPJ e aplica a máscara correspondente
        if (apenasNumeros.length <= 11) { // CPF
            return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else { // CNPJ
            return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
    }


    const handleDateChangeB = async (date: Date | null) => {

        setIsLoading(true);
        setPerB(date);

    
        const formattedPerA = perA ?format(perA, 'dd/MM/yyyy') : ''; 
        const formattedPerB = date ?format(date, 'dd/MM/yyyy') : ''; 




        await api.get(`relatorios/ranking_clientes?perA=${formattedPerA}&perB=${formattedPerB}`
        ).then(response => {
            setList(response.data);
            setIsLoading(false);

        }).catch(error => {
            setIsLoading(false);

        })
    };

    return (
        <div>
             <Component.Shared.TextComponent marginTop={0} value="Período" size={18} color='black' margin={0} align='center' />

            <div className="divisor-container">
                <div className="divisor1">
                    <Component.Shared.DatePicker  texto="Data Inicial" onDateChange={handleDateChangeA} />
                </div>
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Data Final" onDateChange={handleDateChangeB} />
                </div>
            </div>
            <div>
                <input
                    type="text"
                    placeholder="Filtrar por CNPJ/CPF ou nome do cliente"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="grid-container">
                {loading && (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent overlay />
                    </div>
                )}
                <table>
                    <thead>
                        <tr>
                            <th>Ranking</th>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>CNPJ/CPF</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && (
                            list

                                .filter(cli => {
                                    if (!searchTerm) return true;
                                    return cli.cnpJ_CPF.includes(searchTerm) || cli.nome.includes(searchTerm)
                                })
                                .slice(indexOfFirstProduct, indexOfLastProduct).map((product, index) => (
                                    <tr key={product.clienteId}>
                                         <td style={{color: '#ee4d2d', fontWeight: 'bold'}}>{indexOfFirstProduct + index + 1}</td>
                                        <td>{product.clienteId}</td>
                                        <td>{product.nome}</td>
                                        <td>{aplicarMascaraCpfCnpj(product.cnpJ_CPF)}</td>
                                        <td>R$ {product.total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>

                                    </tr>
                                )))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={list.length}
                setCurrentPage={handlePageChange}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
        </div>
    );
}

export default RankingClientes;