import React from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';


import Component from "../../components/components"
import { useLocation } from 'react-router-dom';

const OrderDetail: React.FC = () => {
   
    const location = useLocation();
    const order = location.state;
  return (
    <div>
      {/* Renderizar o componente Header */}
      <Header />
      <Component.Custom.OrderDetail  order={order} />
      <Footer />
    </div>
  );
};

export default OrderDetail;