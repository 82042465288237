import { ReactNode, createContext, useState } from "react";
import { Product } from "../types/product.type";
import api from "../services/api";


interface CartContextProps {
    cart: Product[];
    addProduct: (product: Product) => void;
    updateProductQtde: (productId: number, quantity: number) => void; 
    updateProductQtdeDesc: (productId: number, quantity: number, desc:number) => void; 
    removeProduct: (productId: number) => void;
    get: () => void;
    clearCart: () =>void;
  }
  

  export const CartContext = createContext<CartContextProps>({
    cart: [],
    addProduct: async () => {},
    updateProductQtde: async () => {},
    updateProductQtdeDesc: async () => {},
    removeProduct: () => {},
    get: async () => {},
    clearCart: async () => {},
  })


  
export const CartProvider = ({ children }: { children: ReactNode }) => {
    const [cart, setProducts] = useState<Product[]>([
        // Seus produtos iniciais
    ]);

    const updateProductQtde = (productId: number, quantity: number) => {
      setProducts(cart.map(product => {
          if (product.id === productId) {
              return { ...product, qtde: quantity };
          }
          return product;
      }));
  };

  const updateProductQtdeDesc = (productId: number, quantity: number, desc: number) => {
    setProducts(cart.map(product => {
        if (product.id === productId) {
            return { ...product, qtde: quantity , desc: desc, preco: product.desc === 0 && desc > 0 ? product.preco * (1 - (Number(desc) / 100)) : product.preco };
        }
        return product;
    }));
};

    const get = async () => {
      await api
        .get('/produtos')
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.error('error', error.response.data.message)
        
        })
    }
  
    const addProduct = (product: Product) => {
        setProducts([...cart, product]);
    };

    const removeProduct = (productId: number) => {
        setProducts(cart.filter(product => product.id !== productId));
    };

    const clearCart = () => {
      setProducts([]); // Ou qualquer outro método que você use para manter o estado do carrinho.
  };
    return (
    <CartContext.Provider value={{ cart, addProduct, updateProductQtde, updateProductQtdeDesc, removeProduct, get,clearCart }}>
    { children }
    </CartContext.Provider>
    );
  };
