import { Navigate } from 'react-router-dom';

import Cookies from 'js-cookie';

export const ProtectedRoute = ({ element, ...rest }: { element: JSX.Element }) => {
  
  const token = Cookies.get('AccessToken')
   
  // Se não estiver autenticado, redirecione para a página de login.
  if (!token) {
    return <Navigate to="/login" />;
  }

  return element;  // Renderize o componente passado se estiver autenticado.
};

export default ProtectedRoute;