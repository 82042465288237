import './index.css'

import React, { useState, useContext, useEffect } from 'react';
import './index.css';
import Product from '../../../types/product.type';
import Component from '../../components';
import { Link } from 'react-router-dom';

import { ProductContext } from '../../../context/product.provider';
import { CartContext } from '../../../context/cart.provider';
import noimage from '../../../images/noimg.jpg';
import { BASE_URL } from "../../../services/api";
import { AuthContext } from '../../../context/auth.provider';
import { useTheme } from '../../../context/theme.context';
import { RepresentadaContext } from '../../../context/representada.provider';


interface ScrollProps
{
    title: string;
}


const ScrollComponent : React.FC<ScrollProps> = ({ title }) => {
    const [loading, setIsLoading] = useState(true);
    const {selectedRepresentada} = useContext(RepresentadaContext);
    const { getProductsLancamento } = useContext(ProductContext);
    const { cart, addProduct } = useContext(CartContext);
    const [message, setMessage] = useState<string | null>(null);
    const [products, setProducts] = useState<Product[]>([]);
    const { user } = useContext(AuthContext);
    const { currentTheme } = useTheme();
    useEffect(() => {

        (async () => {

            if (user && user.sub && selectedRepresentada) {
                //Verificar uma categoria ou de recentes adicionados
                const fetchedProducts = await getProductsLancamento(user.sub, selectedRepresentada.id);
                setProducts(fetchedProducts);
            }

            setIsLoading(false);

        })();
    }, [user, selectedRepresentada]); // Adicionado props.category.categoriaId como dependência


    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [quantities, setQuantities] = useState<{ [key: number]: number }>({});
    const handleProductClick = (product: Product) => {
        setSelectedProduct(product);
    };



    const handleBlur = (productId: number) => {
        setQuantities(prevQuantities => {
          if (!prevQuantities[productId]) {
            return {
              ...prevQuantities,
              [productId]: 1,
            };
          }
          return prevQuantities;
        });
      };
  
      
    const handleQuantityChange = (productId: number, quantity: number) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: quantity,
        }));
    };
    const handleBuyClick = (product: Product) => {

        const productQuantity = quantities[product.id] || 1;

        // Criar uma cópia do produto e adicionar a propriedade quantity
        const productWithQuantity = {
            ...product,
            qtde: productQuantity
        };

        if (cart.some(item => item.id === product.id)) {
            setMessage('Produtos já estão no carrinho.');

        } else {
            if (product.saldo < productQuantity) {
                handleQuantityChange(product.id, Number(product.saldo))
                setMessage('Saldo insuficiente para esse produto.');
            }
            else {
                addProduct(productWithQuantity);
                setMessage('Produtos adicionados com sucesso!');
            }

        }

        setQuantities(prevQuantities => {
            if (!prevQuantities[product.id]) {
              return {
                ...prevQuantities,
                [product.id]: 1,
              };
            }
            return prevQuantities;
          });
        setTimeout(() => setMessage(null), 1500);
    };

    return (
        <><div>  <h4 className="divs-list-top-bordered-scroll">
           {title}
        </h4></div><div className="horizontal-scroll-wrapper">
                {message && <div className="success-message">{message}</div>}
                {loading ? (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent />
                    </div>
                ) :

                    products.slice(0, 10).map((product, index) => (
                        <article className="product-scroll" key={index}>
                            <Link to={`/produtos/detalhes/${product.id}`}
                                state={{ value: product }}
                                style={{ color: currentTheme.color.fontColorsTitle }}>
                                <div className="teste2-click" onClick={() => handleProductClick(product)}>
                                    <Component.Shared.ImageProductComponent className="item-image-scroll" source={product.imagens.length > 0 ? `${BASE_URL}uploads/${product.imagens[0].imagem}` : noimage} />
                                    <div className="item-name-product-scroll">
                                        <Component.Shared.TextComponent margin={0} className='textodescricao' value={product.nome} color="#444444" fontWeight={400} size={16} align="center" />
                                        <Component.Shared.TextComponent margin={5} value={'R$ ' + product.preco.toFixed(2)} color="#646464" size={22} align="center" />
                                    </div>
                                </div>
                            </Link>
                            <div className="input-button-container-scroll">
                                <input
                                    type="number"
                                    value={quantities[product.id] || ''}
                                    onBlur={() => handleBlur(product.id)}
                                    className='input-cart teste2'
                                    onChange={e => handleQuantityChange(product.id, Number(e.target.value))}
                                />

                                {
                                    product.variacao === true ? (

                                        <Link to={`/produtos/detalhes/${product.id}`}
                                            state={{ value: product }}
                                            style={{ color: currentTheme.color.fontColorsTitle }}>

                                            <button
                                                id={`buy-button-${index}`}
                                                type="submit"
                                                className="btn btn-success teste2"
                                                onClick={() => handleBuyClick(product)}
                                            >

                                                <i className="fa fa-cart-arrow-down"></i> ESCOLHER
                                            </button>
                                        </Link>

                                    ) :

                                        (

                                            <button
                                                id={`buy-button-${index}`}
                                                type="submit"
                                                className="btn btn-success teste2"
                                                onClick={() => handleBuyClick(product)}
                                            >

                                                <i className="fa fa-cart-arrow-down"></i> COMPRAR
                                            </button>
                                        )
                                }




                            </div>
                        </article>

                    ))}

            </div></>
    );
};

export default ScrollComponent;