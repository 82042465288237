import { z } from "zod";

const documentoSchema = z.object({
  id: z
    .string().min(2, 'Obrigatório')
    .refine((id) => id.length === 2, {
      message: 'O ID deve conter exatamente dois dígitos.',
    }),
  descricao: z.string().min(1, 'Obrigatório'),
});
export { documentoSchema };
