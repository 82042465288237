// theme.ts
export interface ThemeType {
    color: {
      primary: string;
      secondary: string;
      fontColors: string;
      fontColorsTitle: string;
    };
    border: {
      radius: number;
      style: string;
    };
    font: {
      small: string;
      default: string;
      big: string;
      bigger: string;
    };
  }
  
  const theme: ThemeType = {
    color: {
        primary: 'black',
        secondary: '#FEFEFE',
        fontColors: 'antiquewhite',
        fontColorsTitle: 'black'
    },
    border: {
        radius: 5,
        style: "2px solid #736681",
    },
    font: {
        small: "0.5rem",
        default: "1rem",
        big: "2rem",
        bigger: "3rem",
    },
  };
  
  export default theme;