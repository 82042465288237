import React from 'react';
import './index.css';

type ContentProps = {
  children: React.ReactNode;

}

const ContentAdminComponent: React.FC<ContentProps> = ({ children }) => {
  return (
    <div className="content">
      {children}
    </div>
  );
}

export default ContentAdminComponent;