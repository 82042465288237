// ThemeContext.tsx
import React, { createContext, useState, useContext } from 'react';
import theme, { ThemeType } from '../types/theme.type'; // Ajuste o caminho conforme necessário

interface ThemeContextType {
  currentTheme: ThemeType;
  setCurrentTheme: React.Dispatch<React.SetStateAction<ThemeType>>;
}

const ThemeContext = createContext<ThemeContextType>({
  currentTheme: theme,
  setCurrentTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeType>(theme);

  return (
    <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};