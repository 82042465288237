import React from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';


import Component from "../../components/components"
import { useParams,useLocation } from 'react-router-dom';

const ProductDetail: React.FC = () => {
    const { productId } = useParams();
    const location = useLocation();
    const value = location.state?.value;

  return (
    <div>
      {/* Renderizar o componente Header */}
      <Header />
      <Component.Custom.ProductDetail  product={value} />
      <Footer />
    </div>
  );
};

export default ProductDetail;