import React, { useRef, useEffect, useState } from 'react';
import { ptBR } from 'date-fns/locale';
import api from '../../../../../services/api';
import { format, eachDayOfInterval, startOfMonth, endOfMonth } from 'date-fns';
import Component from '../../../../components';

type Type =
    {
      id: number;
       nome: string;
       data_ultima_compra : string;
       ultimo_pedido : number;
       valor_ultimo_pedido : number;
       dias_ultima_compra: number;
       ciclo_medio_compra: number;
       status_cliente: string;
    }


    
interface Props {
  visibleStatus: string[]; // Define a função que recebe um array de strings
  selectedRepres: number;
}


const GridDetalhesCarteira: React.FC<Props> = ({visibleStatus, selectedRepres}) => {
    const [list, setList] = useState<Type[]>([]);
 
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

  useEffect(() => {
    const fetchData = async () => {
      
      setLoading(true); // Ativa o estado de carregamento
      try {
        const dadosFiltrados = visibleStatus.filter(item => item.trim() !== '');

        const response = await api.post<Type[]>(`/relatorios/detalhar_clientes_mensal?representanteid=${selectedRepres}`, dadosFiltrados.length > 0? visibleStatus : []);
        const sortedList = response.data.sort((a, b) => {
          // Tratando valores nulos para que venham por último
          if (a.dias_ultima_compra === null) return 1;
          if (b.dias_ultima_compra === null) return -1;
      
          // Ordenação decrescente
          return b.dias_ultima_compra - a.dias_ultima_compra;
      });
      setList(sortedList);
      } catch (error) {
        console.error('Error fetching data', error);
      }
      finally {
        setLoading(false); // Desativa o estado de carregamento
      }
    };

    fetchData();
  }, [selectedRepres]);



  return <><div>
    {loading && (
      <div className="product-container">
        <Component.Shared.LoaderComponent overlay />
      </div>
    )}
    <div className="grid-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Último pedido</th>
                                            <th>Data último pedido</th>
                                            <th>Dias sem comprar</th>
                                            <th>Ciclo médido de compra</th>
                                            <th>Situação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && (
                                            list.map((product, index) => (
                                                <tr key={product.id}>
                                                    <td>{product.nome}</td>
                                                    <td>{product.ultimo_pedido}</td>
                                                    <td>{product.data_ultima_compra}</td>
                                                    <td>{product.dias_ultima_compra}</td>
                                                    <td>{product.ciclo_medio_compra}</td>
                                                    <td>{product.status_cliente}</td>
                                                </tr>
                                            )))}
                                    </tbody>
                                </table>
                            </div>

  </div></>;
};

export default GridDetalhesCarteira;