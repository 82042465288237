import { z } from 'zod';

const ProductSchema = z.object({
  id: z.string().optional(),
  custo: z.string().min(1, 'Obrigatório.'),
  margemBruta: z.string().min(1, 'Obrigatório.'),
  marcaid: z.string().min(1, 'Obrigatório.'),
  subcategoriaid: z.string(),
  nome: z.string().min(1, 'Obrigatório.'),
  descricao: z.string().min(1, 'Obrigatório.'),
  preco: z.string().optional(),
  saldo: z.string().min(1, 'Obrigatório.'),
  Imagens: z.string().array().optional(),
})
  .refine(data => {
    return /^\d+(\.\d{1,2})?$|^$/.test(data.saldo);
  }, {
    message: 'Este campo deve ser um número válido',
    path: ['saldo']
  })
  .refine(data => {
    return /^\d+(\.\d{1,2})?$|^$/.test(data.custo);
  }, {
    message: 'Este campo deve ser um número válido',
    path: ['custo']
  })
  .refine(data => {
    return /^\d+(\.\d{1,2})?$|^$/.test(data.margemBruta);
  }, {
    message: 'Este campo deve ser um número válido',
    path: ['margemBruta']
  })
export {ProductSchema};

