import { useEffect, useState } from "react";
import PaginationComponent from "../../../pagination/pagination.components";
import api from "../../../../../services/api";
import Component from "../../../../components";
import { format } from 'date-fns';
type ClientesSemCompraType =
    {
        id: number;
        nome: string;
        cnpj_cpf: string;
        dias: number;
        data_ultimo_pedido: string;
        valor: number;
    }
const ClientesSemCompra: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [list, setList] = useState<ClientesSemCompraType[]>([])
    const [loading, setIsLoading] = useState(false);



    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    const [perA, setPerA] = useState<Date | null>(null);

    const handleDateChangeA = (date: Date | null) => {
        setPerA(date);
        // Aqui você pode fazer o que quiser com a data selecionada
    };

    const [perB, setPerB] = useState<Date | null>(null);


    

    const handleDateChangeB = async (date: Date | null) => {

        setIsLoading(true);
        setPerB(date);

    
        const formattedPerA = perA ?format(perA, 'dd/MM/yyyy') : ''; 
        const formattedPerB = date ?format(date, 'dd/MM/yyyy') : ''; 



        setIsLoading(true);
        await api.get(`/relatorios/clientes_sem_compra?perA=${formattedPerA}&perB=${formattedPerB}`
        ).then(response => {
            setIsLoading(false);
            setList(response.data)
        }).catch(error => {
            setIsLoading(false);
        })
    };


    return (
        <div>
            <Component.Shared.TextComponent marginTop={0} value="Período" size={18} color='black' margin={0} align='center' />

            <div className="divisor-container">
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Data Inicial" onDateChange={handleDateChangeA} />
                </div>
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Data Final" onDateChange={handleDateChangeB} />
                </div>
            </div>
            <div className="divisor-container">
                <div className="divisor1">
                    <input
                        type="text"
                        placeholder="Filtrar por nome"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

            </div>
            <div className="grid-container">
                {loading && (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent overlay />
                    </div>
                )}
                <table>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>CNPJ/CPF</th>
                            <th>Dias</th>
                            <th>Data Últ.P</th>
                            <th>Total Últ.P</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && (
                            list

                                .filter(cli => {
                                    // Se searchTerm for vazio, inclua todos os itens.
                                    if (!searchTerm) return true;
                                    return cli.nome.toLowerCase().includes(searchTerm.toLowerCase());
                                })

                                .slice(indexOfFirstProduct, indexOfLastProduct).map((product) => (
                                    <tr key={product.id}>
                                        <td>{product.id}</td>
                                        <td>{product.nome}</td>
                                        <td>{product.cnpj_cpf}</td>
                                        <td>{product.dias}</td>
                                        <td>{product.data_ultimo_pedido}</td>
                                        <td>R$ {product.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                )))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={list.length}
                setCurrentPage={handlePageChange}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
        </div>
    );
}

export default ClientesSemCompra;