import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import api from '../../services/api';


export const AdminProtectedRoute = ({ element, ...rest }: { element: JSX.Element }) => {
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);
  const token = Cookies.get('AccessToken');

  useEffect(() => {
    if (token) {
      api.post('/empresas/acess-painel-administrativo', {}, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        if (response.status === 200) {
          setHasAccess(true);
        } else {
          setHasAccess(false);
        }
      })
      .catch(error => {
        setHasAccess(false);
      });
    }
  }, [token]);

  if (token && hasAccess === null) {
    return <div></div>; // ou algum componente de carregamento
  }

  if (!token || hasAccess === false) {
    return <Navigate to="/home" />;
  }

  return element;
};