
export interface State {
  descricao: string;
    id: string;
   
  }
  
  const states: State[] = [
    {
      descricao: 'Acre', id: 'AC'
     },
     {
      descricao: 'Alagoas', id: 'AL'
     },
     {
      descricao: 'Amapá', id: 'AP'
     },
     {
      descricao: 'Amazonas', id: 'AM'
     },
     {
      descricao: 'Bahia', id: 'BA'
     },
     {
      descricao: 'Ceará', id: 'CE'
     },
     {
      descricao: 'Distrito Federal', id: 'DF'
     },
     {
      descricao: 'Espírito Santo', id: 'ES'
     },
     {
      descricao: 'Goiás', id: 'GO'
     },
    {
     descricao: 'Maranhão', id: 'MA'
    },
    {
      descricao: 'Mato Grosso', id: 'MT'
     },
     {
      descricao: 'Mato Grosso do Sul', id: 'MS'
     },
     {
      descricao: 'Minas Gerais', id: 'MG'
     },
     {
      descricao: 'Pará', id: 'PA'
     },
     {
      descricao: 'Paraíba', id: 'PB'
     },
     {
      descricao: 'Paraná', id: 'PR'
     },
     {
      descricao: 'Pernambuco', id: 'PE'
     },
     {
      descricao: 'Piauí', id: 'PI'
     },
    {
      descricao: 'Rio de Janeiro', id: 'RJ'
     },
     {
      descricao: 'Rio Grande do Norte', id: 'RN'
     },
     {
      descricao: 'Rio Grande do Sul', id: 'RS'
     },
     {
      descricao: 'Rondônia', id: 'RO'
     },
     {
      descricao: 'Roraima', id: 'RR'
     },
     {
      descricao: 'Santa Catarina', id: 'SC'
     },
     {
      descricao: 'São Paulo', id: 'SP'
     },
     {
      descricao: 'Sergipe', id: 'SE'
     },
     {
      descricao: 'Tocantins', id: 'TO'
     },
 
  ];
  
  export default states;