import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import "./index.css";
import { CheckoutRepresInputProps, checkoutRepresSchema } from '../../../schemas/user.schemas';
import { useContext, useEffect, useState } from 'react';
import Component from '../../../components';

import Representada from '../../../../types/representada.type';
import api, { BASE_URL } from '../../../../services/api';
import Client from '../../../../types/client.type';
import ProdutoType from '../../../../types/productcard.type';
import { FaCheck, FaEdit, FaSyncAlt, FaTrash } from 'react-icons/fa';
import { CartContext } from '../../../../context/cart.provider';
import Product from '../../../../types/product.type';
import noimage from '../../../../images/noimg.jpg';
import Swal from 'sweetalert2';
import CondPagto from '../../../../types/condpagto.type';
import Docto from '../../../../types/docto.type';
import Endereco from '../../../../types/endereco.type';
import TipoPed from '../../../../types/tipoPed.type';


const AddPedidos: React.FC = () => {

    const [loading, setLoading] = useState(false);
    const [idUf, setUf] = useState('');
    const [clientes, setClientes] = useState<Client[]>([]);
    const [listProducts, setListProducts] = useState<Product[]>([])
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [searchTermProduct, setSearchTermProduct] = useState<string>("");
    const [condpagtos, setCondPagtos] = useState<CondPagto[]>([]);
    const [doctos, setDoctos] = useState<Docto[]>([]);
    const [enderecos, setEnderecos] = useState<Endereco[]>([]);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [error, setError] = useState('');

    const [representadaId, setRepresentadaId] = useState('');
    const { cart, removeProduct, get, addProduct, clearCart, updateProductQtdeDesc } = useContext(CartContext);
    const [message, setMessage] = useState<string | null>(null);

    const [selectedCliente, setSelectedCliente] = useState<null | Client>(null);
    const [selectedProduto, setSelectedProduto] = useState<null | ProdutoType>(null);
    const [representadas, setRepresentadas] = useState<Representada[]>([]);
    const [tipoPeds, setTipoPeds] = useState<TipoPed[]>([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<CheckoutRepresInputProps>({ resolver: zodResolver(checkoutRepresSchema) })


    useEffect(() => {
        setValue('itempedidos', [...cart]);
    }, [cart, setValue]);

    useEffect(() => {
        // Reseta o erro ao montar a tela novamente
        clearCart();
        listarrepresentadas();
        listarClientes();
        listarTipoPeds();
        setValue("clienteid", "");
        setValue("itempedidos", []);

    }, []);

    function removeDiacritics(text: string): string {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }



    const handleRepresentadaChange = (value: string) => {
        setRepresentadaId(value);
        listarProdutos(Number(value));
    };



    // Filtra clientes com base no termo de busca
    const filteredClientes = clientes.filter(cliente =>
        cliente.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cliente.cnpj_cpf.includes(searchTerm)
    );

    const filteredProdutos = showAllProducts || !searchTermProduct
        ? listProducts
        : listProducts.filter(produto =>
            removeDiacritics(produto.nome.toLowerCase()).includes(removeDiacritics(searchTermProduct.toLowerCase()))
            ||
            produto.id.toString().includes(searchTermProduct)
        );

    const listarProdutos = async (representadaId: number) => {
        setLoading(true); // Inicia o indicador de carregamento

        if (selectedCliente) {

            // Constrói a URL baseada na condição do representadaId
            let url = '/produtos/listarbyclienteidrepresentadaid';
            url += `?clienteid=${selectedCliente.id}&&representadaid=${representadaId}&&tipo=${selectedCliente.tipo}`;

            // Faz a chamada à API usando a URL construída
            await api.get(url)
                .then(response => {
                    setListProducts(response.data); // Atualiza os produtos com a resposta da API
                    setLoading(false); // Finaliza o indicador de carregamento
                })
                .catch(error => {
                    console.error("Erro ao gerar relatório:", error); // Loga qualquer erro que ocorrer na requisição
                    setLoading(false); // Finaliza o indicador de carregamento em caso de erro
                });
        }

    };


    const limpartudo = () => {
        handleClearClient();
        setSelectedCliente(null);
        setSelectedProduto(null);
        setSearchTerm('');
        setSearchTermProduct('');
        setValue("observacao", '');
        setValue("condpagtoid", '');
        setValue("doctoid", '');
        setValue("tipoped", '');
        setShowAllProducts(false);
        setCondPagtos([]);
        setDoctos([]);

    };

    const listarrepresentadas = async () => {

        await api.post('/Fornecedores/buscarrepresentadas'
        ).then(response => {
            setRepresentadas(response.data)
        }).catch(error => {
        })

    };

    const listarClientes = async () => {

        await api.get('/relatorios/clientes'
        ).then(response => {
            setClientes(response.data)
        }).catch(error => {
        })

    };

    const listarTipoPeds = async () => {

        await api.get('/tipoped'
        ).then(response => {
            setTipoPeds(response.data)
        }).catch(error => {
        })

    };

    const listCondPagto = async (clienteid: number, tipo: string) => {
        setLoading(true);
        await api.get('condpagtos/listarbyclienteid?clienteid=' + clienteid + '&&tipo=' + tipo
        ).then(response => {
            setCondPagtos(response.data);
            setLoading(false);
        })

    };

    const listDocto = async (clienteid: number, condpagtoid: string, tipo: string) => {
        setLoading(true);
        await api.get('doctos/listarbycondpagtoidclienteid?condpagtoid=' + condpagtoid + '&&clienteid=' + clienteid + '&&tipo=' + tipo
        ).then(response => {
            setDoctos(response.data);
            setLoading(false);

        })

    };

    const listEndereco = async (clienteid: number, tipo: string) => {
        setLoading(true);
        await api.post('/enderecoentrega/listarenderecosbyclienteid?clienteid=' + clienteid + '&&tipo=' + tipo
        ).then(response => {
            setEnderecos(response.data);
            setLoading(false);
        })

    };

    const handleSelectCliente = (cliente: Client) => {
        setSelectedCliente(cliente);
        setSearchTerm(""); // Opcional: Limpa o termo de busca
        // Mais lógica conforme necessário
        listCondPagto(cliente.id, cliente.tipo);
        listEndereco(cliente.id, cliente.tipo);

        setValue('clienteid', cliente.id.toString());
    };


    const handleSelectChange = (condpagtoid: string) => {
        if (selectedCliente) {
            listDocto(selectedCliente.id, condpagtoid, selectedCliente.tipo)
        }
    };


    const handleSelectProduto = (prod: Product) => {
        //setSelectedProduto(null);
        //ATUALIZACAO PARA NAO SUMIR OS PRODUTOS LISTAGEM
      //  setSearchTermProduct(""); // Opcional: Limpa o termo de busca

        //tela para informar quantidade e versão nova descontos
        //addProduct(prod);

        if (cart.some(item => item.id === prod.id)) {
            setMessage('Produto já foi adicionado no pedido.');
            setTimeout(() => setMessage(null), 1500);
        }
        else {

            Swal.fire({
                title: 'Detalhes do Produto',
                html: `
                <div style="text-align: left;">
                <p><strong>Código:</strong> ${prod.id}</p>
                <p><strong>Descrição:</strong> ${prod.nome}</p>
                <p><strong>Preço Tabela:</strong> R$${prod.precoTab.toFixed(2)}</p>
                <p><strong>Preço Líquido:</strong> R$${prod.preco.toFixed(2)}</p>
                <div style="display: flex; align-items: center;">
                <div>
                <label for="swal-input-quantity" style="margin-right: 10px;"><strong>Quantidade:</strong></label>
                <input id="swal-input-quantity" type="number" value="" min="1" style="width: 80px; text-align: right;">
                </div>

                <div style="margin-left: 10px;">
                <label for="swal-input-desc" style="margin-right: 10px;"><strong>Desconto:</strong></label>
                <input id="swal-input-desc" type="number" value="${prod.desc.toFixed(2)}" min="0" max="100" style="width: 80px; text-align: right;" ${prod.acres || prod.desc ? 'disabled' : ''} >
                </div>
                </div>
                </div>
            `,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Adicionar',
                focusConfirm: false,
                preConfirm: () => {

                    const quantityInput = document.getElementById('swal-input-quantity') as HTMLInputElement; // Assegura que é um HTMLInputElement
                    const quantity = quantityInput && Number(quantityInput.value) > 0 ? quantityInput.value : '1';

                    //  const acrescimoInput = document.getElementById('swal-input-acres') as HTMLInputElement;
                    //  const acrescimo = acrescimoInput && Number(acrescimoInput.value) > 0 ? acrescimoInput.value : '0';

                    const descontoInput = document.getElementById('swal-input-desc') as HTMLInputElement;
                    const desconto = descontoInput && Number(descontoInput.value) > 0 ? descontoInput.value : '0';

                    if (prod.saldo < Number(quantity)) {
                        Swal.fire({
                            title: 'Mensagem',
                            text: "Saldo insuficiente do produto.",
                            icon: 'warning',
                        });
                        return;
                    }

                    const productWithQuantity = {
                        ...prod,
                        qtde: Number(quantity),
                        // acres: Number(acrescimo),
                        desc: Number(desconto),
                        preco: prod.desc === 0 && Number(desconto) > 0 ? prod.preco * (1 - (Number(desconto) / 100)) : prod.preco
                    };
                    console.log(productWithQuantity);
                    addProduct(productWithQuantity);
                    return { productWithQuantity };
                }
            }).then((result) => {
                if (result.isConfirmed && result.value) {
                     //ATUALIZACAO PARA NAO SUMIR OS PRODUTOS LISTAGEM
                  //  setShowAllProducts(false);
                    // Aqui você pode adicionar a lógica para adicionar o produto ao carrinho com a quantidade atualizada
                }
            });
        }
    };

    const alterarItem = (prod: Product) => {

        Swal.fire({
            title: 'Detalhes do Produto',
            html: `
              <div style="text-align: left;">
                <p><strong>Código:</strong> ${prod.id}</p>
                <p><strong>Descrição:</strong> ${prod.nome}</p>
                <p><strong>Preço Tabela:</strong> R$${prod.precoTab.toFixed(2)}</p>
                <p><strong>Preço Líquido:</strong> R$${prod.preco.toFixed(2)}</p>
                <div style="display: flex; align-items: center;">
                <div>
                <label for="swal-input-quantity" style="margin-right: 10px;"><strong>Quantidade:</strong></label>
                <input id="swal-input-quantity" type="number" value="${prod.qtde}" min="1" style="width: 80px; text-align: right;">
                </div>

                <div style="margin-left: 10px;">
                <label for="swal-input-desc" style="margin-right: 10px;"><strong>Desconto:</strong></label>
                <input id="swal-input-desc" type="number" value="${prod.desc}" min="0" max="100"  style="width: 80px; text-align: right;" ${prod.acres || prod.desc ? 'disabled' : ''}  >
                </div>
                </div>
                </div>
            `,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Alterar',
            focusConfirm: false,
            preConfirm: () => {
                const quantityInput = document.getElementById('swal-input-quantity') as HTMLInputElement; // Assegura que é um HTMLInputElement
                const quantity = quantityInput && Number(quantityInput.value) > 0 ? quantityInput.value : '1';

                if (prod.saldo < Number(quantity)) {
                    Swal.fire({
                        title: 'Mensagem',
                        text: "Saldo insuficiente do produto.",
                        icon: 'warning',
                    });
                    return;
                }

                const descontoInput = document.getElementById('swal-input-desc') as HTMLInputElement;
                const desconto = descontoInput && Number(descontoInput.value) > 0 ? descontoInput.value : '0';
                updateProductQtdeDesc(prod.id, Number(quantity), Number(desconto));
                return { ...prod, qtde: quantity, desc: desconto };
            }
        }).then((result) => {
            if (result.isConfirmed && result.value) {
                 //ATUALIZACAO PARA NAO SUMIR OS PRODUTOS LISTAGEM
              //  setShowAllProducts(false);
                // Aqui você pode adicionar a lógica para adicionar o produto ao carrinho com a quantidade atualizada
            }
        });


    }

    const handleConfirmarAlterarCliente = () => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: "Você deseja mesmo alterar o cliente? Se tiver itens no pedido, eles serão apagados.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',

            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
        }).then((result) => {
            if (result.isConfirmed) {
                handleClearClient();
            }
        });
    };

    const handleSendOrder = async (data: CheckoutRepresInputProps) => {

        if (selectedCliente) {

            await api.post('/pedidos/inserirpedidorepresentante', {
                tipo: selectedCliente.tipo,
                representadaid: representadaId,
                ...data
            }
            ).then(response => {
                Swal.fire({
                    title: 'Gerado com sucesso',
                    text: "Pedido " + response.data.id + " gerado com sucesso. Deseja gerar outro pedido ?",
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',

                    cancelButtonText: 'Não',
                    confirmButtonText: 'Sim',
                }).then((result) => {
                    if (result.isConfirmed) {
                        //atualizar a pagina
                        limpartudo();
                    }
                });

            }).catch(error => {
                setError(error.response.data);
                console.log(error.response.data);
            })
        }
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setSearchTermProduct(searchTerm);

        if (searchTerm.length > 0) {
            setShowAllProducts(false);
        }
    };

    const confirmarExclusao = (id: number) => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: "Você deseja mesmo remover este item do seu pedido?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',

            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
        }).then((result) => {
            if (result.isConfirmed) {
                removeProduct(id);
            }
        });
    };


    const handleClearClient = () => {
        setSelectedCliente(null);
        setSearchTerm('');
        clearCart();
        setValue("clienteid", "");
        setEnderecos([]);
        setValue("enderecoid", "");

    };
    const totalValue = cart.reduce((acc, product) => acc + product.qtde * product.preco, 0);

    const totalProd = cart.reduce((acc, product) => acc + product.qtde, 0);


    return (
        <div>
            {message && <div className="success-message">{message}</div>}
            {loading && (
                <div className="product-container">
                    <Component.Shared.LoaderComponent overlay />
                </div>
            )}
            <form className="" onSubmit={handleSubmit(handleSendOrder)}>

                <Component.Shared.TextComponent marginTop={20} value="Cliente" size={18} color='black' margin={0} align='center' />


                {
                    !selectedCliente && (
                        <>
                            <Component.Shared.TextInputComponent
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                                size={16}
                                margin={0}
                                marginTop={10}
                                color="black"
                                placeholder="Digite o nome ou CNPJ/CPF do cliente"
                                type="text"
                            />
                            {errors.clienteid && (
                                <Component.Shared.TextComponent
                                    value={errors.clienteid.message}
                                    size={12}
                                    color="red"
                                    margin={0}
                                    align="flex-start"
                                />
                            )}
                        </>
                    )
                }



                {searchTerm && !selectedCliente && (
                    <Component.Shared.ListaClienteCard
                        clientes={filteredClientes.slice(0, 10)}
                        onSelectCliente={handleSelectCliente} // Você precisa implementar esse prop em ListaClienteCard para lidar com a seleção
                    />
                )}

                {selectedCliente && (
                    <div>
                        <Component.Shared.ClienteCard cliente={selectedCliente} />
                        <div className="btn btn-primary button" onClick={() => handleConfirmarAlterarCliente()} >
                            <FaSyncAlt size={18} />
                            <span> Alterar cliente</span>
                        </div>
                    </div>
                )}


                <Component.Shared.TextComponent marginTop={20} value="Representada" size={18} color='black' margin={0} align='center' />
                {selectedCliente && (
                    <div title={cart.length > 0 ? "Não é possível alterar a representada com itens no pedido" : ""}>
                        <Component.Shared.SelectComponent
                            onChange={handleRepresentadaChange}
                            margin={0}
                            marginTop={10}
                            align='flex-start'
                            data={representadas}
                            size={16}
                            color="black"
                            placeholder="* Representada"
                            disable={cart.length > 0}
                        // Desabilita o SelectComponent se houver itens no carrinho
                        />


                    </div>
                )}


                <Component.Shared.TextComponent marginTop={20} value="Tipo de Pedido" size={18} color='black' margin={0} align='center' />
                <Component.Shared.SelectComponent
                    margin={0}
                    marginTop={10}
                    align='flex-start'
                    data={tipoPeds}
                    size={16}
                    color="black"
                    placeholder=""
                    register={register('tipoped')}
                    disable={cart.length > 0} // Desabilita o SelectComponent se houver itens no carrinho
                />

                {errors.tipoped && (
                    <Component.Shared.TextComponent
                        value={errors.tipoped.message}
                        size={12}
                        color="red"
                        margin={0}
                        align="flex-start"
                    />
                )}

                <Component.Shared.TextComponent marginTop={20} value="Produtos" size={18} color='black' margin={0} align='center' />


                {!selectedProduto && representadaId && selectedCliente && (
                    <>
                        <Component.Shared.TextInputComponent onChange={handleSearchChange} value={searchTermProduct} size={16} margin={0} marginTop={10} color="black" placeholder="Digite o nome ou código de barras do produto " type="text" />
                        <small style={{ cursor: 'pointer', textDecoration: 'underline', color: 'grey' }} onClick={() => setShowAllProducts(true)}>Listar todos os produtos</small>

                        {errors.itempedidos ? (
                            <Component.Shared.TextComponent value={errors.itempedidos.message} size={12} color='red' margin={0} align='center' />
                        ) : null}
                    </>
                )}

                {(!selectedProduto && (showAllProducts || searchTermProduct)) && (
                    <Component.Shared.ListProductCard produtos={filteredProdutos} onSelectedProduto={handleSelectProduto} />
                )}


                {cart.length > 0 && (
                    <>
                        <div className='grid-container'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Foto</th>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        <th>Qtde.</th>
                                        <th>Preço Líq.</th>
                                        <th>Desc.</th>
                                        <th>Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cart.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <Component.Shared.ImageProductComponent className="item-image-grid" source={item.imagens.length > 0 ? `${BASE_URL}uploads/${item.imagens[0].imagem}` : noimage} />
                                            </td>
                                            <td>{item.id}</td>
                                            <td>{item.nome}</td>
                                            <td>{item.qtde}</td>
                                            <td>{`R$ ${item.preco.toFixed(2)}`}</td>
                                            <td >
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                    <span style={{ color: 'red' }}>
                                                        {`${item.desc.toFixed(2)}%`} &#x25BC; {/* Seta para baixo */}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                R${(item.qtde * item.preco).toFixed(2)}
                                                <FaEdit onClick={() => alterarItem(item)} style={{ marginLeft: '3px', cursor: 'pointer' }} title='Alterar produto' size={11} />
                                                <FaTrash onClick={() => confirmarExclusao(item.id)} style={{ marginLeft: '3px', cursor: 'pointer' }} size={11} title='Remover produto' />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="containertotals">
                            <div className="item">
                                <p className="label">Quantidade de itens</p>
                                <p>{totalProd}</p>
                            </div>
                            <div className="valor">
                                <p className="label">Valor total</p>
                                <p className='texto-valortotal'>R$ {totalValue.toFixed(2)}</p>
                            </div>
                        </div>
                    </>
                )}

                <Component.Shared.TextComponent marginTop={20} value="Condição de pagamento" size={18} color='black' margin={0} align='center' />

                <Component.Shared.SelectComponent onChange={handleSelectChange} margin={0} marginTop={10} align='flex-start' data={condpagtos} size={16} color="black" placeholder="" register={register('condpagtoid')} />

                {errors.condpagtoid ? (
                    <Component.Shared.TextComponent value={errors.condpagtoid.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                <Component.Shared.TextComponent marginTop={20} value="Documento" size={18} color='black' margin={0} align='center' />

                <Component.Shared.SelectComponent margin={0} marginTop={10} align='flex-start' data={doctos} size={16} color="black" placeholder="" register={register('doctoid')} />

                {errors.doctoid ? (
                    <Component.Shared.TextComponent value={errors.doctoid.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                <Component.Shared.TextComponent marginTop={20} value="Endereço de entrega" size={18} color='black' margin={0} align='center' />

                <Component.Shared.SelectComponent margin={0} marginTop={10} align='flex-start' data={enderecos} size={16} color="black" placeholder="" register={register('enderecoid')} />

                {errors.enderecoid ? (
                    <Component.Shared.TextComponent value={errors.enderecoid.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}


                <Component.Shared.TextComponent marginTop={20} value="Observação" size={18} color='black' margin={0} align='center' />
                <Component.Shared.TextInputComponent type='textarea' align='flex-start' placeholder='' size={15} maxLength={200} color='black' margin={0} register={register('observacao')} />

                {error ? (
                    <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                <button type="submit" className="btn btn-primary button">
                    <FaCheck size={18} />
                    <span> Gerar pedido</span>
                </button>
            </form>
        </div>
    );
}

export default AddPedidos;