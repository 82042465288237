import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import "./index.css";
import { useTheme } from "../../../context/theme.context";

const SearchBarComponent = () => {
  const [input, setInput] = useState("");
  const navigate = useNavigate()
  
  const {currentTheme} = useTheme();

  const handleSubmit = (event: any) => {
    event.preventDefault(); // Impede o recarregamento da página
    
    navigate('/search-results',  { state: input });
  };

  return (
    <form onSubmit={handleSubmit} className="input-wrapper">
      <input
        type="search"
        className="input-search"
        placeholder="Pesquisar em todo o site..."
        value={input}
        onChange={(e) => setInput(e.target.value)}  // Atualiza o estado conforme o usuário digita
      />
      <FaSearch
        id="search-icon"
        color={currentTheme.color.primary}
        size={25}
        onClick={handleSubmit}  // Trate o clique no ícone como uma submissão
        style={{ cursor: 'pointer' }}  // Mude o cursor para indicar que o ícone é clicável
      />
    </form>
  );
};

export default SearchBarComponent;