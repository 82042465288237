import React from 'react';
import ClienteCard from './clientecard.shared.component';
import Order from '../../types/order.type';
import PedidoCard from './pedidocard.shared.component';

interface ListCardProps {
  pedidos: Order[];
  onSelectPedido: (order: Order) => void; 
}
const ListaPedidoCard: React.FC<ListCardProps> = ({ pedidos, onSelectPedido }) => {
  return (
    <div>
      {pedidos.map(pedido => (
       <div onClick={() => onSelectPedido(pedido) } key={pedido.id}>
          <PedidoCard pedido={pedido} />
        </div>
      ))}
    </div>
  );
};

export default ListaPedidoCard;