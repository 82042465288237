import { useContext, useEffect, useState } from "react";
import Product, { Variacao } from "../../../types/product.type";
import './index.css'; // Importando o arquivo CSS
import { CartContext } from "../../../context/cart.provider";
import Component from "../../components";

type ProductDetailProps =
  {
    product: Product;
  }



export const ProductDetailComponent = (props: ProductDetailProps) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { cart, addProduct } = useContext(CartContext);
  const [qtde, setQtde] = useState<number | ''>(1);
  const [error, setError] = useState<string | null>(null);
  const [selectedQuantities, setSelectedQuantities] = useState<Variacao[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);  // Isso irá rolar a página para o topo assim que o componente for montado
  }, []);


  const handleBuyClick = (product: Product) => {
    // Criar uma cópia do produto e adicionar a propriedade quantity

    if (product.variacao === true) {
      const totalQuantity = selectedQuantities.reduce((total, item) => total + item.qtde, 0);
      // Verificar se a soma total é um múltiplo de props.qtde_minima
      if (totalQuantity % props.product.qtde_minima !== 0 && totalQuantity !== 0) { // totalQuantity não é múltiplo de props.qtde_minima
        // Você pode notificar o usuário ou tomar qualquer outra ação necessária
        setError(`A quantidade total selecionada deve ser um múltiplo de ${props.product.qtde_minima}`);
        setTimeout(() => setError(null), 1500);

        return; // supondo que você tenha um método setError para mostrar erros
      }

      if (totalQuantity === 0) {
        setError(`Obrigatório informar a quantidade`);
        setTimeout(() => setError(null), 1500);
        return;
      }

      const productWithQuantity = {
        ...product,
        qtde: totalQuantity,
        variacoes: selectedQuantities
      };

      if (cart.some(item => item.id === product.id)) {
        setError('Produtos já estão no carrinho.');
        setTimeout(() => setError(null), 1500);
      } else {

        if (product.saldo < Number(qtde)) {
          setQtde(product.saldo);
          setError('Saldo insuficiente para esse produto.');
          setTimeout(() => setError(null), 1500);
        }
        else {
          addProduct(productWithQuantity);
          setSuccessMessage('Produtos adicionados com sucesso.');
          setTimeout(() => setSuccessMessage(null), 1500);

        }

      }
    }
    else {

      const productWithQuantity = {
        ...product,
        qtde: Number(qtde)
      };

      if (cart.some(item => item.id === product.id)) {
        setError('Produtos já estão no carrinho.');
        setTimeout(() => setError(null), 1500);
      } else {

        if (product.saldo < Number(qtde)) {
          setQtde(product.saldo);
          setError('Saldo insuficiente para esse produto.');
          setTimeout(() => setError(null), 1500);
        }
        else {
          addProduct(productWithQuantity);
          setSuccessMessage('Produtos adicionados com sucesso.');
          setTimeout(() => setSuccessMessage(null), 1500);

        }


      }
    }
  };

  const handleBlur = () => {
    if (qtde === '') {
      setQtde(1);
    }
  };
  const handleQuantityChange = (value: string) => {
    if (value === '') {
      setQtde('');
    } else if (!isNaN(Number(value))) {
      setQtde(Number(value));
    }
  };

  const handleQuantityChangeVariation = (id: number, quantity: number, cod_variacao: string, imagem: string) => {
    const existingItem = selectedQuantities.find(item => item.id === id);

    if (existingItem) {
      // Se o item já existir, atualize a quantidade
      const updatedQuantities = selectedQuantities.map(item =>
        item.id === id ? { ...item, qtde: quantity } : item
      );
      setSelectedQuantities(updatedQuantities);
    } else {
      // Se o item não existir, adicione-o ao array
      const newItem = { id: id, qtde: quantity, cod_variacao: cod_variacao , imagem: imagem };
      setSelectedQuantities([...selectedQuantities, newItem]);
    }
  };
  return (
    <div>

      <div className='product-detail-content'>

        <div className='col-xs-12 col-sm-6 image-detail'>
          <div className='col-xs-12 gallery-product'>
            <Component.Custom.BannerCarrousel type="base" images={props.product.imagens} />
          </div>
        </div>
        <div className='col-xs-12 info-detail' >
          <h4 className='product-name'>{props.product.nome}</h4>
          <br />
          <p className="description-produto"  >
          <DescriptionWithBreaks description={props.product.descricao} />
          </p>
          <div className="payment">

            <div data-content-id="produto_detalhe_box_quantity_567" className="row">

              <div className="col-xs-6">
                <div className="security-seal">
                  <p className='product-price'>R$ {props.product.preco.toFixed(2)}</p>
                </div>
              </div>
              {props.product.saldo > 0 ?
                (<div className="col-xs-6 col-md-4 col-lg-3 payment-right">
                  {props.product.variacao === false && (
                    <input
                      id="quantity"
                      name="quantity"
                      className='input-quantity-detail'
                      value={qtde.toString()}
                      onBlur={() => handleBlur()}
                      onChange={e => handleQuantityChange(e.target.value)}

                    />
                  )}
                  <button id="buy-button-567" type="submit" onClick={() => handleBuyClick(props.product)} className="btn btn-success btn-block">
                    <i className="fa fa-cart-arrow-down"></i> COMPRAR</button>
                </div>)
                : (<div className="col-xs-6 col-md-4 col-lg-3 payment-right">
                  <h4 style={{ marginRight: '20px' }}>INDISPONÍVEL</h4>
                </div>)}
              {error ? (
                <Component.Shared.TextComponent value={error} size={16} color='red' margin={0} align='flex-start' />
              ) : null}
              {successMessage ? (
                <Component.Shared.TextComponent value={successMessage} size={16} color='black' margin={0} align='flex-start' />
              ) : null}
            </div>
          </div>
          {props.product.variacao && props.product.imagens.some(img => img.cod_variacao && img.cod_variacao.length > 0) && (
            <div className="variations">
              <h4 className='product-name'>Variações</h4>
              <p className="description-produto">Apenas em lote de {props.product.qtde_minima} unidades. (1 caixa)</p>
              {/* Itera sobre as imagens filtradas e cria um cartão de variação para cada uma */}
              {props.product.imagens.filter(img => img.cod_variacao && img.cod_variacao.length > 0).map(item => {
                const currentVariation = selectedQuantities.find(variation => variation.id === item.id);
                const currentQuantity = currentVariation ? currentVariation.qtde.toString() : '';
                return (
                  <Component.Custom.VariationCard
                    key={item.id}
                    id={item.id}
                    imagem={item.imagem}
                    cod_variacao={item.cod_variacao}
                    qtde={currentQuantity} // passa a quantidade atual da variação
                    onQuantityChange={handleQuantityChangeVariation}
                  />
                );
              })}
            </div>
          )}
        </div>

      </div>
    </div>
  )
}
function DescriptionWithBreaks({ description }: { description: string }) {
  const splitText = description.split('<br/>').map((text, index, array) => (
    <span className="rows-description" key={index}>
      {text}
      {index === array.length - 1 ? null : <br />}
    </span>
  ));

  return <>{splitText}</>;
}
export default ProductDetailComponent;