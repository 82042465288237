import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import br from 'date-fns/locale/pt-BR';
registerLocale('br', br);

interface Props {
    texto: string;
    onDateChange: (date: Date | null) => void;
    value?: Date | null;
    register?: any;
}

const MonthPickerComponent: React.FC<Props> = ({
    texto,
    onDateChange,
    value,
    register
}) => {
    const [startDate, setStartDate] = useState<Date | null>(null);

    useEffect(() => {
        if (value) {
            setStartDate(value); // Atualiza o estado local quando o prop 'value' muda
        }
    }, [value]);

    const handleChange = (date: Date | null) => {
        setStartDate(date);
        onDateChange(date); // Notifica o componente pai sobre a mudança
    };

    return (
        <DatePicker
            dateFormat="MM/yyyy"
            locale="br"
            selected={startDate} // Usa o estado local para controlar o valor atual
            onChange={handleChange}
            placeholderText={texto}
            showMonthYearPicker
            {...register} // Espalha quaisquer props adicionais que possam ser necessárias para formularios, como react-hook-form
        />
    );
};

export default MonthPickerComponent;
