import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import "./index.css";
import { AlterPedidoInputProps, AlterPedidoSchema, CheckoutRepresInputProps, checkoutRepresSchema } from '../../../schemas/user.schemas';
import { useContext, useEffect, useState } from 'react';
import Component from '../../../components';

import Representada from '../../../../types/representada.type';
import api, { BASE_URL } from '../../../../services/api';
import Client from '../../../../types/client.type';
import ProdutoType from '../../../../types/productcard.type';
import { FaCheck, FaEdit, FaSyncAlt, FaTrash } from 'react-icons/fa';
import Product from '../../../../types/product.type';
import noimage from '../../../../images/noimg.jpg';
import Swal from 'sweetalert2';
import CondPagto from '../../../../types/condpagto.type';
import Docto from '../../../../types/docto.type';
import Endereco from '../../../../types/endereco.type';
import TipoPed from '../../../../types/tipoPed.type';
import { useLocation } from 'react-router-dom';
import Order from '../../../../types/order.type';

type TypeP =
    {
        id: number;
        nome: string;
        imagem: string;
        quantidade: number;
        preco: number;
        total: number;
        acres: number;
        desc: number;
    }

const AlterarPedido: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [listProducts, setListProducts] = useState<Product[]>([])
    const [searchTermProduct, setSearchTermProduct] = useState<string>("");
    const [condpagtos, setCondPagtos] = useState<CondPagto[]>([]);
    const [doctos, setDoctos] = useState<Docto[]>([]);
    const [enderecos, setEnderecos] = useState<Endereco[]>([]);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [error, setError] = useState('');

    const [representadaId, setRepresentadaId] = useState('');
    const [message, setMessage] = useState<string | null>(null);

    const [selectedProduto, setSelectedProduto] = useState<null | ProdutoType>(null);
    const [representadas, setRepresentadas] = useState<Representada[]>([]);
    const location = useLocation();

    const props: Order = location.state as Order;
    const [items, setItems] = useState<TypeP[]>([]);

    const [tipoPeds, setTipoPeds] = useState<TipoPed[]>([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<AlterPedidoInputProps>({
        resolver: zodResolver(AlterPedidoSchema),
        defaultValues: {
            clienteid: props.clienteid.toString(),
            doctoid: props.doctoId.toString(),
            condpagtoid: props.condicao_pagamentoid.toString(),
            tipoped: props.tipoPedId.toString(),
            observacao: props.observacao,
            itempedidos: items,
        }
    })

    
    useEffect(() => {
        setValue('itempedidos', [...items]);
    }, [items, setValue]);


    useEffect(() => {
        // Reseta o erro ao montar a tela novamente
        listarrepresentadas();
        listarTipoPeds();
        // setValue("clienteid", "");
        //setValue("itempedidos", []);


        listCondPagto(props.clienteid, props.tipo);
        //listEndereco(props.clienteid, props.tipo); futura versão - pedido tem endereco sem codigo
        listarProdutos(props.representadaId);
        listDocto(props.clienteid, props.condicao_pagamentoid.toString(), props.tipo)
        setRepresentadaId(props.representadaId.toString());
        setItems(props.items);


    }, []);


    function removeDiacritics(text: string): string {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    const listarrepresentadas = async () => {

        await api.post('/Fornecedores/buscarrepresentadas'
        ).then(response => {
            setRepresentadas(response.data)
        }).catch(error => {
        })

    };


    const filteredProdutos = showAllProducts || !searchTermProduct
        ? listProducts
        : listProducts.filter(produto =>
            removeDiacritics(produto.nome.toLowerCase()).includes(removeDiacritics(searchTermProduct.toLowerCase()))
            ||
            produto.id.toString().includes(searchTermProduct)
        );

    const listarProdutos = async (representadaId: number) => {
        setLoading(true); // Inicia o indicador de carregamento

        if (props.clienteid) {

            // Constrói a URL baseada na condição do representadaId
            let url = '/produtos/listarbyclienteidrepresentadaid';
            url += `?clienteid=${props.clienteid}&&representadaid=${representadaId}&&tipo=${props.tipo}`;

            // Faz a chamada à API usando a URL construída
            await api.get(url)
                .then(response => {
                    setListProducts(response.data); // Atualiza os produtos com a resposta da API
                    setLoading(false); // Finaliza o indicador de carregamento
                })
                .catch(error => {
                    console.error("Erro ao gerar relatório:", error); // Loga qualquer erro que ocorrer na requisição
                    setLoading(false); // Finaliza o indicador de carregamento em caso de erro
                });
        }

    };

    const listarTipoPeds = async () => {

        await api.get('/tipoped'
        ).then(response => {
            setTipoPeds(response.data)

            setValue('tipoped', props.tipoPedId.toString());
        }).catch(error => {
        })

    };

    const listCondPagto = async (clienteid: number, tipo: string) => {
        setLoading(true);
        await api.get('condpagtos/listarbyclienteid?clienteid=' + clienteid + '&&tipo=' + tipo
        ).then(response => {
            setCondPagtos(response.data);
            setLoading(false);
        })

    };

    const listDocto = async (clienteid: number, condpagtoid: string, tipo: string) => {
        setLoading(true);
        await api.get('doctos/listarbycondpagtoidclienteid?condpagtoid=' + condpagtoid + '&&clienteid=' + clienteid + '&&tipo=' + tipo
        ).then(response => {
            setDoctos(response.data);
            setLoading(false);

        })

    };

   // const listEndereco = async (clienteid: number, tipo: string) => {
    //    setLoading(true);
    //    await api.post('/enderecoentrega/listarenderecosbyclienteid?clienteid=' + clienteid + '&&tipo=' + tipo
     //   ).then(response => {
     //       setEnderecos(response.data);
     //       setLoading(false);
     //   })

  //  };

    const handleSelectChange = (condpagtoid: string) => {
        setValue('condpagtoid', condpagtoid);
        listDocto(props.clienteid, condpagtoid, props.tipo)
    };

    const handleSelectProduto = (prod: Product) => {
        //setSelectedProduto(null);
        setSearchTermProduct(""); // Opcional: Limpa o termo de busca

        if (items.some(item => item.id === prod.id)) {
            setMessage('Produto já foi adicionado no pedido.');
            setTimeout(() => setMessage(null), 1500);
        }
        else {

            Swal.fire({
                title: 'Detalhes do Produto',
                html: `
                <div style="text-align: left;">
                <p><strong>Código:</strong> ${prod.id}</p>
                <p><strong>Descrição:</strong> ${prod.nome}</p>
                <p><strong>Preço Tabela:</strong> R$${prod.precoTab.toFixed(2)}</p>
                <p><strong>Preço Líquido:</strong> R$${prod.preco.toFixed(2)}</p>
                <div style="display: flex; align-items: center;">
                <div>
                <label for="swal-input-quantity" style="margin-right: 10px;"><strong>Quantidade:</strong></label>
                <input id="swal-input-quantity" type="number" value="" min="1" style="width: 80px; text-align: right;">
                </div>

                <div style="margin-left: 10px;">
                <label for="swal-input-desc" style="margin-right: 10px;"><strong>Desconto:</strong></label>
                <input id="swal-input-desc" type="number" value="${prod.desc.toFixed(2)}" min="0" max="100" style="width: 80px; text-align: right;" ${prod.acres || prod.desc ? 'disabled' : ''} >
                </div>
                </div>
                </div>
            `,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Adicionar',
                focusConfirm: false,
                preConfirm: () => {

                    const quantityInput = document.getElementById('swal-input-quantity') as HTMLInputElement; // Assegura que é um HTMLInputElement
                    const quantity = quantityInput && Number(quantityInput.value) > 0 ? quantityInput.value : '1';

                    //  const acrescimoInput = document.getElementById('swal-input-acres') as HTMLInputElement;
                    //  const acrescimo = acrescimoInput && Number(acrescimoInput.value) > 0 ? acrescimoInput.value : '0';

                    const descontoInput = document.getElementById('swal-input-desc') as HTMLInputElement;
                    const desconto = descontoInput && Number(descontoInput.value) > 0 ? descontoInput.value : '0';

                    if (prod.saldo < Number(quantity)) {
                        Swal.fire({
                            title: 'Mensagem',
                            text: "Saldo insuficiente do produto.",
                            icon: 'warning',
                        });
                        return;
                    }

                    const productWithQuantity: TypeP = {
                        id: prod.id,
                        nome: prod.nome,
                        imagem: prod.imagens[0].imagem,
                        total: 0,
                        acres: 0,
                        quantidade: Number(quantity),
                        // acres: Number(acrescimo),
                        desc: Number(desconto),
                        preco: prod.desc === 0 && Number(desconto) > 0 ? prod.preco * (1 - (Number(desconto) / 100)) : prod.preco
                    };
                    console.log(productWithQuantity);
                    addProduct(productWithQuantity);
                    return { productWithQuantity };
                }
            }).then((result) => {
                if (result.isConfirmed && result.value) {
                    setShowAllProducts(false);
                    // Aqui você pode adicionar a lógica para adicionar o produto ao carrinho com a quantidade atualizada
                }
            });
        }

    };

    const addProduct = (product: TypeP) => {
        setItems(prevItems => [...prevItems, product]);
    };
    const removeProduct = (productId: number) => {
        setItems(items.filter(product => product.id !== productId));
    };
    const updateProductQtdeDesc = (productId: number, quantity: number, desc: number) => {
        setItems(items.map(product => {
            if (product.id === productId) {
                return { ...product, quantidade: quantity, desc: desc, preco: product.desc === 0 && desc > 0 ? product.preco * (1 - (Number(desc) / 100)) : product.preco };
            }
            return product;
        }));
    };

    const alterarItem = (prod: TypeP) => {


        const data = listProducts.filter(a => a.id === prod.id);


        Swal.fire({
            title: 'Detalhes do Produto',
            html: `
              <div style="text-align: left;">
                <p><strong>Código:</strong> ${prod.id}</p>
                <p><strong>Descrição:</strong> ${prod.nome}</p>
                <p><strong>Preço Tabela:</strong> R$${data[0].precoTab.toFixed(2)}</p>
                <p><strong>Preço Líquido:</strong> R$${prod.preco.toFixed(2)}</p>
                <div style="display: flex; align-items: center;">
                <div>
                <label for="swal-input-quantity" style="margin-right: 10px;"><strong>Quantidade:</strong></label>
                <input id="swal-input-quantity" type="number" value="${prod.quantidade}" min="1" style="width: 80px; text-align: right;">
                </div>

                <div style="margin-left: 10px;">
                <label for="swal-input-desc" style="margin-right: 10px;"><strong>Desconto:</strong></label>
                <input id="swal-input-desc" type="number" value="${prod.desc}" min="0" max="100"  style="width: 80px; text-align: right;" ${prod.acres || prod.desc ? 'disabled' : ''}  >
                </div>
                </div>
                </div>
            `,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Alterar',
            focusConfirm: false,
            preConfirm: () => {
                const quantityInput = document.getElementById('swal-input-quantity') as HTMLInputElement; // Assegura que é um HTMLInputElement
                const quantity = quantityInput && Number(quantityInput.value) > 0 ? quantityInput.value : '1';

                if (data[0].saldo < Number(quantity)) {
                    Swal.fire({
                        title: 'Mensagem',
                        text: "Saldo insuficiente do produto.",
                        icon: 'warning',
                    });
                    return;
                }

                const descontoInput = document.getElementById('swal-input-desc') as HTMLInputElement;
                const desconto = descontoInput && Number(descontoInput.value) > 0 ? descontoInput.value : '0';
                updateProductQtdeDesc(prod.id, Number(quantity), Number(desconto));
                return { ...prod, qtde: quantity, desc: desconto };
            }
        }).then((result) => {
            if (result.isConfirmed && result.value) {
                setShowAllProducts(false);
                // Aqui você pode adicionar a lógica para adicionar o produto ao carrinho com a quantidade atualizada
            }
        });


    }


    const handleSendOrder = async (data: AlterPedidoInputProps) => {


        const mappedItems = data.itempedidos.map(item => ({
            ...item,
            qtde: item.quantidade,  // Adiciona o campo 'qtde' com o valor de 'quantidade'
            preco: item.preco,      // Mantém o resto dos campos
            id: item.id,
            nome: item.nome,
            imagem: item.imagem,
            acres: item.acres,
            desc: item.desc,
            total: item.total
        }));
    
        // Atualiza o objeto data com os items mapeados
        const updatedData = {
            ...data,
            itempedidos: mappedItems
        };


        await api.post('/pedidos/alterarpedidorepres', {
            tipo: props.tipo,
            id: props.id,
            ...updatedData
        }
        ).then(response => {
            Swal.fire({
                title: 'Alterado com sucesso',
                text: "Pedido  alterado com sucesso.",
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',

                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            }).then((result) => {
                if (result.isConfirmed) {
                    //atualizar a pagina
                }
            });

        }).catch(error => {
            setError(error.response.data);
            console.log(error.response.data);
        })
    

    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setSearchTermProduct(searchTerm);

        if (searchTerm.length > 0) {
            setShowAllProducts(false);
        }
    };

    const confirmarExclusao = (id: number) => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: "Você deseja mesmo remover este item do seu pedido?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',

            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
        }).then((result) => {
            if (result.isConfirmed) {
                removeProduct(id);
            }
        });
    };


    const totalValue = items.reduce((acc, product) => acc + product.quantidade * product.preco, 0);
    const totalProd = items.reduce((acc, product) => acc + product.quantidade, 0);

    return (
        <div>
            {message && <div className="success-message">{message}</div>}
            {loading && (
                <div className="product-container">
                    <Component.Shared.LoaderComponent overlay />
                </div>
            )}
            <form className="" onSubmit={handleSubmit(handleSendOrder)}>

                <Component.Shared.TextComponent marginTop={20} value="Cliente" size={18} color='black' margin={0} align='center' />

                <div>
                    <Component.Shared.ClienteCard cliente={{ id: props.clienteid, nome: props.nome, cnpj_cpf: props.cnpj_cpf, telefone: props.telefone, email: '', cidade: props.cidade, uf: props.uf, inativar: false, tipo: "A" }} />
                </div>

                <Component.Shared.TextComponent marginTop={20} value="Representada" size={18} color='black' margin={0} align='center' />

                <div>
                    <Component.Shared.SelectComponent
                        margin={0}
                        marginTop={10}
                        align='flex-start'
                        data={representadas}
                        size={16}
                        color="black"
                        placeholder="* Representada"
                        findById={props.representadaId.toString()}
                        disable={true}
                    />
                </div>

                <Component.Shared.TextComponent marginTop={20} value="Tipo de Pedido" size={18} color='black' margin={0} align='center' />
                <Component.Shared.SelectComponent
                    margin={0}
                    marginTop={10}
                    align='flex-start'
                    data={tipoPeds}
                    size={16}
                    color="black"
                    placeholder=""
                    findById={props.tipoPedId.toString()}
                    disable={true}
                />

                <Component.Shared.TextComponent marginTop={20} value="Produtos" size={18} color='black' margin={0} align='center' />


                {!selectedProduto && representadaId && (
                    <>
                        <Component.Shared.TextInputComponent onChange={handleSearchChange} value={searchTermProduct} size={16} margin={0} marginTop={10} color="black" placeholder="Digite o nome ou código de barras do produto " type="text" />
                        <small style={{ cursor: 'pointer', textDecoration: 'underline', color: 'grey' }} onClick={() => setShowAllProducts(true)}>Listar todos os produtos</small>

                        {errors.itempedidos ? (
                            <Component.Shared.TextComponent value={errors.itempedidos.message} size={12} color='red' margin={0} align='center' />
                        ) : null}
                    </>
                )}

                {(!selectedProduto && (showAllProducts || searchTermProduct)) && (
                    <Component.Shared.ListProductCard produtos={filteredProdutos} onSelectedProduto={handleSelectProduto} />
                )}


                {items.length > 0 && (
                    <>
                        <div className='grid-container'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Foto</th>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        <th>Qtde.</th>
                                        <th>Preço Líq.</th>
                                        <th>Desc.</th>
                                        <th>Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <Component.Shared.ImageProductComponent className="item-image-grid" source={item.imagem.length > 0 ? `${BASE_URL}uploads/${item.imagem}` : noimage} />
                                            </td>
                                            <td>{item.id}</td>
                                            <td>{item.nome}</td>
                                            <td>{item.quantidade}</td>
                                            <td>{`R$ ${item.preco.toFixed(2)}`}</td>
                                            <td >
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                    <span style={{ color: 'red' }}>
                                                        {`${item.desc ? item.desc.toFixed(2) : '0,00'}%`} &#x25BC; {/* Seta para baixo */}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                R${(item.quantidade * item.preco).toFixed(2)}
                                                <FaEdit onClick={() => alterarItem(item)} style={{ marginLeft: '3px', cursor: 'pointer' }} title='Alterar produto' size={11} />
                                                <FaTrash onClick={() => confirmarExclusao(item.id)} style={{ marginLeft: '3px', cursor: 'pointer' }} size={11} title='Remover produto' />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="containertotals">
                            <div className="item">
                                <p className="label">Quantidade de itens</p>
                                <p>{totalProd}</p>
                            </div>
                            <div className="valor">
                                <p className="label">Valor total</p>
                                <p className='texto-valortotal'>R$ {totalValue.toFixed(2)}</p>
                            </div>
                        </div>
                    </>
                )}

                <Component.Shared.TextComponent marginTop={20} value="Condição de pagamento" size={18} color='black' margin={0} align='center' />

                <Component.Shared.SelectComponent onChange={handleSelectChange} margin={0} marginTop={10} align='flex-start' data={condpagtos} size={16} color="black" placeholder="" register={register('condpagtoid')} findById={props.condicao_pagamentoid.toString()} />

                {errors.condpagtoid ? (
                    <Component.Shared.TextComponent value={errors.condpagtoid.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                <Component.Shared.TextComponent marginTop={20} value="Documento" size={18} color='black' margin={0} align='center' />

                <Component.Shared.SelectComponent margin={0} marginTop={10} align='flex-start' data={doctos} size={16} color="black" placeholder="" register={register('doctoid')} findById={props.doctoId.toString()} />

                {errors.doctoid ? (
                    <Component.Shared.TextComponent value={errors.doctoid.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}


                <Component.Shared.TextComponent marginTop={20} value="Observação" size={18} color='black' margin={0} align='center' />
                <Component.Shared.TextInputComponent type='textarea' align='flex-start' placeholder='' size={15} maxLength={200} color='black' margin={0} register={register('observacao')} />

                {error ? (
                    <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                <button type="submit" className="btn btn-primary button">
                    <FaCheck size={18} />
                    <span> Salvar</span>
                </button>
            </form>
        </div>
    );
}

export default AlterarPedido;