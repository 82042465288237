import styled from 'styled-components';
import React from 'react';
import { FaCircle, FaEnvelope, FaExclamation, FaExclamationCircle, FaMapMarker, FaPhoneAlt, FaRegIdCard, FaUserAlt, FaUserAltSlash, FaUserTie } from 'react-icons/fa';
import Order from '../../types/order.type';

type PedidoCardProps = {
  pedido: Order;
};


const StyledCard = styled.div`
  padding: 10px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 10px;
  border-radius: 5px;
  color: black;
  pointer-events: auto;

  &:first-child {
    border-top: 1px solid #e7e7e7;
  }

  &:hover {
    background-color: #f0f0f0;
  }

.headerv {
  display: flex;
  align-items: baseline;
  font-size: 1em;
  max-width: 100%;
  margin-bottom: 0 !important; // Espaço entre o cabeçalho e o conteúdo abaixo
}

.nome {
  margin-right: 0.5em; // Espaço entre o nome e o CNPJ

}

.cnpj {
  color: #999;
  font-weight:normal;
  line-height: 1;
}

.dadosclicard
{
  margin-bottom: 0 !important;
}

.telefone,
{
  margin-right: 1em !important;
  margin-bottom: 0 !important;
  
}
.cidade
{
  margin-bottom: 0 !important;
}

.icons
{
  margin-right: 0.2em;
}

.contact-info {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

  .email-info {
    display: flex;
    align-items: center;
    overflow-wrap: break-word; // Isso permite que o texto quebre para a nova linha
    word-break: break-word; // Quebra a palavra ao alcançar o fim do container
    hyphens: auto; // Adiciona hífens se necessário
    max-width: calc(100% - 120px); // Limita a largura máxima, 20px é o dobro do padding para ajuste
    margin-bottom: 0 !important;

  }

  .email {
    overflow: hidden; // Esconde qualquer texto que ultrapasse o container
    text-overflow: ellipsis; // Adiciona "..." se o texto for muito longo para o container
    white-space: nowrap; // Mantém o texto do e-mail em uma única linha
    margin-right: 1em !important;
    margin-bottom: 0 !important;
  }

  .nome-info {
    min-height:48px !important;
  }

  @media (max-width: 445px) {
  margin-right: -15px !important;
  margin-left: -15px !important;
  }

`;

function aplicarMascaraCpfCnpj(valor: string) {
  // Remove caracteres não numéricos
  const apenasNumeros = valor.replace(/\D/g, '');

  // Verifica se é CPF ou CNPJ e aplica a máscara correspondente
  if (apenasNumeros.length <= 11) { // CPF
    return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else { // CNPJ
    return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}



function aplicarMascaraTel(valor: string) {
  // Remove caracteres não numéricos
  const apenasNumeros = valor.replace(/\D/g, '');

  if (apenasNumeros.length === 11) {
    return apenasNumeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  // Verifica se o número tem 10 dígitos (telefone com oito dígitos + DDD)
  else if (apenasNumeros.length === 10) {
    return apenasNumeros.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
  // Retorna o número não formatado caso não tenha 10 ou 11 dígitos
  else {
    return apenasNumeros;
  }
}

const PedidoCard: React.FC<PedidoCardProps> = ({ pedido }) => {
  return (
    <StyledCard >
      <div className="headerv">
        <p className='telefone'> {pedido.data}</p>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
          <div style={{ flexDirection: 'column', minWidth: '220px' }} title={'Detalhar pedido: #' + pedido.id.toString()}>
            <h5 >Pedido #{pedido.id}</h5>
          </div>
          <div style={{ flexDirection: 'column', minWidth: '220px', marginLeft: '50px' }}>
            <h5 style={{ color: pedido.situacao.trim() === 'Cancelado' ? 'red' : 'black' }}>{pedido.situacao} </h5>
          </div>

        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <h5 >R$ {pedido.totalPedido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </h5>
        </div>
      </div>

      <div className="nome-info" >
        <FaUserAlt size={14} className='icons' />
        {pedido.nome}
      </div>
      <div className="headerv">
        <FaRegIdCard size={14} className='icons' />
        <p className='telefone'> {aplicarMascaraCpfCnpj(pedido.cnpj_cpf)}</p>
      </div>
      <div className="headerv">
        <div className="email-info" title={pedido.documento}>
          <FaCircle size={14} className='icons' /><p className='email'>  {pedido.descTipoPed}</p>
        </div>
        <div className="contact-info">
          <FaPhoneAlt size={14} className='icons' />
          <p className='telefone'> {aplicarMascaraTel(pedido.telefone)}</p>
        </div>

      </div>
      <div className="headerv">
        <FaMapMarker size={14} className='icons' /> <p className='cidade'>{pedido.cidade}, {pedido.uf}</p>
      </div>
      <div className="headerv">
        <FaUserTie  size={14} className='icons' />
        <p className='telefone'> {pedido.vendedorDesc}</p>
      </div>
    </StyledCard>
  );
};

export default PedidoCard;
