import React, { useRef, useEffect, useState } from 'react';
import { ArcElement, Chart, ChartConfiguration, ChartOptions, Plugin } from 'chart.js';
import { format, eachDayOfInterval, startOfMonth, endOfMonth } from 'date-fns';

import api from '../../services/api';
import Component from '../components';

const centerTextPlugin: Plugin = {
  id: 'centerText',
  afterDraw: (chart) => {
    const { ctx, chartArea } = chart;

    const chartType = (chart.config as any).type;
    if (chartType !== 'doughnut' && chartType !== 'pie') {
      return;
    }
    if (!chartArea) {
      return;
    }

    ctx.save();

    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;
    const fontSize = (chartArea.bottom - chartArea.top) / 10; // Ajuste o divisor conforme necessário para alterar o tamanho da fonte
    ctx.font = `${fontSize}px Lato, sans-serif`;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#000000';
    
    const meta = chart.getDatasetMeta(0);
    const totalClients = meta.data.reduce((acc, element, index) => {
      const value = chart.data.datasets[0].data[index];
      return chart.getDataVisibility(index) && typeof value === 'number' ? acc + value : acc;
    }, 0);
    const text = `${totalClients} Clientes`;
    


    ctx.fillText(text, centerX, centerY);
  
     
    // Adiciona as porcentagens ao lado dos segmentos
    meta.data.forEach((element, index) => {
      if (!chart.getDataVisibility(index)) return;

      const value = chart.data.datasets[0].data[index] as number;
      const percentage = ((value / totalClients) * 100).toFixed(2) + '%';

      const { x, y } = (element as any).tooltipPosition();

      const arc = element as ArcElement;
      const radius = (arc.outerRadius + arc.innerRadius) / 2;
      const angle = (arc.startAngle + arc.endAngle) / 2;
      const xOffset = Math.cos(angle) * (radius + 11); // Ajuste de 20 pixels para fora
      const yOffset = Math.sin(angle) * (radius + 11); // Ajuste de 20 pixels para fora

      ctx.font = `${fontSize * 0.6}px Lato, sans-serif`;
      ctx.fillStyle = '#000000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(percentage, centerX + xOffset, centerY + yOffset);
    });


      ctx.restore();
  }
};

// Certifique-se de registrar o plugin
Chart.register(centerTextPlugin);


interface GraficoPositivacaoComponentProps {
  onStatusChange: (status: string[]) => void; // Define a função que recebe um array de strings
  selectedDate: Date | null;
  selectedRepres: number;
}

const GraficoCurvaABC: React.FC<GraficoPositivacaoComponentProps> = ({onStatusChange, selectedDate, selectedRepres }) => {
  const doughnutCanvasRef = useRef<HTMLCanvasElement>(null);
  const [chartData, setChartData] = useState<number[]>([]);
  const [visibleStatus, setVisibleStatus] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [chartInstance, setChartInstance] = useState<Chart<"doughnut"> | null>(null);
  useEffect(() => {
    const fetchData = async () => {

      if (!selectedDate) return;
      setLoading(true); // Ativa o estado de carregamento
      const mesAno = format(selectedDate, 'MM/yyyy');
      try {
        // Substitua a URL pela URL da sua API
        const response = await api.post(`/relatorios/grafico_clientes_curvaabc?mesAno=${mesAno}&representanteid=${selectedRepres}`);
        const resultsCli = response.data;
        const labels = ['Curva A', 'Curva B', 'Curva C']; // Assume que esses são os labels
     
        // Processar os dados recebidos para a estrutura necessária
        const curvaA = resultsCli.find((item: any) => item.status_cliente === "A")?.quantidade || 0;
        const curvaB = resultsCli.find((item: any) => item.status_cliente === "B")?.quantidade || 0;
        const curvaC = resultsCli.find((item: any) => item.status_cliente === "C")?.quantidade || 0;
  
        setChartData([curvaA,curvaB,curvaC]); // Atualize conforme necessário
        setVisibleStatus(labels);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate,selectedRepres]);

  
  useEffect(() => {
    if (doughnutCanvasRef.current && chartData.length > 0) {
      const ctx = doughnutCanvasRef.current.getContext('2d');

      if (ctx) {
        const config: ChartConfiguration<'doughnut'> = {
          type: 'doughnut',
          data: {
            labels: ['A', 'B', 'C'],
            datasets: [{
              data: chartData,
              backgroundColor: ['#7E51A9', '#B47AC6', '#F7DDFF'],
              borderColor: 'white',
              borderWidth: 2
            }]
          },
          options: {
            cutout: '90%',
            layout: {
              padding: {
                bottom: 0, // Aumenta o espaçamento na parte inferior
              },
            },
            plugins: {
              legend: {
                display: true,
                position: 'bottom',
                onClick: (e, legendItem, legend) => {
                  const ci = legend.chart;
                  const index = legendItem.index ?? 0;
                  ci.toggleDataVisibility(index);
                  ci.update();

                    // Atualiza o estado de visibilidade dos status e envia para o Home
                    const visibleLabels = (ci.data.labels as string[]).filter((_, i) => ci.getDataVisibility(i));
                onStatusChange(visibleLabels);
                },
              },
              title: {
                display: true,
                text: 'Curva ABC',
                font: {
                  size: 18,
                },
                align: 'start',
                padding: {
                  top: 10,
                  bottom: 30
                }
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    const value = tooltipItem.raw as number;
                    const total = chartData.reduce((a, b) => a + b, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `${tooltipItem.label}: ${value} (${percentage}%)`;
                  }
                }
              }
            }
          } as ChartOptions<'doughnut'>
        };

    
        if (chartInstance) {
          chartInstance.destroy();
        }

        const newChartInstance = new Chart(ctx, config);
        setChartInstance(newChartInstance);

        return () => newChartInstance.destroy();
      }
    }
  }, [chartData]);

  return (
    <div  style={{ width: 300, height: 300, display: loading ? 'none' : 'block' }}>
      {loading && (
        <div className="product-container">
          <Component.Shared.LoaderComponent overlay />
        </div>
      )}
      <canvas ref={doughnutCanvasRef} />
    </div>
  );
};

export default GraficoCurvaABC;
