import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import br from 'date-fns/locale/pt-BR';
registerLocale('br', br);

interface Props {
    texto: string;
    onDateChange: (date: Date | null) => void;
    value?: Date | null; // Propriedade opcional para controlar o valor externamente
    register?: any;
}

const DatePickerComponent: React.FC<Props> = ({
    texto,
    onDateChange,
    value,
    register
}) => {
    const [startDate, setStartDate] = useState<Date | null>(null);

    // Efeito para sincronizar o estado interno com o prop 'value'
    useEffect(() => {
        if(value)
        setStartDate(value); // Atualiza o estado local quando o prop 'value' muda
    }, [value]);

    const handleChange = (date: Date | null) => {
        setStartDate(date);
        onDateChange(date); // Notifica o componente pai sobre a mudança
    };

    return (
        <DatePicker
            dateFormat="dd/MM/yyyy"
            locale="br"
            selected={startDate} // Usa o estado local para controlar o valor atual
            onChange={handleChange}
            placeholderText={texto}
            {...register} // Espalha quaisquer props adicionais que possam ser necessárias para formularios, como react-hook-form
        />
    );
};

export default DatePickerComponent;
