import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { RegisterInputProps, registerSchema } from "../../components/schemas/user.schemas";
import useAuthStore from "../../context/auth.store";
import './index.css';
import ImageComponent from '../../components/shared/image.shared.component';


import Component from '../../components/components';
import axios from 'axios';
import { useEffect, useState } from 'react';
import states from './../../types/state'; // Importe a lista de estados
import tipoInsc from '../../types/tipoinsc'; // Importe a lista de estados

const CreateAccount = () => {

    const { create, error, clearError } = useAuthStore()
    const [loading, setLoading] = useState(false);
    const [sucess, setSucess] = useState(false);

    useEffect(() => {
        // Reseta o erro ao montar a tela novamente
        clearError();
    }, []);

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<RegisterInputProps>({ resolver: zodResolver(registerSchema), defaultValues: { tipo: 'pf' } })


    const onSubmit = async (data: RegisterInputProps) => {
        setSucess(false);
        setLoading(true);  // Start loading
        const errorFromCreate = await create(data);

        setLoading(false);  // Start loading

        if (errorFromCreate == '') {
            setSucess(true);
            clearError();
        }
    }

    const handleCepChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        const cep = event.target.value.replace(/\D/g, '');
        if (cep.length === 8) {
            axios
                .get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        const data = response.data;
                        // Utilize o setValue para atualizar os campos do formulário com os dados retornados pela API
                        setValue('endereco', data.street);
                        setValue('bairro', data.neighborhood);
                        setValue('cidade', data.city);
                        setValue('uf', data.state);

                    } else {
                        console.error('CEP inválido ou não encontrado.');
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar o CEP:', error);
                });
        }
    };
    const handlePersonTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Limpa o campo 'cnpj_cpf' ao trocar o tipo de pessoa
        setValue('cnpj_cpf', '');
        setValue('tipo', e.target.value);
    };

    return (
        <div className="container-principal">
     
        <div className='main-create'>
            <div className="centered-content">
                {loading && <Component.Shared.LoaderComponent overlay />}
                <form className="form-control contentLogin-create" onSubmit={handleSubmit(onSubmit)}>
                    <ImageComponent height={80} width={200} className='' source='/logogrupoayres.jpg' margin={50} />

                    <div className='form-check' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <input type="radio" value="pf" {...register('tipo')} defaultChecked onChange={handlePersonTypeChange} /> Pessoa Física
                        <div style={{ marginLeft: '50px' }}></div> {/* Espaço entre os botões */}
                        <input type="radio" value="pj" {...register('tipo')} onChange={handlePersonTypeChange} /> Pessoa Jurídica
                    </div>


                    {watch('tipo') === 'pf' ? (
                        <><Component.Shared.TextInputComponent size={16} margin={0} marginTop={10} color="black" placeholder="* Nome Completo" type="text" register={register('nome')} />

                            {errors.nome ? (<Component.Shared.TextComponent value={errors.nome.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}

                            <Component.Shared.TextInputComponent mask='cpf' margin={0} marginTop={10} maxLength={14} size={16} color="black" placeholder="* CPF" type="text" register={register('cnpj_cpf', { required: 'CPF é obrigatório' })} /></>
                    ) : (
                        <><Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Razão Social" type="text" register={register('nome')} />

                            {errors.nome ? (<Component.Shared.TextComponent value={errors.nome.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}

                            <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Fantasia" type="text" register={register('fantasia')} />

                            {errors.fantasia ? (<Component.Shared.TextComponent value={errors.fantasia.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}

                            <Component.Shared.TextInputComponent margin={0} marginTop={10} mask='cnpj' maxLength={18} size={16} color="black" placeholder="* CNPJ" type="text" register={register('cnpj_cpf', { required: 'CNPJ é obrigatório' })} /></>
                    )}
                    {errors.cnpj_cpf ? (<Component.Shared.TextComponent value={errors.cnpj_cpf.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}
                    <Component.Shared.SelectComponent register={register('TipoInscricao')} margin={0} marginTop={10} align='flex-start' data={tipoInsc} size={16} color="black" placeholder="* Tipo de inscrição" />
                    {errors.TipoInscricao ? (
                        <Component.Shared.TextComponent value={errors.TipoInscricao.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent marginTop={10} margin={0} size={16} color="black" maxLength={15} mask='inscricao' placeholder="* Inscrição" type="text" register={register('inscricao')} />
                    {errors.inscricao ? (
                        <Component.Shared.TextComponent value={errors.inscricao.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}


                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" maxLength={15} mask='telefone' placeholder="* Telefone com DDD" type="text" register={register('telefone')} />
                    {errors.telefone ? (
                        <Component.Shared.TextComponent value={errors.telefone.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}


                    <Component.Shared.TextComponent marginTop={20} value="ENDEREÇO PRINCIPAL" size={14} color='black' margin={0} align='center' />

                    <Component.Shared.TextInputComponent
                        size={16}
                        color="black"
                        placeholder="* CEP"
                        mask="cep"
                        type="text"
                        maxLength={9}
                        margin={0} marginTop={10}
                        register={register('cep')}
                        onChange={handleCepChange} // Chama a função handleCepChange quando o valor do CEP mudar
                    />
                    {errors.cep ? (
                        <Component.Shared.TextComponent value={errors.cep.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Endereço" type="text" register={register('endereco')} />
                    {errors.endereco ? (
                        <Component.Shared.TextComponent value={errors.endereco.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}
                    {/* Para colocar os campos número e complemento na mesma linha */}
                    <div className='inputs-create'>
                        <Component.Shared.TextInputComponent margin={0} marginTop={10} maxLength={10} size={16} color="black" placeholder="* Número" type="text" register={register('numero')} />
                        <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="Complemento" type="text" register={register('complemento')} />
                    </div>
                    {errors.numero ? (
                        <Component.Shared.TextComponent value={errors.numero.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Bairro" type="text" register={register('bairro')} />
                    {errors.bairro ? (
                        <Component.Shared.TextComponent value={errors.bairro.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Cidade" type="text" register={register('cidade')} />
                    {errors.cidade ? (
                        <Component.Shared.TextComponent value={errors.cidade.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}


                    <Component.Shared.SelectComponent marginTop={10} register={register('uf')} align='flex-start' data={states} margin={0} size={16} color="black" placeholder="* UF" />
                    {errors.uf ? (
                        <Component.Shared.TextComponent value={errors.uf.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextComponent marginTop={20} value="DADOS DE ACESSO" size={14} color='black' margin={0} align='center' />

                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* E-mail" type="text" register={register('email')} />
                    {errors.email ? (
                        <Component.Shared.TextComponent value={errors.email.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}


                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Senha" type="password" register={register('senha')} />
                    {errors.senha ? (
                        <Component.Shared.TextComponent value={errors.senha.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* Confirmação de senha" type="password" register={register('confirmacao_senha')} />
                    {errors.confirmacao_senha ? (
                        <Component.Shared.TextComponent value={errors.confirmacao_senha.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    {error ? (
                        <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    {sucess ? (
                        <Component.Shared.TextComponent value={'Registro concluído com sucesso.'} size={12} color='green' margin={0} align='flex-start' />
                    ) : null}
                    <button className="form-control btn btn-primary button" type='submit'>Solicitar acesso</button>

                </form>

            </div>
            <div className="footer-sistema-create">
                <p>Inovando Sistemas - Todos os direitos reservados.</p>
            </div>
        </div>
        </div>
    
    )
}

export default CreateAccount;


