import React from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import Component from '../../components/components';

const Panel: React.FC = () => {

  return (
    <div>
      <Header />
       <Component.Custom.Panel />
      <Footer />
    </div>
  );
};

export default Panel;