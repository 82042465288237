import React from 'react';
import ProductCard from './productcard.shared.component';
import ProdutoType from '../../types/productcard.type';
import Product from '../../types/product.type';

interface ListProductProps {
  produtos: Product[];
  onSelectedProduto?: (prod: Product) => void; 
}

const ListaProductCard: React.FC<ListProductProps> = ({ produtos, onSelectedProduto }) => {

  const handleProductClick = (prod: Product) => {
    // Verifica se o produto tem estoque antes de permitir a seleção
    if (prod.saldo > 0 && onSelectedProduto) {
      onSelectedProduto(prod);
    }
  };

  return (
    <div>
      {produtos.map(prod => (
        <div onClick={() => handleProductClick(prod)} key={prod.id} style={{ opacity: prod.saldo > 0 ? 1 : 0.5 }}>
          <ProductCard produto={prod} />
        </div>
      ))}
    </div>
  );
};

export default ListaProductCard;
