import React, { useEffect, useState } from "react";
import noimage from '../../images/noimg.jpg';
interface ImagePros {

    source: string,
    className: string,
    onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

const ImageProductComponent: React.FC<ImagePros> = ({
    className,
    source
    , onClick
}) => {

    const [imageSrc, setImageSrc] = useState(noimage); // Começa com a imagem de placeholder

    useEffect(() => {
        const img = new Image();
        img.src = process.env.PUBLIC_URL + source;
        img.onload = () => setImageSrc(process.env.PUBLIC_URL + source); // Atualiza o estado quando a imagem real é carregada
    }, [source]);

    return (
        <img
            className={className}
            src={imageSrc}
            alt="Product"
            onClick={onClick}
            style={{ cursor: onClick && 'zoom-in' }}
        >
        </img>
    );
}

export default ImageProductComponent;