import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProductContext } from "../../../../context/product.provider";
import { AuthContext } from "../../../../context/auth.provider";
import SubCategory from "../../../../types/subcategory.type";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import UserProps from "../../../../types/user.type";
import api from "../../../../services/api";
import { AxiosRequestConfig } from "axios";
import Component from "../../../components";
import { v4 as uuidv4 } from 'uuid'; 
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import './cadastro-produto.css';
import { zodResolver } from "@hookform/resolvers/zod";
import { ProductSchema } from "../../../schemas/product.schemas";

type Product = {
  id: number;
  custo: string;
  margemBruta: string;
  marcaid: number;
  subcategoriaid: number;
  nome: string;
  descricao: string;
  preco: string;
  saldo: string;
  Imagens: string[];
};

type UploadedImage = {
  formato: string;
  imagem: string;
  CodVariacao: string; 
};

type Marca = {
  id: string;
  descricao: string;
}

const CadastroProduto: React.FC = () => {

  const navigate = useNavigate();
  const productContext = useContext(ProductContext);
  const { getProductRegistration } = productContext;
  const [products, setProducts] = useState<Product[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  const [selectedImageUrls, setSelectedImageUrls] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<
  {
    previewUrl: string | undefined; CodVariacao: string; formato: string; imagem: string 
}[]
>([]);


  const [subcategorias, setSubCategories] = useState<SubCategory[]>([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>('');

  const [marcas, setMarcas] = useState<Marca[]>([]);
  const [marcasCadastradas, setMarcasCadastradas] = useState<Marca[]>([]);
  const [selectedMarcaSelecionada, setSelectedMarcaSelecionada] = useState<string>('');


  const [isEditing, setIsEditing] = useState(false);
  const [editingProductId, setEditingProductId] = useState<number | null>(null);
  const [isNewCadastro, setIsNewCadastro] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Product>({
    resolver: zodResolver(ProductSchema)
  });

  const [produtoAtual, setProdutoAtual] = useState<Product>({
    id: 0,
    custo: '0.00',
    margemBruta: '0.00',
    marcaid: 0,
    subcategoriaid: 0,
    nome: '',
    descricao: '',
    preco: '0.00',
    saldo: '0',
    Imagens: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Calcula a lista de produtos a ser exibida na página atual
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
  const currentProductsP = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const token = Cookies.get('AccessToken');
    if (!token) {
      navigate("/login");
      return;
    } else {
      const decoded = jwtDecode<UserProps>(token);
      if (decoded.exp * 1000 < Date.now()) {
        Cookies.remove('AccessToken');
        navigate("/login");
        return;
      }
    }
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
        try {
          await fetchProducts();
          await fetchSubCategories();
          await listMarcas();
        } catch (error) {
          console.error('Ocorreu um erro:', error);
        }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const  listMarcas = async () => {
    try {
      const response = await api.get('/Marcas/listar');
      if (response.status === 200) {
        const marcasdata = response.data as Marca[];
        console.log('Marcas cadastradas:', response.data);
        setMarcas(marcasdata);
        setMarcasCadastradas(marcasdata);
      }
      } catch (error) {
        console.error('Ocorreu um erro ao listar as marcas:', error);
      } finally {
        setIsLoading(false);
      }
    }
  
    const  fetchSubCategories = async () => {
      try {
        const response = await api.get('/SubCategorias/listar');
        if (response.status === 200) {
          const subcategoriasdata = response.data as SubCategory[];
          setSubCategories(subcategoriasdata);
          console.log('Subcategorias',subcategoriasdata)
        } else {
          console.error('Erro ao listar as categorias.');
        }
      } catch (error) {
        console.error('Erro ao enviar a solicitação:', error);
      }
    };
    
  const fetchProducts = async () => {
    try {
      const response = await api.get('/produtos/listar');
      if (response.status === 200) {
        const productsData = response.data as Product[];
        setProducts(productsData);
      } else {
        console.error('Erro ao listar os produtos.');
      }
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setProdutoAtual({
      ...produtoAtual,
      [name]: value,
    });
  };

  //useEffect para calcular preço de venda
  useEffect(() => {
    calculateSellingPrice();
  }, [produtoAtual.custo, produtoAtual.margemBruta]);

  const formatValue = (value: string) => {
    if (typeof value !== 'string') {
      return '0.00';
    }

    return value.replace(/\s+/g, '').replace(/(?<=^\d+\.\d*)0+$/, '');
  };


  const calculateSellingPrice = () => {
    const { custo, margemBruta } = produtoAtual;
    const sellingPrice = parseFloat(formatValue(custo)) / (1 - parseFloat(formatValue(margemBruta)) / 100);

    const produtoAtualizado = {
      ...produtoAtual,
      preco: isNaN(sellingPrice) ? '0.00' : sellingPrice.toFixed(2),
    };

    setProdutoAtual(produtoAtualizado);
  };

  const onFocus = (fieldName: keyof typeof produtoAtual) => {
    setValue(fieldName, '');
    setProdutoAtual({
      ...produtoAtual,
      [fieldName]: '',
    });
  };

  const onBlur = (fieldName: keyof typeof produtoAtual) => {
    if (!produtoAtual[fieldName] && fieldName !== 'preco') {
      setValue(fieldName, '0.00');
      setProdutoAtual({
        ...produtoAtual,
        [fieldName]: '0.00',
      });
    }
  };

  const formatToTwoDecimals = (
    fieldName: keyof typeof produtoAtual,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    let valueAsString: string;

    if (typeof value === 'number' || !isNaN(Number(value))) {
      valueAsString = value.toString();
    } else {
      valueAsString = String(value);
    }

    if (valueAsString && !valueAsString.includes('.')) {
      setValue(fieldName, `${valueAsString}.00`);
      setProdutoAtual({
        ...produtoAtual,
        [fieldName]: `${valueAsString}.00`,
      });
    } else if (valueAsString.includes('.') && valueAsString.split('.')[1].length === 1) {
      setValue(fieldName, `${valueAsString}0`);
      setProdutoAtual({
        ...produtoAtual,
        [fieldName]: `${valueAsString}0`,
      });
    }
  };

  function getFileFormat(url: string) {
    return url.substring(url.lastIndexOf('.'));
  }

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
  
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileExtension = getFileFormat(file.name);
        const uniqueFileName = `${uuidv4()}${fileExtension}`;
        const formData = new FormData();
        formData.append('file', file, uniqueFileName);
  
        try {
          const response = await api.post('/Produtos/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
  
          const newImageFileName = response.data;
          const base64String = await getImageDataAsBase64(newImageFileName);
          const imageUrl = URL.createObjectURL(file);
  
          setUploadedImages((prevImages) => ([
            ...prevImages,
            {
              formato: getFileFormat(newImageFileName),
              imagem: base64String,
              CodVariacao: '', 
              previewUrl: imageUrl,
            },
            
          ]));
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }
    }
  };
  
  async function getImageDataAsBase64(imageUrl: string) {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error(`Erro ao obter conteúdo da imagem: ${imageUrl}`);
      }

      const blob = await response.blob();
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {

            const base64String = (reader.result as string).split(',')[1];
            resolve(base64String);
          } else {
            reject(new Error('Falha ao ler a imagem como base64'));
          }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw error;
    }
  }

  const onSubmit = async (data: Product) => {
   

      const formattedImages = uploadedImages.map((image) => ({
        formato: image.formato,
        imagem: image.imagem,
        CodVariacao: image.CodVariacao,
      }));

      const requestData = {
        ...data,
        marcaid: selectedMarcaSelecionada,
        preco: produtoAtual.preco,
        subcategoriaid: selectedSubcategory,
        Imagens: uploadedImages,
      };

      api
        .post('/produtos/inserir', requestData)
        .then((response) => {
          if (response.status === 200) {
          setProdutoAtual({
            id: 0,
            custo: '0.00',
            margemBruta: '0.00',
            marcaid: 0,
            subcategoriaid: 0,
            nome: '',
            descricao: '',
            preco: '0.00',
            saldo: '0',
            Imagens: [],
          });
        }
                  
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }

          fetchProducts();
          setSelectedSubcategory('');
          setUploadedImages([]);
          setSelectedMarcaSelecionada('')
          setIsEditing(false)
          setSuccessMessage('Cadastro realizado com sucesso.');
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        })
        .catch((error) => {
          console.error('Erro ao adicionar um produto:', error);
        });
     
  };

  const handleNewCadastro = () => {
    if (editingProductId) {
      setProdutoAtual({
        id: 0,
        custo: '0.00',
        margemBruta: '',
        marcaid: 0,
        subcategoriaid: 0,
        nome: '',
        descricao: '',
        preco: '0.00',
        saldo: '0',
        Imagens: [],
      });
      setEditingProductId(0);
      setSelectedSubcategory('');
      setUploadedImages([]);
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const editProduct = (id: number) => {
    const produtoEditar = products.find((product) => product.id === id);

    if (produtoEditar) {
      setProdutoAtual({
        id: produtoEditar.id,
        nome: produtoEditar.nome,
        descricao: produtoEditar.descricao,
        custo: produtoEditar.custo,
        margemBruta: produtoEditar.margemBruta,
        marcaid: produtoEditar.marcaid,
        subcategoriaid: produtoEditar.subcategoriaid,
        preco: produtoEditar.preco,
        saldo: produtoEditar.saldo,
        Imagens: produtoEditar.Imagens,
      });

      setIsEditing(true);
      setEditingProductId(id);
    } else {
      console.error(`Produto com ID não encontrado.`);
    }
  };

  const handleEditProduto = async (data: Product) => {
  
      const formattedImages = uploadedImages.map((image) => ({
        formato: image.formato,
        imagem: image.imagem,
        CodVariacao: image.CodVariacao,
      }));

      const requestData = {
        ...data,
        marcaid: selectedMarcaSelecionada,
        preco: produtoAtual.preco,
        subcategoriaid: selectedSubcategory,
        Imagens: uploadedImages,
      };
    
        api
          .put('/Produtos/alterar', requestData)
          .then((response) => {
            if (response.status === 204) {
              fetchProducts();
              setProdutoAtual({
                id: 0,
                custo: '0.00',
                margemBruta: '0.00',
                marcaid: 0,
                subcategoriaid: 0,
                nome: '',
                descricao: '',
                preco: '0.00',
                saldo: '0',
                Imagens: [],
              });
              setUploadedImages([])
              setIsEditing(false);
              setSelectedSubcategory('');
              setSelectedMarcaSelecionada('');
              setEditingProductId(null);
              setIsNewCadastro(false)
              fetchProducts()
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              console.log('Produto editado com sucesso.');
            } else {
              console.error('Erro ao editar o produto.');
            }
          })
          .catch((error) => {
            console.error('Erro ao editar o produto:', error);
          });
      
    }

  const handleExcluirProduto = (data: Product) => {
    
    const request: AxiosRequestConfig = {
      data: {
        id: data.id,
        custo: data.custo,
        margemBruta: data.margemBruta,
        marcaid: data.marcaid,
        subcategoriaid: data.subcategoriaid,
        nome: data.nome,
        descricao: data.descricao,
        preco: data.preco,
        saldo: data.saldo,
        Imagens:uploadedImages
      },
    };

    api
      .delete('/Produtos/excluirProduto', request)
      .then((response) => {
        if (response.status === 204) {
          fetchProducts();
        } else {
          console.error('Erro ao excluir a Produto.');
        }
      })
      .catch((error) => {
        console.error('Erro ao enviar a solicitação para excluir o Produto:', error);
      });
    
  }

  const pesqProduct = () => {
    const filtered = products.filter((product) =>
      product.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    pesqProduct();
  }, [searchTerm]);

  const cancelSearch = () => {
    setFilteredProducts(products); 
    setSearchTerm(''); 
    fetchProducts()
  };
  
  const handleCancel = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (isEditing) {
      // Se estiver editando, limpe o estado atual do produto
      setProdutoAtual({
        id: 0,
        custo: '0.00',
        margemBruta: '0.00',
        marcaid: 0,
        subcategoriaid: 0,
        nome: '',
        descricao: '',
        preco: '0.00',
        saldo: '0',
        Imagens: [],
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setSelectedSubcategory('');
      setUploadedImages([]);
      setIsEditing(false);
      setEditingProductId(null);
      setIsNewCadastro(false); 

    } 
  };
  useEffect(() => {
    if (marcas.length > 0) {
     
    }
  }, [marcas]);

  const getMarcaDescricaoById = (marcaid: string) => {
    const idToCompare = marcas.length > 0 && typeof marcas[0].id === 'number' ? parseInt(marcaid, 10) : marcaid;
  
    const marca = marcas.find((m) => m.id === idToCompare);
    return marca ? marca.descricao : "Marca não encontrada";
  };
  
   
  const getSubCategoriaDescricaoById = (subcategoriaid: number) => {
    const subcategoria = subcategorias.find((s) => s.id === subcategoriaid);
    return subcategoria ? subcategoria.descricao : "Subcategoria não encontrada";
  };
  
  return (

      <div>
          <div className="box-insert"  onClick={handleNewCadastro} >
            <span className="text-button-insert">Novo </span>
            <FaPlus title="Adicionar novo registro" color="white" size={25} />
        </div>
        <div className="divisor-container">
        <div className="divisor1">
          <input
            type="text"
            placeholder="Pesquisar Produto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="divisor2">
          <button 
            className="button-list"
            type="button" 
            onClick={pesqProduct}>
            Pesquisar
          </button>
          <button 
            type="button" 
            className="button-list"
            onClick={cancelSearch}>
            Cancelar
          </button>
        </div>
      </div>
      <div className="grid-container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Subcategoria</th>
                <th>Marca</th>
                <th>Preço</th>
                <th>Margem</th>
                <th>Saldo</th>
                <th>Custo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
            {searchTerm ? (
                  currentProductsP.map((product) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.nome}</td>
                  <td>{getSubCategoriaDescricaoById(product.subcategoriaid)}</td>
                  <td>{marcas.length > 0 ? getMarcaDescricaoById(product.marcaid.toString()) : "Carregando marcas..."}</td>
                  <td>{product.preco}</td>
                  <td>{product.margemBruta}</td>
                  <td>{product.saldo}</td>
                  <td>{product.custo}</td>
                  <td> 
                    <div className="btnP">
                    <div className="box-update">
                        <FaPen onClick={() => editProduct(product.id)} title="Alterar registro" size={20} color="white" />
                      </div>
                      <div className="box-delete">
                        <FaTrash onClick={() => handleExcluirProduto(product)} title="Excluir registro" color="white" size={20} />
                      </div>
                      </div>
                    </td>
                </tr>
              ))
              ) : ( currentProducts.map((product) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.nome}</td>
                  <td>{getSubCategoriaDescricaoById(product.subcategoriaid)}</td>
                  <td>{getMarcaDescricaoById(product.marcaid.toString())}</td>
                  <td>{product.preco}</td>
                  <td>{product.margemBruta}</td>
                  <td>{product.saldo}</td>
                  <td>{product.custo}</td>
                  <td> 
                    <div className="btnP">
                    <div className="box-update">
                        <FaPen onClick={() => editProduct(product.id)} title="Alterar registro" size={20} color="white" />
                      </div>
                      <div className="box-delete">
                        <FaTrash onClick={() => handleExcluirProduto(product)} title="Excluir registro" color="white" size={20} />
                      </div>
                      </div>
                    </td>
                </tr>
                ))
              )}
            </tbody>
          </table>
          </div>
        <Component.Custom.Pagination
            currentPage={currentPage}
            totalItems={products.length}
            setCurrentPage={handlePageChange}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
          <div className="edit-status">
            {isEditing ? (
              <p>Campos habilitados para edição.</p>
            ) : (
              <p>
                Campos desabilitados. Clique em "Novo" para editar.
              </p>
            )}
          </div>
          <div className="order-edit-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div>
                  <input
                    {...register('id')}
                    type="hidden"
                    name="id"
                    value={produtoAtual.id}
                  />

                  {errors.id ? (
                    <Component.Shared.TextComponent
                      value={errors.id.message}
                      size={12}
                      color="red"
                      margin={0}
                      align="flex-start"
                    />
                  ) : null}
                </div>

                <div>
                  <label htmlFor="nome">Nome do produto:</label>
                  <input
                    {...register('nome')}
                    type="text"
                    id="nome"
                    name="nome"
                    value={produtoAtual.nome}
                    onChange={(e) => {
                      setValue('nome', e.target.value);
                      setProdutoAtual({ ...produtoAtual, nome: e.target.value });
                    }}
                    disabled={!isEditing}
                  />

                  {errors.nome ? (
                    <Component.Shared.TextComponent
                      value={errors.nome.message}
                      size={12}
                      color="red"
                      margin={0}
                      align="flex-start"
                    />
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="descricao">Descrição do produto:</label>
                <textarea
                  {...register('descricao')}
                  id="descricao"
                  name="descricao"
                  value={produtoAtual.descricao}
                  onChange={(e) => {
                    setValue('descricao', e.target.value);
                    setProdutoAtual({ ...produtoAtual, descricao: e.target.value });
                  }}
                  disabled={!isEditing}
                  style={{ width: '100%' }}
                />

                {errors.descricao ? (
                  <Component.Shared.TextComponent
                    value={errors.descricao.message}
                    size={12}
                    color="red"
                    margin={0}
                    align="flex-start"
                  />
                ) : null}
              </div>

              <div className="category-subcategory-container">
                <div className="category-select custom-select">
                  <label htmlFor="subcategoriaid">Subcategoria:</label>
                  <select
                    {...register('subcategoriaid')}
                    id="subcategoriaid"
                    name="subcategoriaid"
                    value={selectedSubcategory}
                    onChange={(e) => setSelectedSubcategory(e.target.value)
                    }

                    disabled={!isEditing}
                  >
                    <option value="">Selecione uma Subcategoria</option>
                    {subcategorias.map((subcategorias) => (
                      <option key={subcategorias.id} value={subcategorias.id}>
                        {subcategorias.descricao}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="subcategory-select custom-select">
                  <label htmlFor="marca">Marca:</label>
                  <select
                    {...register('marcaid')}
                    id="marcaid"
                    name="marcaid"
                    value={selectedMarcaSelecionada}
                    onChange={(e) => {
                      setSelectedMarcaSelecionada(e.target.value)
                    }}
                    disabled={!isEditing}
                  >
                    <option value="">Selecione uma marca</option>
                    {marcasCadastradas.map((marca) => (
                      <option key={marca.id} value={marca.id}>
                        {marca.descricao}
                      </option>
                    ))}
                  </select>
                  
                  {errors.marcaid ? (
                    <Component.Shared.TextComponent
                      value={errors.marcaid.message}
                      size={12}
                      color="red"
                      margin={0}
                      align="flex-start"
                    />
                  ) : null}
                </div>
              </div>

              <div className="image-input-container">
                <label htmlFor="imagens">Imagens do Produto:</label>
                <input
                  type="file"
                  id="Imagens"
                  name="Imagens"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                  disabled={!isEditing}
                  ref={fileInputRef}
                />
              </div>

                <div className="payment-saldo-container">
                  {uploadedImages.map((image, index) => (
                    <div key={index} className="image-preview">
                      <img src={image.previewUrl} alt={`Preview ${index}`} className="image-preview-container" />
                      <div className="input-image">
                        <label htmlFor={`CodVariacao-${index}`}>Código de Variação:</label>
                        <input
                          type="text"
                          id={`CodVariacao-${index}`}
                          name={`CodVariacao-${index}`}
                          value={image.CodVariacao}
                          onChange={(e) => {
                            const newImages = [...uploadedImages];
                            newImages[index].CodVariacao = e.target.value;
                            setUploadedImages(newImages);
                          }}
                          disabled={!isEditing}
                        />
                      </div>
                    </div>
                  ))}
                </div>
             
              {errors.Imagens ? (
                <Component.Shared.TextComponent
                  value={errors.Imagens.message}
                  size={12}
                  color="red"
                  margin={0}
                  align="flex-start"
                />
              ) : null}

              <div className="payment-saldo-container">
                <div className="input-container">
                  <label htmlFor="saldo">Saldo:</label>
                  <input
                    {...register('saldo')}
                    type="text"
                    id="saldo"
                    name="saldo"
                    value={produtoAtual.saldo || ''}
                    onChange={(e) => {
                      const { value } = e.target;
                      setValue('saldo', value);
                      setProdutoAtual({ ...produtoAtual, saldo: value });
                    }}
                    onFocus={() => onFocus('saldo')}
                    disabled={!isEditing}
                  />

                  {errors.saldo ? (
                    <Component.Shared.TextComponent
                      value={errors.saldo.message}
                      size={12}
                      color="red"
                      margin={0}
                      align="flex-start"
                    />
                  ) : null}
                </div>

                <div className="input-container">
                  <label htmlFor="custo">Preço de Custo:</label>
                  <input
                    {...register('custo')}
                    type="text"
                    id="custo"
                    name="custo"
                    value={produtoAtual.custo || ''}
                    onChange={(e) => {
                      const { value } = e.target;
                      setValue('custo', value);
                      setProdutoAtual({ ...produtoAtual, custo: value });
                    }}
                    onBlur={(e) => {
                      formatToTwoDecimals('custo', e);
                      onBlur('custo');
                    }}
                    onFocus={() => onFocus('custo')}
                    disabled={!isEditing}
                    className="input-payment"
                  />

                  {errors.custo ? (
                    <Component.Shared.TextComponent
                      value={errors.custo.message}
                      size={12}
                      color="red"
                      margin={0}
                      align="flex-start"
                    />
                  ) : null}
                </div>

                <div className="input-container">
                  <label htmlFor="margemBruta">Margem Bruta (%):</label>
                  <input
                    {...register('margemBruta')}
                    type="text"
                    id="margemBruta"
                    name="margemBruta"
                    value={produtoAtual.margemBruta || ''}
                    onChange={(e) => {
                      const { value } = e.target;
                      setValue('margemBruta', value);
                      setProdutoAtual({ ...produtoAtual, margemBruta: value });
                    }}
                    onBlur={(e) => {
                      formatToTwoDecimals('margemBruta', e);
                      onBlur('margemBruta');
                    }}
                    onFocus={() => onFocus('margemBruta')}
                    disabled={!isEditing}
                    className="input-payment"
                  />

                  {errors.margemBruta ? (
                    <Component.Shared.TextComponent
                      value={errors.margemBruta.message}
                      size={12}
                      color="red"
                      margin={0}
                      align="flex-start"
                    />
                  ) : null}
                </div>

                <div className="input-container">
                  <label htmlFor="preco">Preço de Venda:</label>
                  <input
                    {...register('preco')}
                    type="text"
                    id="preco"
                    name="preco"
                    value={produtoAtual.preco}
                    readOnly
                    onChange={(e) => {
                      const { value } = e.target;
                      setValue('preco', value);
                    }}
                    onBlur={(e) => {
                      formatToTwoDecimals('preco', e);
                      onBlur('preco');
                    }}
                    disabled={!isEditing}
                    className="input-payment"
                  />

                  {errors.preco ? (
                    <Component.Shared.TextComponent
                      value={errors.preco.message}
                      size={12}
                      color="red"
                      margin={0}
                      align="flex-start"
                    />
                  ) : null}
                </div>
              </div>
              <div className="buttonF">
              <button 
              className="btn btn-secondary button" 
              type='button'
              onClick={handleCancel}
              disabled={!isEditing}
              >
                Cancelar
              </button>
              <button 
              disabled={!isEditing}
              className="btn btn-primary button" 
              type='submit'
              >
                Cadastrar
              </button>
                {editingProductId ? (
                  <button 
                  
                  type="button" 
                  onClick={() => handleEditProduto(produtoAtual)}
                  className="btn btn-primary button" 
                  >
                    Salvar Alteração
                  </button>
                ) : null}
              </div>
            </form>
          </div>
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
        </div>
  );
}
export default CadastroProduto;