import { Outlet } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import { CartProvider } from './context/cart.provider.tsx';
import { ProductProvider } from './context/product.provider.tsx';
import { ThemeProvider } from './context/theme.context.tsx';
import { RepresentadaProvider } from './context/representada.provider.tsx';

function App() {
  return (
    
    <ThemeProvider>
      <RepresentadaProvider>
    <ProductProvider>
    <CartProvider>
      <Outlet />
    </CartProvider>
   </ProductProvider>
   </RepresentadaProvider>
   </ThemeProvider>

  );
}

export default App