import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css';

import {

  createBrowserRouter,
 
  RouterProvider
} from 'react-router-dom'

import NotFound from './pages/NotFound'
import AuthProvider from './context/auth.provider'
import Login from './pages/login/login'



import Home from './pages/home/home';
import AdminHome from './pages/admin/home/home';
import ProtectedRoute from './components/shared/protected.rotes';
import ForgotPassword from './pages/forgot-password/forgot';
import CreateAccount from './pages/create-account/create';
import Products from './pages/products/products';
import ProductDetail from './pages/product-detail/product-detail';
import Checkout from './pages/checkout/checkout';
import Panel from './pages/panel/panel';
import CheckoutComplete from './pages/checkout-complete/checkout-complete';
import SearchResults from './pages/search-results/search-results';
import OrderDetail from './pages/order-detail/order-detail';
import { CategoryProvider } from './context/category.provider';
import Recovery from './pages/recovery/recovery';
import Component from './components/components';
import { AdminProtectedRoute } from './components/shared/admin.protected.rotes';
import DetalharPedido from './components/custom/admin/relatorios/detalhar-pedido/detalharpedido.component';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/home',
        element: <ProtectedRoute element={<CategoryProvider><Home /></CategoryProvider>} />
      },
      {
        path: '/produtos',
        element: <ProtectedRoute element={<CategoryProvider><Products /></CategoryProvider>} />
      },
      {
        path: '/produtos/detalhes/:productId',
        element: <ProtectedRoute element={<CategoryProvider><ProductDetail /></CategoryProvider>} />
      },
      {
        path: '/panel/detalhes/:orderId',
        element: <ProtectedRoute element={<CategoryProvider><OrderDetail /></CategoryProvider>} />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '/recovery',
        element: <Recovery />
      },
       {
        path: '/create-account',
        element: <CreateAccount />
      },
      {
        path: '/checkout',
        element: <ProtectedRoute element={<CategoryProvider><Checkout /></CategoryProvider>} />
      },
      {
        path: '/search-results',
        element: <ProtectedRoute element={<CategoryProvider><SearchResults /></CategoryProvider>} />
      },
      {
        path: '/checkout-complete',
        element: <ProtectedRoute element={<CategoryProvider><CheckoutComplete /></CategoryProvider>} />
      },
      {
        path: '/panel',
        element: <ProtectedRoute element={<CategoryProvider><Panel /></CategoryProvider>} />
      },

      {
        path: '/admin-home',
        element: <AdminProtectedRoute element={<AdminHome/>} />,
        children: [
          { path: '/admin-home', element: <Component.Custom.HomeContent /> },
          { path: 'saldoestoque', element: <Component.Custom.SaldoEstoque /> },
          { path: 'clientessemcompra', element: <Component.Custom.ClientesSemCompra /> },
          { path: 'rankingclientes', element: <Component.Custom.RankingClientes /> },
          { path: 'rankingprodutos', element: <Component.Custom.RankingProdutos /> },
          { path: 'rankingvendas', element: <Component.Custom.RankingVendas /> },
          { path: 'inadimplencia', element: <Component.Custom.Inadimplencia /> },
          { path: 'geralvendas', element: <Component.Custom.GeralVendas /> },
          { path: 'historicopedidos', element: <Component.Custom.HistoricoPedidos />}, 
          {
            path: 'detalhes/:orderId',
            element:<Component.Custom.DetalharPedido />
          },
          {
            path: 'alterarpedido/:orderId',
            element:<Component.Custom.AlterarPedido />
          },
          { path: 'documentos', element: <Component.Custom.CadastroDocumento /> },
          { path: 'clientes', element: <Component.Custom.Clientes /> },
          { path: 'categorias', element: <Component.Custom.CadastroCategoria /> },
          { path: 'condicoes', element: <Component.Custom.CadastroCondPagto /> },
          { path: 'produtos', element: <Component.Custom.CadastroProduto /> },
          { path: 'subcategorias', element: <Component.Custom.CadastroSubcategoria /> },
          { path: 'fornecedores', element: <Component.Custom.CadastroForn /> },
          { path: 'addclientes', element: <Component.Custom.AddClientes /> },
          { path: 'addpedidos', element: <Component.Custom.AddPedidos /> },
          { path: 'marcas', element: <Component.Custom.CadastroMarca /> }]
      },
    ]
  }
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={routes} />
    </AuthProvider>
  </React.StrictMode>
)