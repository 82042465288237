import React from "react";
import SubCategory from "../../../types/subcategory.type";
import { CategoryContext } from "../../../context/category.provider"; // Importando o contexto de categoria

type SubcategoriesListProps = {
  subcategories: SubCategory[],
  selectedSubcategoryId?: number,
  onSubCategoryChange: (subcategory: SubCategory) => void,
}

const SubCategoriesList = (props: SubcategoriesListProps) => {
  const { subcategories, selectedSubcategoryId, onSubCategoryChange } = props;

  const { selectSubCategory } = React.useContext(CategoryContext); // Usando o contexto de categoria

  const handleSubCategoryClick = (subcategory: SubCategory) => {
    selectSubCategory(subcategory); // Mudando a subcategoria selecionada usando o contexto
    onSubCategoryChange(subcategory);
  };

  return (
    <div>
      {subcategories.map((sub, index) => (
        <div
          key={index}
          onClick={() => handleSubCategoryClick(sub)}
          style={{
            fontWeight: selectedSubcategoryId === sub.id ? 'bold' : 'normal',
            color: selectedSubcategoryId === sub.id ? '#00b7bd' : 'gray',
            cursor: 'pointer',
          }}
        >
          <p>{sub.descricao}</p>
        </div>
      ))}
    </div>
  );
};

export default SubCategoriesList;
