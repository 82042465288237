import { ReactNode, createContext, useEffect, useState, useCallback } from "react";
import { Category } from "../types/category.type";
import SubCategory from "../types/subcategory.type";
import api from "../services/api";

interface CategoryContextProps {
  categories: Category[];
  getCategories: (representadaid: number | null) => Promise<Category[]>;
  selectedCategory: Category | null;
  selectCategory: (category: Category | null) => void;
  selectedSubCategory: SubCategory | null;
  selectSubCategory: (subCategory: SubCategory | null) => void;
}

export const CategoryContext = createContext<CategoryContextProps>({
  categories: [],
  getCategories: async () => [],
  selectedCategory: null,
  selectCategory: () => {},
  selectedSubCategory: null,
  selectSubCategory: () => {},
});

export const CategoryProvider = ({ children }: { children: ReactNode }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory | null>(null);

  const getCategories = useCallback(async (representadaid: number | null) => {
    try {

      const url = `/categorias${representadaid != null ? `?representadaid=${representadaid}` : ''}`;
   
      const response = await api.get(url);
      setCategories(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching categories:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    getCategories(null);
  }, [getCategories]);

  const selectCategory = (category: Category | null) => {
    setSelectedCategory(category);
    if (!category) setSelectedSubCategory(null); // Se não há categoria selecionada, também limpe a subcategoria
  };

  const selectSubCategory = (subCategory: SubCategory | null) => {
    setSelectedSubCategory(subCategory);
   
  };

  return (
    <CategoryContext.Provider value={{ categories, getCategories, selectedCategory, selectCategory, selectedSubCategory, selectSubCategory }}>
      {children}
    </CategoryContext.Provider>
  );
};