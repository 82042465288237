import React from 'react';
import ClienteCard from './clientecard.shared.component';
import Client from '../../types/client.type';

interface ListCardProps {
  clientes: Client[];
  onSelectCliente?: (cliente: Client) => void; 
}
const ListaClienteCard: React.FC<ListCardProps> = ({ clientes, onSelectCliente }) => {
  return (
    <div>
      {clientes.map(cliente => (
       <div onClick={() => cliente.inativar === false ? onSelectCliente?.(cliente) : null} key={cliente.id}>
          <ClienteCard cliente={cliente} />
        </div>
      ))}
    </div>
  );
};

export default ListaClienteCard;