import { useContext, useEffect, useState } from "react";
import Component from "../../components";
import "./index.css";
import Client from "../../../types/client.type";
import api from "../../../services/api";
import { AuthContext } from "../../../context/auth.provider";
import { ClientListInputProps, clientlistSchema } from "../../schemas/user.schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

type ClientListProps = {
    onClose: () => void;
}

export const ClientListComponent = (props: ClientListProps) => {

    const [clientes, setClientes] = useState<Client[]>([]);
    const { user, loginById } = useContext(AuthContext);
    const [error, setError] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true); // começa visível
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<ClientListInputProps>({ resolver: zodResolver(clientlistSchema) })

    useEffect(() => {
        listarClientes();
    }, [user]);

    const listarClientes = async () => {

        await api.post('/usuarios/listarusuarios'
        ).then(response => {
            setClientes(response.data)
        }).catch(error => {
            setError(error.response.message);
        })

    };


    const handleSendOrder = async (data: ClientListInputProps) => {
        setIsLoading(true);

        const auth = await loginById(data);

        if (auth) {
            setIsVisible(false);
            props.onClose();
        }

        setIsLoading(false);
    };


    return (
        <div>
            {isVisible && (
                loading ? (
                    <Component.Shared.LoaderComponent />
                ) :
                    <div className="overlay">
                        <div className="centered-component">
                            <Component.Shared.SelectComponent filter register={register('usuario_id')} margin={0} marginTop={10} align='flex-start' data={clientes} size={16} color="black" placeholder="* Cliente" />
                            {errors.usuario_id ? (
                                <Component.Shared.TextComponent value={errors.usuario_id.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}
                            {error ? (
                                <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}
                            <button className="form-control btn btn-primary button" type="button"
                                onClick={handleSubmit(handleSendOrder)}>
                                Acessar
                            </button>
                        </div>
                    </div>
            )}
        </div>
    );
}

export default ClientListComponent;